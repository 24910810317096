import {FormikValidation} from "helpers/types";
import {FormFeedback} from "reactstrap";
import {useRef} from "react";
import {userMultiValueContainer, userOptionContainer} from "./Select/User";
import AsyncSelect from "react-select/async";

interface ValidatedAsyncSelectProps<T> {
  value: any;
  field: keyof T;
  validation: FormikValidation<T>;
  className?: string;
  placeholder?: string;
  errorStyle: "container" | "solid";
  noOptionsMessage?: string;
  loadingMessage?: string;
  isClearable?: boolean;
  loadOptions: (inputValue: string) => Promise<any[]>;
}
const ValidatedAsyncSelect = <T,>(componentProps: ValidatedAsyncSelectProps<T>) => {
  const fieldName = componentProps.field.toString();
  const {error, touched} = componentProps.validation.getFieldMeta(fieldName);
  const debounceTimeoutRef = useRef<number | null>(null);

  const loadOptionsWithDebounce = (inputValue: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    return new Promise<any[]>((resolve) => {
      debounceTimeoutRef.current = window.setTimeout(async () => {
        if (inputValue === "") {
          resolve([]);
        } else {
          const data = await componentProps.loadOptions(inputValue);
          resolve(data);
        }
      }, 300); // 300ms debounce süresi
    });
  };

  return (
    <>
      <AsyncSelect
        className={`${componentProps.className} ${touched && error && componentProps.errorStyle === "container" && "form-control is-invalid form-select-invalid"}`}
        name={fieldName}
        loadOptions={loadOptionsWithDebounce}
        isMulti={false}
        onBlur={componentProps.validation.handleBlur}
        cacheOptions
        defaultOptions
        onChange={(options) => {
          const selectedOption = options as any;
          componentProps.validation.setFieldValue(fieldName, selectedOption?.value);
        }}
        components={{MultiValueContainer: userMultiValueContainer, Option: userOptionContainer}}
        placeholder={componentProps.placeholder}
        noOptionsMessage={() => componentProps.noOptionsMessage}
        loadingMessage={() => componentProps.loadingMessage}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={true}
        isClearable={componentProps.isClearable}
      />
      {touched && error ? (
        <FormFeedback type="invalid" className="searches validation-width">
          {error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedAsyncSelect;
