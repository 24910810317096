import {useMemo} from "react";
import {AllMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";
import _ from "lodash";

interface DomainToFlagProps {
  marketplace: string;
}

const DomainToFlag = (props: DomainToFlagProps) => {
  const marketplace = useMemo(() => {
    const value = _.toUpper(props.marketplace);
    return AllMarketplaces.find((amazonMarketplaceInfo: AmazonMarketplace) => amazonMarketplaceInfo.marketplace === value);
  }, [props.marketplace]);

  return <img src={marketplace ? marketplace.flag : ""} alt={marketplace ? `${marketplace.countryName} Flag` : ""} className="me-1" height="14" />;
};

export default DomainToFlag;
