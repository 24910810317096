import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useCallback, useEffect} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getUserOrderDetails} from "slices/order-details/thunk";
import {useProfile} from "Components/Hooks/useProfile";
import {OrderStatusBadge} from "Components/Common/Badges/OrderStatusBadge";
import {OrderStatus} from "models/enums/order_status";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import Summary from "./_Summary";
import Content from "./_Content";
import CostAndFees from "./_CostAndFees";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import ShipmentList from "./_ShipmentList";
import ReassignShipment from "./Modals/ReassignShipment";

const OrderDetails = () => {
  const {t} = useTranslation();
  const {amazonOrderId} = useParams();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      order: state.OrderDetails.order,
    }),
  );
  const {order} = useSelector(ordersData);

  const onLoad = useCallback(
    () => {
      if (amazonOrderId && hasPermission("orderDetails")) {
        dispatch(getUserOrderDetails(amazonOrderId));
      }
    },
    [amazonOrderId], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Orders.Title"),
      url: "/orders",
    },
    {
      label: `${t("OrderDetails.Title")} - ${order.amazonOrderId}`,
      url: "",
    },
  ];
  document.title = t("PageTitles.OrderDetails");

  return (
    <Restricted require={"orderDetails"} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={`${t("OrderDetails.Title")} - ${order.amazonOrderId}`} menus={breadcrumbMenus} />
          <Card>
            <CardBody>
              <Row className="d-flex align-items-center">
                <Col xs="auto" className="pe-0 d-flex align-items-center">
                  <CopyAsinWidget asin={order.amazonOrderId} index={order.amazonOrderId} bold={true} />
                </Col>
                <Col xs="auto">
                  <OrderStatusBadge status={order.orderStatus} size="md" />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col lg={8}>
              <Summary />
              <Content />
              {order.orderStatus !== OrderStatus.PENDING && <ShipmentList />}
            </Col>
            <Col lg={4}>
              <CostAndFees />
            </Col>
          </Row>
        </Container>
      </div>
      <ReassignShipment />
    </Restricted>
  );
};

export default OrderDetails;
