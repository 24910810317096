import i18n from "i18n";
import {UserSearchStatus} from "models/enums/user_search_status";

interface SearchStatusBadgeProps {
  status?: UserSearchStatus;
  size?: "sm" | "md" | "lg";
}

export const SearchStatusBadge = ({status, size = "sm"}: SearchStatusBadgeProps) => {
  let badgeClass = "";
  let text = "";

  switch (status) {
    case UserSearchStatus.INITIAL:
      badgeClass = "bg-info-subtle text-info";
      text = i18n.t("Initial");
      break;
    case UserSearchStatus.IN_PROGRESS:
      badgeClass = "bg-warning-subtle text-warning";
      text = i18n.t("In Progress");
      break;
    case UserSearchStatus.COMPLETED:
      badgeClass = "bg-success-subtle text-success";
      text = i18n.t("Completed");
      break;
    case UserSearchStatus.ERROR:
      badgeClass = "bg-danger-subtle text-danger";
      text = i18n.t("Error");
      break;
    case UserSearchStatus.CANCELED:
      badgeClass = "bg-danger-subtle text-danger";
      text = i18n.t("Canceled");
      break;
  }

  const fontSize = size === "sm" ? "fs-12" : size === "lg" ? "fs-16" : "fs-14";

  return (
    <span className={`badge rounded-pill ${badgeClass} ${fontSize}`}>
      {text === "Working" && <i className="mdi mdi-spin mdi-loading me-1"></i>}
      {text}
    </span>
  );
};
