import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import CountUp from "react-countup";
import DomainToFlag from "Components/Common/DomainToFlag";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useMemo, useRef} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {MarketplaceStatus} from "models/system_monitoring";
import DisplayNumber from "Components/Common/DisplayNumber";

const TokenStatistics = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);

  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);

  const columns = useMemo<ColumnDef<MarketplaceStatus, any>[]>(
    () => [
      {
        header: t("Marketplace"),
        size: 250,
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <div className="d-flex align-items-center">
              <DomainToFlag marketplace={row.marketplace} />
              <span className="ellipsis-single-line">{row.marketplace}</span>
            </div>
          );
        },
        footer: () => {
          return (
            <span className="fw-bold">
              {t("Total")}
            </span>
          );
        },
      },
      {
        header: t("Active"),
        size: 200,
        accessorKey: "active",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <span className="counter-value">
              <CountUp end={row.active} duration={2} />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <DisplayNumber className="fw-bold" value={totalCount} notation="decimal" />;
        },
      },
      {
        header: t("Passive"),
        size: 150,
        accessorKey: "passive",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <span className="counter-value">
              <CountUp end={row.passive} duration={2} />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <DisplayNumber className="fw-bold" value={totalCount} notation="decimal" />;
        },
      },
      {
        header: t("Total"),
        size: 150,
        accessorKey: "total",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as MarketplaceStatus;
          return (
            <span className="counter-value">
              <CountUp end={row.total} duration={2} />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <DisplayNumber className="fw-bold" value={totalCount} notation="decimal" />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <PlaceholderGlow busy={loading.list}>
        <Card className="card-animate mb-0">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Admin.SystemMonitoring.Section.Title.TokenStatistics")}</h4>
          </div>
          <CardBody>
            <DataTable
              ref={tableRef}
              busy={loading.list}
              columns={columns}
              data={systemMonitoring?.marketplacesTokensCount || []}
              totalDataLength={systemMonitoring?.marketplacesTokensCount?.length || 0}
              hovered
            />
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default TokenStatistics;
