import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Input, Spinner, Label} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createTypedModal} from "helpers/modal_helpers";
import {UserSearchNote} from "models/user_search_note";
import {useProfile} from "Components/Hooks/useProfile";
import DisplayDate from "Components/Common/DisplayDate";
import {useState, useEffect, useCallback} from "react";
import {removeNote, saveNote} from "slices/search-result/thunk";
import {createSelector} from "reselect";
import {RootState} from "slices";

interface ModalData {
  asin: string;
  userSearchId: string;
  marketplaceTarget?: string;
  note?: UserSearchNote;
}
export const NotesModal = createTypedModal<ModalData>("notes");

const Notes = () => {
  const {open, data} = NotesModal.useModal();
  if (!open) return null;
  return <NotesContent data={data} />;
};

const NotesContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const dispatch = useDispatch();

  const searchResultSelector = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.SearchResult.loading,
    }),
  );

  const {loading} = useSelector(searchResultSelector);

  const [isEditing, setIsEditing] = useState(!data?.note);
  const [editedNote, setEditedNote] = useState(data?.note?.note || "");

  useEffect(() => {
    // If there's no note, start in edit mode
    if (!data?.note) {
      setIsEditing(true);
    }
  }, [data?.note]);

  const toggle = () => {
    NotesModal.close();
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = useCallback(() => {
    const deletePromise = removeNote({asin: data?.asin!, userSearchId: data?.userSearchId!})(dispatch);
    deletePromise.then((success) => {
      if (success) {
        NotesModal.close();
      }
    });
  }, [data, dispatch]);

  const handleSave = useCallback(async () => {
    const createPromise = saveNote({asin: data?.asin!, note: editedNote, userSearchId: data?.userSearchId!})(dispatch);
    createPromise.then((success) => {
      if (success) {
        setIsEditing(false);
      }
    });
  }, [editedNote, data]); // eslint-disable-line

  const handleCancel = () => {
    if (!data?.note) {
      // If canceling a new note creation, close the modal
      NotesModal.close();
    } else {
      // If canceling an edit, just reset to original note
      setEditedNote(data.note.note || "");
      setIsEditing(false);
    }
  };

  return (
    <>
      <Modal backdrop="static" isOpen={true} toggle={toggle} fade={true} centered={true} size="md">
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("SearchResults.Dialog.Notes.Title")}
          <span className="ms-1 fs-13 text-muted fw-medium">
            -{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: t("SearchResults.Dialog.Notes.Description", {asin: data?.asin}),
              }}
            ></span>
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center flex-column gap-3">
            {data?.note && (
              <Row>
                <Col xs="auto" className="pe-0 d-flex align-items-center">
                  <i className="ri-calendar-2-line text-muted fs-16"></i>
                </Col>
                <Col className="d-flex align-items-center">
                  <Label className="mb-0 me-2">{t("SearchResults.Dialog.Notes.LastUpdate")}:</Label>
                  <DisplayDate id="event-start-date-tag" tz={userProfile?.timezone} value={data.note.createDate} format="D MMM YYYY HH:mm" />
                </Col>
              </Row>
            )}
            <Row className={`d-flex ${isEditing ? "align-items-start" : "align-items-center"}`}>
              <Col xs="auto" className={`pe-0`}>
                <i className="mdi mdi-note-edit-outline text-muted fs-16"></i>
              </Col>
              <Col>
                <Label className="mb-0 me-2" for="editedNote">
                  {t("SearchResults.Dialog.Notes.YourNote")}:
                </Label>
                {isEditing ? (
                  <>
                    <Input type="textarea" className="mb-2 mt-2" name="editedNote" value={editedNote} maxLength={500} onChange={(e) => setEditedNote(e.target.value)} rows={4} autoFocus />
                    <span className="text-muted">
                      {editedNote.length}
                      <i className="mdi mdi-slash-forward"></i>500
                    </span>
                  </>
                ) : (
                  <>
                    <span className="me-2">{editedNote}</span>
                    <Button color="link" className="p-0 link-secondary" onClick={handleEdit}>
                      <i className="ri ri-edit-line align-bottom"></i> {t("Edit")}
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            {isEditing ? (
              <>
                <Button
                  color="success"
                  onClick={handleSave}
                  disabled={!editedNote.trim() || loading.note} // Disable if note is empty
                >
                  {loading.note && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                  {data?.note ? t("Update") : t("Save")}
                </Button>
                <Button color="light" onClick={handleCancel} disabled={loading.note}>
                  {t("Cancel")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="link"
                  className="link-danger link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                  onClick={handleDelete}
                  disabled={loading.note}
                >
                  {loading.note && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                  {t("Delete")}
                </Button>
                <Button type="button" color="light" onClick={toggle} disabled={loading.note}>
                  {t("Close")}
                </Button>
              </>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Notes;
