import {ApiError} from "./types";
import {TimeZone, TimeZones} from "models/timezone";
import {UserShipment, UserShipmentStatus} from "models/user_shipment";
import {toast} from "react-toastify";
import {getDemoModeInfo} from "./local_storage";
import { AllMarketplaces } from "./marketplace_helper";
import i18n from "i18n";
import moment from "moment-timezone";

const timeAgo = (dateTime: string) => {
  const currentTime = getToday().toDate();
  const previousTime = new Date(dateTime);
  const timeDifference = currentTime.getTime() - previousTime.getTime();

  let date: number = 0;
  let ago: string = "";

  if (timeDifference < 60000) {
    date = Math.floor(timeDifference / 1000);
    ago = "seconds ago";
  } else if (timeDifference < 3600000) {
    date = Math.floor(timeDifference / 60000);
    ago = "minutes ago";
  } else if (timeDifference < 86400000) {
    date = Math.floor(timeDifference / 3600000);
    ago = "hours ago";
  } else if (timeDifference < 604800000) {
    date = Math.floor(timeDifference / 86400000);
    ago = "days ago";
  }

  return {
    date,
    ago,
  };
};

const getToday = (): moment.Moment => {
  const demoMode = getDemoModeInfo();
  if (demoMode?.active) {
    return moment(demoMode?.date);
  }
  return moment();
};

const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const dayDifference = (date1: Date, date2: Date): number => {
  const differenceInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
  const dayDifference = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  return dayDifference;
};
const getRelativeDate = (daysAgo: number): Date => {
  const today: Date = getToday().toDate();
  const date = new Date(today);
  date.setDate(today.getDate() - daysAgo);
  date.setHours(0, 0, 0, 0);

  return date;
};

const getRelativeDateByDate = (date: Date, daysAgo: number): Date => {
  const dateValue = new Date(date);
  dateValue.setDate(dateValue.getDate() - daysAgo);
  dateValue.setHours(0, 0, 0, 0);

  return dateValue;
};

const getRelativeDateByTimezone = (daysAgo: number, timezone?: string): Date => {
  if (!timezone) {
    return getRelativeDate(daysAgo);
  }
  const userToday = getToday()
    .tz(timezone)
    .subtract(daysAgo, "days")
    .startOf("day");
  const copied = userToday.clone();
  return copied.utcOffset(0).toDate();
};

const generateError = (error: any, showToast: boolean): ApiError => {
  const defaultError: ApiError = {
    status: false,
    message: i18n.t("General.Error"),
  };
  if (error.response && error.response.data) {
    if (showToast) {
      toast(i18n.t(error.response.data.message || error.response.data), {
        position: "top-center",
        progressStyle: {background: "LightSalmon"},
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-danger text-white",
      });
    }
    if (error.response.data.message) {
      return error.response.data as ApiError;
    } else {
      defaultError.message = i18n.t("General.Error");
      return defaultError;
    }
  } else {
    if (showToast) {
      toast(defaultError.message, {
        position: "top-center",
        progressStyle: {background: "LightSalmon"},
        hideProgressBar: false,
        closeOnClick: true,
        className: "bg-danger text-white",
      });
    }
    return defaultError;
  }
};
const renderSuccessToast = (message: string) => {
  toast(message, {
    position: "top-center",
    progressStyle: {background: "Turquoise"},
    hideProgressBar: false,
    closeOnClick: true,
    className: "bg-success text-white",
  });
};
const renderFailedToast = (message: string) => {
  toast(message, {
    position: "top-center",
    progressStyle: {background: "LightSalmon"},
    hideProgressBar: false,
    closeOnClick: true,
    className: "bg-danger text-white",
  });
};
const convertTimeByTimeZone = (dateTime: Date, timezone?: string): Date => {
  if (!timezone) {
    return dateTime;
  }
  const t = TimeZones.find((option: TimeZone) => option.value === timezone);

  const utcOffset = t?.offset! * 60 * 60 * 1000;
  const utcDateTime = new Date(new Date(dateTime).getTime() + new Date(dateTime).getTimezoneOffset() * 60000 + utcOffset);

  return utcDateTime;
};

const formatCurrency = (symbol: string, decimals: number, notation: "decimal" | "abbreviated", value?: number, source?: string): string => {
  let absValue = Math.abs(value ?? 0);
  decimals = absValue === 0 ? 0 : decimals;
  const sign = (value ?? 0) < 0 ? "-" : "";

  if (notation === "abbreviated") {
    if (absValue >= 1e6) {
      return `${sign}${symbol}${(absValue / 1e6).toFixed(decimals)}M ${source}`;
    } else if (absValue >= 1e3) {
      return `${sign}${symbol}${(absValue / 1e3).toFixed(decimals)}k ${source}`;
    } else {
      return `${sign}${symbol}${absValue.toFixed(decimals)} ${source}`;
    }
  } else {
    const num = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(absValue);

    return `${sign}${symbol}${num} ${source}`;
  }
};

const abbreviateNumber = (number: number) => {
  if (number >= 1e6) {
    return Math.round(number / 1e6) + "M";
  } else if (number >= 1e3) {
    return Math.round(number / 1e3) + "k";
  } else {
    return number.toString();
  }
};

const formatDate = (date: Date, isUTC: boolean = false) => {
  if (isUTC) {
    return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, "0")}-${String(date.getUTCDate()).padStart(2, "0")}`;
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const isValidHttpUrl = (url: string) => {
  let urlObject;
  try {
    urlObject = new URL(url);
  } catch (_) {
    return false;
  }

  return urlObject.protocol === "http:" || urlObject.protocol === "https:";
};

const numberFormatter = (number: any) => {
  const formattedNumber = new Intl.NumberFormat(i18n.language).format(number);
  return formattedNumber;
};

const getShipmentSpeed = (shipment: UserShipment) => {
  const today = getToday().toDate();
  let shipmentSpeed = 0;
  let dayPassed = 0;
  let totalDays = 1; // Default value to avoid division by zero

  if (shipment.status === UserShipmentStatus.COMPLETED && shipment.completedDate) {
    dayPassed = dayDifference(new Date(shipment.completedDate!), new Date(shipment.shipDate!));
    totalDays = dayPassed > 0 ? dayPassed : 1;
    shipmentSpeed = shipment.totalItems / totalDays;
  } else if (shipment.status === UserShipmentStatus.SHIPPED && shipment.shipDate) {
    dayPassed = dayDifference(today, new Date(shipment.shipDate));
    totalDays = dayPassed > 0 ? dayPassed : 1;
    shipmentSpeed = shipment.soldItems / totalDays;
  }
  return {dayPassed, shipmentSpeed};
};

const getCurrencySymbol = (currency?: string) => {
  return AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === currency)?.priceSign || "";
};

const preventScrollUp = () => {
  const scrollY = window.scrollY;
  window.scrollTo(0, scrollY);
};

const generateRandomGuid = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) => (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16));
};

export {
  timeAgo,
  getToday,
  formatBytes,
  dayDifference,
  getRelativeDate,
  getRelativeDateByDate,
  getRelativeDateByTimezone,
  generateError,
  renderSuccessToast,
  renderFailedToast,
  convertTimeByTimeZone,
  formatCurrency,
  abbreviateNumber,
  formatDate,
  isValidHttpUrl,
  numberFormatter,
  getShipmentSpeed,
  getCurrencySymbol,
  preventScrollUp,
  generateRandomGuid,
};
