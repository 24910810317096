import {combineReducers} from "redux";

// Front
import LayoutReducer, {LayoutState} from "./layouts/reducer";

// Authentication
import LoginReducer, {LoginState} from "./auth/login/reducer";
import RegisterReducer, {RegisterState} from "./auth/register/reducer";
import ForgotPasswordReducer, {ForgotPasswordState} from "./auth/forgotPassword/reducer";
import ResetPasswordReducer, {ResetPasswordState} from "./auth/resetPassword/reducer";
import EmailVerificationReducer, {EmailVerificationState} from "./auth/emailVerification/reducer";

// Search
import SearchesReducer, {SearchesState} from "./searches/reducer";

import AccountReducer, {AccountState} from "./account/reducer";
import FavoritesReducer, {FavoritesState} from "./favorites/reducer";
import NotesReducer, { NotesState } from "./notes/reducer";
import ChangeSubscriptionReducer, {ChangeSubscriptionState} from "./account/changeSubscription/reducer";
import NewSubscriptionReducer, {NewSubscriptionState} from "./account/newSubscription/reducer";
import SettingsReducer, {SettingsState} from "./settings/reducer";
import InventoryReducer, {InventoryState} from "./inventory/reducer";
import OrdersReducer, {OrdersState} from "./orders/reducer";
import OrderDetailsReducer, {OrderDetailsState} from "./order-details/reducer";
import CommonReducer, {CommonState} from "./common/reducer";
import StoresReducer, {StoresState} from "./store/reducer";
import ScanAndSaveReducer, {ScanAndSaveState} from "./scan-and-save/reducer";
import ShipmentReducer, {ShipmentState} from "./shipment/reducer";
import NotificationsReducer, {NotificationsState} from "./notifications/reducer";
import UsersReducer, {AdminUsersState} from "./admin/users/reducer";
import DashboardReducer, {DashboardState} from "./dashboard/reducer";
import SearchResultsReducer, { SearchResultState } from "./search-result/reducer";
import AdminSearchesReducer, {AdminSearchesState} from "./admin/searches/reducer";
import AdminScanAndSaveReducer, {AdminScanAndSaveState} from "./admin/scanAndSave/reducer";
import AdminSearchLogsReducer, {AdminSearchLogsState} from "./admin/searchLogs/reducer";
import AdminSystemMonitoringReducer, {AdminSystemMonitoringState} from "./admin/systemMonitoring/reducer";
import ProductDetailsReducer, { ProductDetailsState } from "./product-details/reducer";

export type RootState = {
  Layout: LayoutState;
  Login: LoginState;
  Register: RegisterState;
  ForgotPassword: ForgotPasswordState;
  ResetPassword: ResetPasswordState;
  EmailVerification: EmailVerificationState;
  Account: AccountState;
  Settings: SettingsState;
  Favorites: FavoritesState;
  Notes: NotesState;
  ChangeSubscription: ChangeSubscriptionState;
  NewSubscription: NewSubscriptionState;
  Searches: SearchesState;
  Stores: StoresState;
  ScanAndSave: ScanAndSaveState;
  Inventory: InventoryState;
  Orders: OrdersState;
  OrderDetails: OrderDetailsState;
  Notifications: NotificationsState;
  Shipment: ShipmentState;
  Common: CommonState;
  Dashboard: DashboardState;
  SearchResult: SearchResultState;
  ProductDetails: ProductDetailsState;

  AdminSearches: AdminSearchesState;
  AdminSearchLogs: AdminSearchLogsState;
  AdminScanAndSave: AdminScanAndSaveState;
  AdminUsers: AdminUsersState;
  AdminSystemMonitoring: AdminSystemMonitoringState;
};

const rootReducer = combineReducers<RootState>({
  // Pages :
  Layout: LayoutReducer,
  Login: LoginReducer,
  Register: RegisterReducer,
  ForgotPassword: ForgotPasswordReducer,
  ResetPassword: ResetPasswordReducer,
  EmailVerification: EmailVerificationReducer,
  Account: AccountReducer,
  Settings: SettingsReducer,
  Favorites: FavoritesReducer,
  Notes: NotesReducer,
  ChangeSubscription: ChangeSubscriptionReducer,
  NewSubscription: NewSubscriptionReducer,
  Searches: SearchesReducer,
  Stores: StoresReducer,
  ScanAndSave: ScanAndSaveReducer,
  Inventory: InventoryReducer,
  Orders: OrdersReducer,
  OrderDetails: OrderDetailsReducer,
  Notifications: NotificationsReducer,
  Shipment: ShipmentReducer,
  Common: CommonReducer,
  Dashboard: DashboardReducer,
  SearchResult: SearchResultsReducer,
  ProductDetails: ProductDetailsReducer,

  AdminUsers: UsersReducer,
  AdminSearches: AdminSearchesReducer,
  AdminSearchLogs: AdminSearchLogsReducer,
  AdminScanAndSave: AdminScanAndSaveReducer,
  AdminSystemMonitoring: AdminSystemMonitoringReducer

});

export default rootReducer;
