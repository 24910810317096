import React, {useCallback, useEffect, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Button, Card, CardBody, CardHeader, Col, FormFeedback, Input, InputGroup, InputGroupText, Row, Table} from "reactstrap";
import {ISetUserShipmentDetailsDto, IAddUserShipmentItemDto, IDeleteUserShipmentItemDto, IRefreshUserShipmentItemDto, IAddToAmazonInventoryDto} from "models/user_shipment";
import {addUserShipmentItem, refreshUserShipmentItem, setUserShipmentDetails, addToAmazonInventory, setLoading} from "slices/shipment/thunk";
import {Link} from "react-router-dom";
import {UserShipmentItem} from "models/user_shipment_item";
import {deleteUserShipmentItem} from "slices/thunks";
import {getToday} from "helpers/utilities";
import {UserInventory} from "models/user_inventory";
import {RootState} from "slices";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {saveAs} from "file-saver";
import ImagePopup from "Components/Common/ImagePopup";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import errorImage from "assets/images/svg/product.svg";
import moment from "moment";
import "moment/locale/tr";
import _ from "lodash";
import FNSKUDocument, {BarcodeItem} from "Components/Common/PdfTemplates/FNSKUDocument";
import {pdf} from "@react-pdf/renderer";
import {EMPTY_GUID} from "helpers/string";
import {ActiveMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";
const Products = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {loading, currentUserShipment} = useSelector(shipmentsData);

  const [products, setProducts] = useState<UserShipmentItem[]>([...currentUserShipment.userShipmentItems].sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime()));
  const [asinInvalidStates, setAsinInvalidStates] = useState({});
  const [purchasePriceInvalidStates, setPurchasePriceInvalidStates] = useState({});
  const [taxInvalidStates, setTaxInvalidStates] = useState({});
  const [shippingPriceInvalidStates, setShippingPriceInvalidStates] = useState({});
  const [fBAFeeInvalidStates, setFBAFeeInvalidStates] = useState({});
  const [referralFeeInvalidStates, setReferralFeeInvalidStates] = useState({});
  const [otherFeesInvalidStates, setOtherFeesInvalidStates] = useState({});
  const [sellPriceInvalidStates, setSellPriceInvalidStates] = useState({});
  const [totalItemsInvalidStates, setTotalItemsInvalidStates] = useState({});
  const [weightInvalidStates, setWeightInvalidStates] = useState({});
  const [modalIndex, setModalIndex] = useState(-1);
  const deleteDialogRef = useRef<DialogRef>(null);
  const refreshProductDialogRef = useRef<DialogRef>(null);
  const downloadFNSKUDialogRef = useRef<DialogRef>(null);
  const addToAmazonInventoryDialogRef = useRef<DialogRef>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setProducts([...currentUserShipment.userShipmentItems].sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime()));
  }, [currentUserShipment]);

  const addProduct = () => {
    let newProduct: UserShipmentItem = {} as UserShipmentItem;
    newProduct.userShipmentItemId = currentUserShipment.userShipmentId;
    newProduct.totalItems = 1;
    newProduct.purchasePrice = 0;
    newProduct.tax = 0;
    newProduct.shippingPrice = 0;
    newProduct.actualTotalCostPerItem = 0;
    newProduct.actualTotalCostPerItemTarget = 0;
    newProduct.expectedTotalCost = 0;
    newProduct.expectedTotalCostTarget = 0;
    newProduct.actualTotalCost = 0;
    newProduct.actualTotalCostTarget = 0;
    newProduct.fbaFee = 0;
    newProduct.referralFee = 0;
    newProduct.otherFees = 0;
    newProduct.totalFees = 0;
    newProduct.marketplaceTaxException = 0;
    newProduct.expectedSellPrice = 0;
    newProduct.expectedSalesProceeds = 0;
    newProduct.expectedOverallCost = 0;
    newProduct.expectedProfit = 0;
    newProduct.expectedProfitPerItem = 0;
    newProduct.expectedMargin = 0;
    newProduct.expectedROI = 0;
    newProduct.expectedEstimatedPayout = 0;
    newProduct.actualSellPrice = 0;
    newProduct.actualSalesProceeds = 0;
    newProduct.actualOverallCost = 0;
    newProduct.actualProfit = 0;
    newProduct.actualProfitPerItem = 0;
    newProduct.actualMargin = 0;
    newProduct.actualROI = 0;
    newProduct.actualEstimatedPayout = 0;
    newProduct.soldItems = 0;
    newProduct.desi = 0;
    newProduct.weight = 0;
    newProduct.totalWeight = 0;
    newProduct.initialBuyBoxPrice = 0;
    newProduct.currentBuyBoxPrice = 0;
    newProduct.initialFBASellerCount = 0;
    newProduct.currentFBASellerCount = 0;
    newProduct.initialBSR = 0;
    newProduct.currentBSR = 0;
    newProduct.userInventory = {
      asin: "",
      sku: "",
    } as UserInventory;

    setProducts([...[...products, newProduct]].sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime()));
  };

  const handleInputChange = (input: React.ChangeEvent<HTMLInputElement>, index: any) => {
    let product: UserShipmentItem = {...products[index]};

    if (input.target.name === "asin") {
      input.target.value = input.target.value.toUpperCase();
    }

    // @ts-ignore
    product[input.target.name] = input.target.value;

    setProducts((prevProducts: any) => {
      const newProducts = [...prevProducts];
      newProducts[index] = product;
      return [...newProducts].sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime());
    });

    let nextStatus = true;
    if (input.target.name === "asin") {
      if (input.target.value.length === 10) {
        nextStatus = false;
      }

      setAsinInvalidStates((prevStates) => ({
        ...prevStates,
        [index]: nextStatus,
      }));
    } else {
      const numericValue = parseFloat(input.target.value);

      if (
        /^[0-9]*\.?[0-9]*$/.test(input.target.value) &&
        !isNaN(numericValue) &&
        ((numericValue >= 0 && input.target.name !== "TotalItems") || (input.target.name === "TotalItems" && numericValue > 0))
      ) {
        nextStatus = false;
      }

      if (input.target.name === "purchasePrice") {
        setPurchasePriceInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "tax") {
        setTaxInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "shippingPrice") {
        setShippingPriceInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "fbaFee") {
        setFBAFeeInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "referralFee") {
        setReferralFeeInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "otherFees") {
        setOtherFeesInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "expectedSellPrice") {
        setSellPriceInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "totalItems") {
        setTotalItemsInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }

      if (input.target.name === "weight") {
        setWeightInvalidStates((prevStates) => ({
          ...prevStates,
          [index]: nextStatus,
        }));
      }
    }
  };

  const handleInputBlur = (input: any, userShipmentItemId: any) => {
    let product: UserShipmentItem = products.find((x) => x.userShipmentItemId === userShipmentItemId)!;
    if (input.target.name === "asin") {
      if (input.target.value.length === 10) {
        let productInfos: IAddUserShipmentItemDto = {
          userStoreId: currentUserShipment.userStoreId,
          userShipmentId: currentUserShipment.userShipmentId,
          shipmentName: "",
          asin: input.target.value,
          purchasePrice: product.purchasePrice,
          tax: product.tax,
          shippingPrice: product.shippingPrice,
          fBAFee: product.fbaFee,
          referralFee: product.referralFee,
          otherFees: product.otherFees,
          expectedSellPrice: product.expectedSellPrice,
          totalItems: product.totalItems,
          weight: product.weight,
          from: "ShipmentDetails",
        };

        addUserShipmentItem(productInfos)(dispatch);
        //props.onAddUserShipmentItem(productInfos);
      }
    } else {
      let inputVal = parseFloat(input.target.value);
      // @ts-ignore
      let existItem: UserShipmentItem = products.find((x) => x.userShipmentItemId === userShipmentItemId)!;
      let existPropertyValue: Number = existItem[input.target.name as keyof UserShipmentItem] as Number;

      // Check if existPropertyValue is a string and parse it
      if (typeof existPropertyValue === "string") {
        if (product.userInventory.asin !== "" && !isNaN(inputVal) && existPropertyValue && inputVal !== existPropertyValue) {
          let newShipmentDetails: ISetUserShipmentDetailsDto = {
            userShipmentId: currentUserShipment.userShipmentId,
            userInventoryId: product.userInventoryId,
            userShipmentItemId: product.userShipmentItemId,
            field: _.upperFirst(input.target.name),
            newValue: inputVal,
            shipDate: "",
            amazonShipmentId: "",
            from: "Shipments",
          };

          setUserShipmentDetails(newShipmentDetails, "update")(dispatch);
        }
      }
    }
  };

  const handleDeleteProduct = (index: any) => {
    let product = products[index];
    if (product.userInventory?.asin) {
      let productInfos: IDeleteUserShipmentItemDto = {
        userShipmentId: currentUserShipment.userShipmentId,
        userShipmentItemId: product.userShipmentItemId,
        asin: "",
        from: "ShipmentDetails",
      };

      deleteUserShipmentItem(productInfos)(dispatch);
      deleteDialogRef.current?.hide();
    } else {
      let newProducts = [...products.slice(0, index), ...products.slice(index + 1)];
      setProducts([...newProducts].sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime()));
    }
  };

  const handleRefreshProduct = (index: any) => {
    let product = products[index];

    if (product.userInventory?.asin) {
      let productInfos: IRefreshUserShipmentItemDto = {
        userShipmentId: currentUserShipment.userShipmentId,
        userShipmentItemId: product.userShipmentItemId,
      };

      refreshUserShipmentItem(productInfos)(dispatch);
    }
  };

  const handleAddToAmazonInventory = (index: any) => {
    if (index > -1) {
      let product = products[index];

      if (product.userInventory?.asin) {
        let productInfos: IAddToAmazonInventoryDto = {
          userShipmentId: currentUserShipment.userShipmentId,
          userShipmentItemId: product.userShipmentItemId,
        };

        addToAmazonInventory(productInfos)(dispatch);
      }
    } else {
      let productInfos: IAddToAmazonInventoryDto = {
        userShipmentId: currentUserShipment.userShipmentId,
      };

      addToAmazonInventory(productInfos)(dispatch);
    }
  };

  const handleAddToAmazonCart = () => {
    if (currentUserShipment.userShipmentItems.length > 0) {
      let url = "https://www.amazon.com/gp/aws/cart/add.html";
      let parameters = "AssociateTage=";
      currentUserShipment.userShipmentItems.forEach((userShipmentItem, index) => {
        parameters = parameters.concat(`&ASIN.${index + 1}=${userShipmentItem.userInventory?.asin}&Quantity.${index + 1}=${userShipmentItem.totalItems}`);
      });

      window.open(`${url}?${parameters}`, "_blank");
    }
  };

  const handleDownloadFNSKULabels = async () => {
    setLoading("download", true)(dispatch);
    var list: BarcodeItem[] = products
      .filter((p) => p.userInventory.fnsku != null && p.userInventory.fnsku !== undefined)
      .map((product) => {
        return {
          barcode: product.userInventory.fnsku as string,
          productName: product.userInventory.productName as string,
        };
      });

    const doc = <FNSKUDocument products={list} />;
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `FNSKU-Labels-${currentUserShipment.name}.pdf`);
    setLoading("download", false)(dispatch);
  };

  const onLoad = useCallback(() => {
    if (currentUserShipment) {
      let source = ActiveMarketplaces.find((amazonMarketplace) => amazonMarketplace.marketplace === "US");
      let destination = ActiveMarketplaces.find((amazonMarketplace) => amazonMarketplace.marketplace === currentUserShipment.userStore.marketplace);
      setSourceMarketplace(source);
      setDestinationMarketplace(destination);
    }
  }, [currentUserShipment]); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleMouseDown = (e: any) => {
    const target = e.target as HTMLElement;
    if (target.tagName !== "TD") return;

    setIsMouseDown(true);

    setStartX(e.clientX);
    setScrollLeft(tableRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLTableSectionElement>) => {
    if (!isMouseDown || !tableRef.current) return;

    const x = e.clientX;
    const walk = x - startX;

    tableRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const scrollHandler = useCallback(() => {
    var theadElement = document.getElementById("fixed-thead");
    var parentElement = theadElement?.parentElement;

    let navbarHeaderHeight = 0;
    if (window.innerWidth > 1023) {
      navbarHeaderHeight = 115;
    } else {
      navbarHeaderHeight = 70;
    }
    if (theadElement && parentElement) {
      var coordinates = parentElement?.getBoundingClientRect();
      if (coordinates.y < 0) {
        theadElement.style.transform = `translate3d(0, ${-coordinates.y + navbarHeaderHeight}px, 0)`; //
        theadElement.style.position = "relative";
      } else {
        theadElement.style.transform = "translate3d(0, 0px, 0)"; // +50px başlangıç pozisyonu
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);

    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []); // eslint-disable-line

  return (
    <React.Fragment>
      <PlaceholderGlow busy={loading.save || loading.update || loading.delete}>
        <Card className="border">
          <CardHeader>
            <Row className="d-flex align-items-center gx-2">
              <Col>
                <h6 className="card-title mb-0 align-items-center d-flex">
                  {t("Shipments.ShipmentItems.ProductsTitle")}
                  {products.filter((product) => !product.userInventory?.sku).length > 1 && (
                    <span className="ms-3">
                      <Link
                        to={""}
                        className="link-body-emphasis text-decoration-underline link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link"
                        onClick={() => {
                          setModalIndex(-1);
                          addToAmazonInventoryDialogRef.current?.show();
                        }}
                      >
                        <i className="mdi mdi-store-plus fs-18 me-1"></i> {" " + t("Shipments.ShipmentItems.AddAllProductsToYourStore")}
                      </Link>
                    </span>
                  )}
                </h6>
              </Col>
              {products.some((item) => item.userInventory.fnsku) && (
                <Col xs="auto">
                  <Button type="button" color="secondary" className="d-flex align-items-center rounded-pill py-1 px-3" onClick={() => downloadFNSKUDialogRef.current?.show()}>
                    <span>{t("DownloadFNSKULabels")}</span>
                    <i className="las la-barcode label-icon align-middle fs-4 ms-1 "></i>
                  </Button>
                </Col>
              )}
              {currentUserShipment.userShipmentItems?.length > 0 && (
                <Col xs="auto">
                  <Button type="button" color="warning" className="d-flex align-items-center rounded-pill py-1 px-3" onClick={handleAddToAmazonCart}>
                    <span className="text-dark">{t("AddToAmazonCart")}</span>
                    <i className="las la-cart-arrow-down label-icon align-middle fs-4 ms-1 text-dark"></i>
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            {products.length === 0 ? (
              <div className="p-3">
                <div>{t("Shipments.ShipmentItems.NoProduct")}</div>
                <div className="mt-4">
                  <Button
                    type="button"
                    className="btn btn-success add-btn"
                    onClick={() => {
                      addProduct();
                    }}
                  >
                    {t("Shipments.ShipmentItems.AddProduct")}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="table-responsive shipment-finance-items" ref={tableRef}>
                <Table className="table align-middle position-relative table-nowrap mb-0" style={{minWidth: 1950}}>
                  <thead id="fixed-thead" className="table-light" style={{zIndex: 2, backgroundColor: "var(--vz-card-bg-custom)"}}>
                    <tr>
                      <th>
                        {t("Shipments.ShipmentItems.ASIN")} / {t("Shipments.ShipmentItems.SKU")}
                      </th>
                      <th>{t("Shipments.ShipmentItems.Quantity")}</th>
                      <th>{t("Shipments.ShipmentItems.PurchasePrice")}</th>
                      <th>{t("Shipments.ShipmentItems.Tax")}</th>
                      <th>{t("Shipments.ShipmentItems.Prep&Ship")}</th>
                      <th>{t("Shipments.ShipmentItems.FBAFee")}</th>
                      <th>{t("Shipments.ShipmentItems.ReferralFee")}</th>
                      <th>{t("Shipments.ShipmentItems.OtherFees")}</th>
                      <th>{t("Shipments.ShipmentItems.ExpectedTotalCost")}</th>
                      <th>{t("Shipments.ShipmentItems.ActualTotalCost")}</th>
                      <th>{t("Shipments.ShipmentItems.CostPerUnit")}</th>
                      <th>{t("Shipments.ShipmentItems.SellPrice")}</th>
                      {currentUserShipment.userShipmentSetting.marketplaceTaxExceptionRate > 0 ? <th>{t("Shipments.ShipmentItems.MarketplaceTaxException")}</th> : ""}
                      <th>{t("Shipments.ShipmentItems.ProfitPerUnit")}</th>
                      <th>{t("Shipments.ShipmentItems.ExpectedProfit")}</th>
                      <th>{t("Shipments.ShipmentItems.ExpectedMargin")}</th>
                      <th>{t("Shipments.ShipmentItems.ExpectedROI")}</th>
                      <th>{t("Shipments.ShipmentItems.Weight")}</th>
                      <th>{t("Shipments.ShipmentItems.TotalWeight")}</th>
                    </tr>
                  </thead>
                  <tbody
                    className="user-select-none"
                    style={{cursor: "all-scroll"}}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                  >
                    {products.map((product: UserShipmentItem, index: number) => {
                      const smallImagePath = `https://m.media-amazon.com/images/I/${product.userInventory.imageSmall}`;
                      const bigImagePath = `https://m.media-amazon.com/images/I/${product.userInventory.imageBig}`;
                      return (
                        <tr key={index} className="shipment-items trBody">
                          <td width={160}>
                            <div className="d-flex justify-content-left">
                              <span className="d-flex align-items-center">
                                <Link
                                  to=""
                                  id={`DeleteProduct-${index}`}
                                  className="btn btn-ghost-danger px-1 py-0 fs-16"
                                  onClick={() => {
                                    if (product?.userInventory?.asin) {
                                      setModalIndex(index);
                                      deleteDialogRef.current?.show();
                                    } else {
                                      handleDeleteProduct(index);
                                    }
                                  }}
                                >
                                  <i className="ri-delete-bin-fill"></i>
                                </Link>
                                <DefaultUncontrolledTooltip target={`DeleteProduct-${index}`}>{t("Shipments.ShipmentItems.Delete")}</DefaultUncontrolledTooltip>
                              </span>
                              {product?.userInventory?.asin ? (
                                <span
                                  style={{
                                    marginTop: 2.2,
                                    marginLeft: 5,
                                  }}
                                >
                                  <Row className="flex-nowrap align-items-center">
                                    <Col className="ms-2 pe-0">
                                      <ImagePopup
                                        identifier={`ImagePopup-${product.userShipmentItemId}`}
                                        imageBig={bigImagePath}
                                        imageSmall={smallImagePath}
                                        className="rounded-circle me-1 border"
                                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                          e.currentTarget.onerror = null;
                                          e.currentTarget.src = errorImage;
                                        }}
                                      />
                                    </Col>
                                    <Col>
                                      <div className="d-flex align-items-center">
                                        <CopyAsinWidget
                                          asin={product.userInventory.asin}
                                          index={product.userInventory.asin}
                                          asLink={product.userSearchId !== undefined && product.userSearchId !== EMPTY_GUID && product.userSearchId !== null}
                                          customLink={`/searches/search-results/${product.userSearchId}?asin=${product.userInventory.asin}`}
                                          tooltipText={t("Shipments.ShipmentItems.Dialog.NoSource.Description")}
                                        />

                                        <Link to={`https://www.amazon.${destinationMarketplace?.domain}/dp/${product.userInventory.asin}?th=1&psc=1`} target="_blank" className="ms-1">
                                          <i className="bx bxl-amazon label-icon align-middle fs-18"></i>
                                        </Link>
                                      </div>
                                      <div>
                                        {product.userInventory?.sku ? (
                                          <CopyAsinWidget asin={product.userInventory.sku} index={product.userInventory.sku} />
                                        ) : (
                                          product.userInventory?.asin && (
                                            <span>
                                              <Link
                                                to={""}
                                                className="link-body-emphasis text-decoration-underline link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link"
                                                onClick={() => {
                                                  setModalIndex(index);
                                                  addToAmazonInventoryDialogRef.current?.show();
                                                }}
                                              >
                                                <i className="mdi mdi-store-plus fs-18 me-1"></i> {" " + t("Shipments.ShipmentItems.AddToYourStore")}
                                              </Link>
                                            </span>
                                          )
                                        )}
                                      </div>
                                      {product.userInventory?.fnsku && (
                                        <div className="d-flex align-items-center text-muted fs-12 mt-n1">
                                          <span className="me-1">FNSKU:</span>
                                          <CopyAsinWidget asin={product.userInventory.fnsku} index={product.userInventory.fnsku} />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </span>
                              ) : (
                                <span
                                  style={{
                                    marginTop: 0,
                                    marginLeft: 5,
                                  }}
                                >
                                  <Input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="asin"
                                    placeholder="B000000000"
                                    size={40}
                                    onChange={(event) => handleInputChange(event, index)}
                                    onBlur={(event) => {
                                      handleInputBlur(event, product.userShipmentItemId);
                                    }}
                                    invalid={!!asinInvalidStates[index as keyof typeof asinInvalidStates]}
                                  />
                                  <FormFeedback type="invalid" className="shipment-items validation">
                                    {t("Invalid ASIN")}
                                  </FormFeedback>
                                </span>
                              )}
                            </div>
                          </td>

                          <td width="80">
                            <span>
                              <Input
                                type="text"
                                className="form-control form-control-sm"
                                name="totalItems"
                                value={product.totalItems}
                                onChange={(event) => handleInputChange(event, index)}
                                onBlur={(event) => {
                                  handleInputBlur(event, product.userShipmentItemId);
                                }}
                                onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                invalid={!!totalItemsInvalidStates[index as keyof typeof totalItemsInvalidStates]}
                              />
                              <FormFeedback type="invalid" className="shipment-items validation">
                                {t("Invalid")}
                              </FormFeedback>
                            </span>
                          </td>
                          <td width={240}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group"
                                  name="purchasePrice"
                                  value={product.purchasePrice === 0 ? product.purchasePrice.toFixed(2) : product.purchasePrice}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!purchasePriceInvalidStates[index as keyof typeof purchasePriceInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.purchasePrice === 0 && "bg-danger"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {sourceMarketplace?.currency}
                                </InputGroupText>
                                {product.createDate !== undefined && getToday().diff(moment(product.createDate), "hours") >= 24 ? (
                                  <>
                                    <Link
                                      to=""
                                      id={`RefreshProductInfos-${index}`}
                                      className="link-warning link-offset-2 text-decoration-none fs-16 px-1"
                                      onClick={() => {
                                        setModalIndex(index);
                                        refreshProductDialogRef.current?.show();
                                      }}
                                    >
                                      <i className="mdi mdi-refresh"></i>
                                    </Link>
                                    <DefaultUncontrolledTooltip target={`RefreshProductInfos-${index}`}>{t("Shipments.ShipmentItems.RefreshWarning")}</DefaultUncontrolledTooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          <td width={220}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group"
                                  name="tax"
                                  value={product.tax === 0 ? product.tax.toFixed(2) : product.tax}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!taxInvalidStates[index as keyof typeof taxInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.tax === 0 && "bg-warning"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {sourceMarketplace?.currency}
                                </InputGroupText>
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          <td width={130}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group"
                                  name="shippingPrice"
                                  value={product.shippingPrice === 0 ? product.shippingPrice.toFixed(2) : product.shippingPrice}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!shippingPriceInvalidStates[index as keyof typeof shippingPriceInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.shippingPrice === 0 && "bg-warning"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {sourceMarketplace?.currency}
                                </InputGroupText>
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          <td width={160}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group"
                                  name="fbaFee"
                                  value={product.fbaFee === 0 ? product.fbaFee.toFixed(2) : product.fbaFee}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!fBAFeeInvalidStates[index as keyof typeof fBAFeeInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.fbaFee === 0 && "bg-danger"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {destinationMarketplace?.currency}
                                </InputGroupText>
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          <td width={160}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group"
                                  name="referralFee"
                                  value={product.referralFee === 0 ? product.referralFee.toFixed(2) : product.referralFee}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!referralFeeInvalidStates[index as keyof typeof referralFeeInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.referralFee === 0 && "bg-danger"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {destinationMarketplace?.currency}
                                </InputGroupText>
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          <td width={180}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group"
                                  name="otherFees"
                                  value={product.otherFees === 0 ? product.otherFees.toFixed(2) : product.otherFees}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!otherFeesInvalidStates[index as keyof typeof otherFeesInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.otherFees === 0 && "bg-warning"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {destinationMarketplace?.currency}
                                </InputGroupText>
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          <td width={120}>{product.expectedTotalCost?.toFixed(2) + " " + sourceMarketplace?.currency}</td>
                          <td width={120}>
                            <span className="badge rounded-pill bg-success-subtle text-success fs-12">{product.actualTotalCost?.toFixed(2) + " " + sourceMarketplace?.currency}</span>
                          </td>
                          <td width={120}>{product.actualTotalCostPerItem?.toFixed(2) + " " + sourceMarketplace?.currency}</td>
                          <td width={140}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group pe-0"
                                  name="expectedSellPrice"
                                  value={product.expectedSellPrice === 0 ? product.expectedSellPrice.toFixed(2) : product.expectedSellPrice}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!sellPriceInvalidStates[index as keyof typeof sellPriceInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.expectedSellPrice === 0 && "bg-danger"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {destinationMarketplace?.currency}
                                </InputGroupText>
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          {currentUserShipment.userShipmentSetting.marketplaceTaxExceptionRate > 0 ? (
                            <td width={120}>{product.marketplaceTaxException.toFixed(2) + " " + destinationMarketplace?.currency}</td>
                          ) : (
                            ""
                          )}
                          <td width={120}>
                            <span className={`badge rounded-pill bg-success-subtle ${product.expectedProfitPerItem && product?.expectedProfitPerItem >= 0 ? "text-success" : "text-danger"} fs-12`}>
                              {product.expectedProfitPerItem?.toFixed(2) + " " + destinationMarketplace?.currency}
                            </span>
                          </td>
                          <td width={120}>
                            <span className={`badge rounded-pill bg-success-subtle ${product.expectedProfit >= 0 ? "text-success" : "text-danger"} fs-12`}>
                              {product.expectedProfit?.toFixed(2) + " " + destinationMarketplace?.currency}
                            </span>
                          </td>
                          <td width={110}>%{product.expectedMargin}</td>
                          <td width={110}>%{product.expectedROI}</td>
                          <td width={160}>
                            <span>
                              <InputGroup size="sm">
                                <Input
                                  type="text"
                                  className="form-control form-control-sm border-input-group"
                                  name="weight"
                                  value={product.weight === 0 ? product.weight.toFixed(2) : product.weight}
                                  onChange={(event) => handleInputChange(event, index)}
                                  onBlur={(event) => {
                                    handleInputBlur(event, product.userShipmentItemId);
                                  }}
                                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event, product.userShipmentItemId)}
                                  invalid={!!weightInvalidStates[index as keyof typeof weightInvalidStates]}
                                />
                                <InputGroupText className={`ps-2 ${product.weight === 0 && "bg-danger"}`} style={{background: "var(--vz-input-bg-custom)"}}>
                                  {t("lb")}
                                </InputGroupText>
                                <FormFeedback type="invalid" className="shipment-items validation">
                                  {t("Invalid")}
                                </FormFeedback>
                              </InputGroup>
                            </span>
                          </td>
                          <td width={110}>
                            {product.totalWeight?.toFixed(2)} {t("lb")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot className="table-light fw-bold" style={{zIndex: 2, backgroundColor: "var(--vz-card-bg-custom)"}}>
                    <tr>
                      <td colSpan={1}>
                        <Button
                          type="button"
                          className="btn btn-success btn-sm add-btn"
                          onClick={() => {
                            addProduct();
                          }}
                        >
                          + {t("Shipments.ShipmentItems.AddProduct")}
                        </Button>
                      </td>
                      <td colSpan={2}>
                        {products.reduce((accumulator: number, product: UserShipmentItem) => {
                          return accumulator + Number(product.totalItems);
                        }, 0)}{" "}
                        {t("items")}
                      </td>
                      <td colSpan={3}>
                        {t("Costs")}:{" "}
                        {products
                          .reduce((accumulator: any, product: UserShipmentItem) => {
                            return accumulator + Number(product.expectedTotalCost);
                          }, 0)
                          .toFixed(2)}{" "}
                        {sourceMarketplace?.currency}
                      </td>
                      <td colSpan={currentUserShipment.userShipmentSetting.marketplaceTaxExceptionRate > 0 ? 12 : 11}>
                        {t("Fees")}:{" "}
                        {products
                          .reduce((accumulator: any, product: UserShipmentItem) => {
                            return accumulator + Number(product.totalFees);
                          }, 0)
                          .toFixed(2)}{" "}
                        {destinationMarketplace?.currency}
                      </td>
                      <td>
                        {products
                          .reduce((accumulator: any, product: UserShipmentItem) => {
                            return accumulator + Number(product.totalWeight);
                          }, 0)
                          .toFixed(2)}{" "}
                        {t("lb")}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>
      </PlaceholderGlow>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Yes, Delete It!"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("Close"),
            },
          };
        }}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Shipments.ShipmentItems.Dialog.Delete.Description")}
        title={t("Shipments.ShipmentItems.Dialog.Delete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            handleDeleteProduct(modalIndex);
          }
          deleteDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={refreshProductDialogRef}
        color="warning"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Yes, Refresh It!"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("Close"),
            },
          };
        }}
        busy={loading.delete}
        iconClass="mdi mdi-refresh"
        message={t("Shipments.ShipmentItems.Dialog.Refresh.Description")}
        title={t("Shipments.ShipmentItems.Dialog.Refresh.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            handleRefreshProduct(modalIndex);
          }
          refreshProductDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={addToAmazonInventoryDialogRef}
        color="secondary"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Yes"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("No"),
            },
          };
        }}
        busy={false}
        iconClass="bx bxl-amazon"
        title={t("Shipments.ShipmentItems.Dialog.AddToAmazonInventory.Title")}
        message={t("Shipments.ShipmentItems.Dialog.AddToAmazonInventory.Description", {storeName: currentUserShipment.userStore.name})}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            handleAddToAmazonInventory(modalIndex);
          }
          addToAmazonInventoryDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={downloadFNSKUDialogRef}
        color="secondary"
        buttons={["download", "cancel"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            download: {
              ...buttonConfigs.download,
              label: t("Download"),
            },
            cancel: {
              ...buttonConfigs.cancel,
              label: t("Cancel"),
            },
          };
        }}
        busy={loading.download}
        iconClass="mdi mdi-barcode-scan"
        message={
          products.some((product) => product.userInventory?.fnsku == null || product.userInventory?.productName === null)
            ? t("Shipments.ShipmentItems.Dialog.DownloadFNSKULabels.DescriptionFNSKUNotFound")
            : t("Shipments.ShipmentItems.Dialog.DownloadFNSKULabels.Description")
        }
        title={t("Shipments.ShipmentItems.Dialog.DownloadFNSKULabels.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "download") {
            await handleDownloadFNSKULabels();
          }
          downloadFNSKUDialogRef.current?.hide();
        }}
      />
    </React.Fragment>
  );
};

export default Products;
