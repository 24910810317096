import React from "react";
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";

export interface BreadcrumbMenuItem {
  label: string;
  url?: string;
}

interface BreadCrumbProps {
  title: string;
  menus: BreadcrumbMenuItem[];
  component?: JSX.Element;
}

const BreadCrumb = ({title, component, menus}: BreadCrumbProps) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="mb-sm-0">{title}</h4>
              {component && <div className="ms-3">{component}</div>}
            </div>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {menus &&
                  menus.map((menu, index) =>
                    menu.url && menu.url !== "" ? (
                      <li key={index} className="breadcrumb-item">
                        <Link to={menu.url ?? ""}>{menu.label}</Link>
                      </li>
                    ) : (
                      <li key={index} className="breadcrumb-item active">
                        {menu.label}
                      </li>
                    ),
                  )}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
