import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayNumber from "Components/Common/DisplayNumber";
import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";

interface SaleAnalysisProps {
  item: UserSearchProduct;
}
const SaleAnalysis = ({item}: SaleAnalysisProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.SaleAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.SaleAnalysis.SaleCount", {day: 30})}</span>
              {item.saleAnalysis?.hasAnomalies && (
                <>
                  <i id={`PopoverHasAnomalies-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
                  <DefaultUncontrolledTooltip target={`PopoverHasAnomalies-${item.userSearchProductId}`}>{t("SearchResults.Summary.SaleCount.Anomalies")}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
            <span>
              <DisplayNumber value={item.saleAnalysis?.saleCount} renderOnEmpty={<>{t("NA")}</>} />
            </span>
          </li>
          <li className="d-flex justify-content-between lh-sm">
            <span>{t("SearchResults.Analyzes.SaleAnalysis.SaleTrend")}</span>
            <span className="d-flex align-items-center">
              {item.saleAnalysis.saleTrendUp != null &&
                item.saleAnalysis.saleTrendRate !== null &&
                (item.saleAnalysis.saleTrendUp === true ? (
                  <i className="bx bx-up-arrow-alt align-top fs-18 text-success common-css"></i>
                ) : (
                  <i className="bx bx-down-arrow-alt align-top fs-18 text-danger common-css"></i>
                ))}
              <DisplayNumber value={item.saleAnalysis?.saleTrendRate} suffix={"%"} decimals={2} renderOnEmpty={<>{t("NA")}</>} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.SaleAnalysis.Category")}</span>
            </div>
            <span className="ellipsis-single-line-category" title={item.summary?.categoryName}>
              {item.summary?.categoryName ? item.summary?.categoryName : t("NA")}
            </span>
          </li>

          <li className="d-flex justify-content-between common-css">
            <div>
              <span>{t("SearchResults.Analyzes.SaleAnalysis.BSRAvg", {day: 30})}</span>
            </div>
            <DisplayNumber value={item.summary?.bsrAvg} renderOnEmpty={<>{t("NA")}</>} />
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default SaleAnalysis;
