import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {ShipmentOrder, UserShipment} from "models/user_shipment";

export type ShipmentLoadingState = LoadingStates<"list" | "filter" | "save" | "update" | "delete" | "implementSettings" | "changeStatus" | "analyzeShipment" | "download">;

export type ShipmentState = {
  loading: ShipmentLoadingState;
  userShipmentList: Partial<PagedList<UserShipment>>;
  currentUserShipment: UserShipment;
  currentUserShipmentOrders: ShipmentOrder[];
  error: ApiError;
};

const initialState: ShipmentState = {
  loading: {
    list: false,
    filter: true,
    update: false,
    save: false,
    delete: false,
    implementSettings: false,
    changeStatus: false,
    analyzeShipment: false,
    download: false
  },
  userShipmentList: {items: []},
  currentUserShipment: {} as UserShipment,
  currentUserShipmentOrders: [],
  error: {} as ApiError,
};

const ShipmentSlice = createSlice({
  name: "Shipment",
  initialState,
  reducers: {
    setUserShipmentList(state, action: PayloadAction<PagedList<UserShipment>>) {
      state.userShipmentList = action.payload;
    },
    setCurrentUserShipment(state, action: PayloadAction<UserShipment>) {
      state.currentUserShipment = action.payload;
    },
    setCurrentUserShipmentOrder(state, action: PayloadAction<ShipmentOrder[]>) {
      state.currentUserShipmentOrders = action.payload.sort((a: ShipmentOrder, b: ShipmentOrder) => {
        return new Date(b.assignmentDate).getTime() - new Date(a.assignmentDate).getTime();
      });
    },
    loading(state, action: PayloadAction<[keyof ShipmentLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.userShipmentList = initialState.userShipmentList;
      state.currentUserShipment = initialState.currentUserShipment;
      state.error = initialState.error;
    },
  },
});

export const {setUserShipmentList, setCurrentUserShipment, setCurrentUserShipmentOrder, loading, apiError, reset} = ShipmentSlice.actions;

export default ShipmentSlice.reducer;
