import {initModal} from "@doopage/use-modal";

type ModalPayload<T> = {
  data: T;
};

// Modal instance tipi
interface ModalInstance<T> {
  open: (payload: T) => void;
  close: (clearData?: boolean) => void;
  useModal: () => {open: boolean; data: T | undefined};
}
// Wrapper fonksiyonu
export function createTypedModal<T>(name: string): ModalInstance<T> {
  const modal = initModal(name);

  return {
    open: (payload: T) => {
      const modalPayload: ModalPayload<T> = {data: payload};
      modal.open(modalPayload);
    },
    close: modal.close,
    useModal: () => {
      const {open, data} = modal.useModal() as {
        open: boolean;
        data: ModalPayload<T> | undefined;
      };
      // data?.data şeklinde gelen veriyi düzleştirme
      return {open, data: data as any};
    },
  };
}
