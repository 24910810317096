import {Link} from "react-router-dom";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";

const LimitExceeded = () => {
  const {t} = useTranslation();
  return (
    <div>
      <Container>
        <Card className="rounded-4 bg-overlay-pattern shadow-sm">
          <CardBody>
            <Row className="justify-content-center mb-3">
              <Col lg={8}>
                <div className="my-5">
                  <div className="avatar-lg mx-auto">
                    <div className="avatar-title bg-light text-warning display-3 rounded-circle">
                      <i className="ri-error-warning-fill"></i>
                    </div>
                  </div>
                  <h2
                    className="mt-4 mb-4 fw-semibold lh-base text-center"
                    dangerouslySetInnerHTML={{
                      __html: t("ProductDetails.Dialog.LimitExceed.Title"),
                    }}
                  ></h2>
                  <p className="fs-17 mb-4">{t("ProductDetails.Dialog.LimitExceed.Description")}</p>
                  <p className="fs-17 mb-4">{t("ProductDetails.Dialog.LimitExceed.SubMessage")}</p>
                  <p className="text-muted">{t("ProductDetails.Dialog.LimitExceed.FooterMessage")}</p>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row>
          <div className="mt-0 text-center">
            <Link to={"/account/register"} className="btn btn-secondary rounded-pill">
              {t("Register.Title")} <i className="ri-arrow-right-line align-middle ms-1"></i>
            </Link>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default LimitExceeded;
