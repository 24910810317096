
export type CheckListStatus = "success" | "danger" | "warning" | "muted";
interface CheckListItemProps {
  labelName: string;
  status: CheckListStatus;
  hide?: boolean;
}
const CheckListItem = ({labelName, status, hide}: CheckListItemProps) => {
  if (hide) return null;

  const getStatusIcon = () => {
    switch (status) {
      case "success":
        return <i className="ri-checkbox-circle-fill fs-18 text-success"></i>;
      case "danger":
        return <i className="ri-close-circle-fill fs-18 text-danger"></i>;
      case "warning":
        return <i className="ri-error-warning-fill fs-18 text-warning"></i>;
      default:
        return <i className="ri-donut-chart-line fs-18 text-muted"></i>;
    }
  };

  return (
    <li>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-1">{getStatusIcon()}</div>
        <div className="flex-grow-1">
          <h6 className={"mb-0" + (status === "muted" ? " text-muted " : "")}>{labelName}</h6>
        </div>
      </div>
    </li>
  );
};

export default CheckListItem;
