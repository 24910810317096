import {useFormik} from "formik";
import {useCallback, useEffect} from "react";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {GetUserFavoriteListQuery} from "api/query";
import * as Yup from "yup";

interface FiltersProps {
  fields: FieldConfig<GetUserFavoriteListQuery>[];
}
const Filters = (props: FiltersProps) => {
  // validation
  const validation = useFormik({
    initialValues: {} as GetUserFavoriteListQuery,
    validationSchema: Yup.object<GetUserFavoriteListQuery>({}),
    onSubmit: (values: GetUserFavoriteListQuery) => {
      const payload: GetUserFavoriteListQuery = {
        page: values.page,
        pageSize: values.pageSize,
        marketplace: values.marketplace,
        sortBy: values.sortBy,
        sortingOrder: values.sortingOrder,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<GetUserFavoriteListQuery>(props.fields, validation);

  const onLoad = useCallback(() => {
    validation.handleSubmit();
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return <></>;
};

export default Filters;
