import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {UserSearchProduct} from "models/user_search_product";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody, Col, Row} from "reactstrap";

interface ProductSizeProps {
  item: UserSearchProduct;
}
const ProductSize = ({item}: ProductSizeProps) => {
  const {t} = useTranslation();
  const [dimensionInMetric, setDimensionInMetric] = useState("");
  const [weightInMetric, setWeightInMetric] = useState("");
  const [dimensionalWeightInMetric, setDimensionalWeightInMetric] = useState("");

  const LIBRE_UNIT = 0.45359237; // 1lb equals to 0.45359237kg
  const INCH_UNIT = 2.54; // 1 inch equals to 2.54cm

  const onLoad = useCallback(() => {
    if (item.productSize) {
      const splitDimension = item.productSize.dimensions?.replaceAll(" ", "").split("*");
      if (splitDimension) {
        let length = parseFloat(splitDimension[0]);
        let width = parseFloat(splitDimension[1]);
        let height = parseFloat(splitDimension[2]);

        if (length && width && height) {
          length = length * INCH_UNIT;
          width = width * INCH_UNIT;
          height = height * INCH_UNIT;

          setDimensionInMetric(`${length.toFixed(2)} * ${width.toFixed(2)} * ${height.toFixed(2)}`);
        }
      }

      if (item.productSize.weight) {
        setWeightInMetric((item.productSize.weight * LIBRE_UNIT).toFixed(2).toString());
      }
      if (item.productSize.dimensionalWeight) {
        setDimensionalWeightInMetric((item.productSize.dimensionalWeight * LIBRE_UNIT).toFixed(2).toString());
      }
    }
  }, [item]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const renderDimensions = (showIcons: boolean, value?: string, statusValue?: boolean): JSX.Element => {
    return (
      <>
        {value ? (
          <Row>
            <Col className="d-flex justify-content-end pe-0">{`${value}`}</Col>
            <Col xs="auto" className="ps-0">
              {showIcons && (statusValue ? <i className="mdi mdi-check text-success ms-1"></i> : <i className="mdi mdi-alert align-middle text-warning ms-1"></i>)}
            </Col>
          </Row>
        ) : (
          <span>{t("NA")}</span>
        )}
      </>
    );
  };
  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.ProductSize.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.ProductSize.Dimensions", {value: "inch"})}</span>{" "}
              <i id={`PopoverDimensions-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverDimensions-${item.userSearchProductId}`}>{t("SearchResults.Analyzes.ProductSize.Tooltip.Dimensions")}</DefaultUncontrolledTooltip>
            </div>
            {renderDimensions(item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING, item.productSize?.dimensions + " inches", item.productSize?.dimensionsStatus)}
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.ProductSize.Dimensions", {value: "cm"})}</span>
              <i id={`PopoverDimensions-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverDimensions-${item.userSearchProductId}`}>{t("SearchResults.Analyzes.ProductSize.Tooltip.Dimensions")}</DefaultUncontrolledTooltip>
            </div>

            {renderDimensions(item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING, dimensionInMetric + " cm", item.productSize?.dimensionsStatus)}
          </li>
          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.ProductSize.Weight")}</span>
            </div>
            {renderDimensions(item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING, `${item.productSize?.weight} lb (${weightInMetric} kg)`, item.productSize?.weightStatus)}
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.ProductSize.DimensionalWeight")}</span>{" "}
              <i id={`PopoverDimensionalWeight-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverDimensionalWeight-${item.userSearchProductId}`} placeholder="bg-danger">
                {t("Definitions.DimensionalWeight")}
              </DefaultUncontrolledTooltip>
            </div>
            {
              renderDimensions(item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING, `${item.productSize?.dimensionalWeight} lb (${dimensionalWeightInMetric} kg)`, item.productSize?.dimensionalWeightStatus)
            }
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default ProductSize;
