import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect} from "react";
import {useFormik} from "formik";
import {ExchangeOptionItem} from "models/user_setting_exchange_options";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, FormFeedback, Input, InputGroup, InputGroupText, Label, Row, Spinner} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getExchangeRates, updateExchangeRates} from "slices/settings/thunk";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Loader from "Components/Common/Loader";
import ErrorPopover from "Components/Common/ErrorPopover";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import * as Yup from "yup";
import {generateRandomGuid} from "helpers/utilities";

const PAGE_IDENTIFIER: ConstantPage = "settings.exchangeRates";
const ExchangeRates = () => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getExchangeRates());
    }
  }, [dispatch]); //eslint-disable-line

  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      exchangeRates: state.Settings.exchangeRates,
      defaultCurrencies: state.Settings.defaultCurrencies,
    }),
  );
  const {loading, exchangeRates, defaultCurrencies} = useSelector(settingsPageData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      cad: exchangeRates?.find((x) => x.currencyCode === "CAD")?.rate || "",
      gbp: exchangeRates?.find((x) => x.currencyCode === "GBP")?.rate || "",
      aud: exchangeRates?.find((x) => x.currencyCode === "AUD")?.rate || "",
      mxn: exchangeRates?.find((x) => x.currencyCode === "MXN")?.rate || "",
      cadAuto: exchangeRates?.find((x) => x.currencyCode === "CAD")?.auto || false,
      gbpAuto: exchangeRates?.find((x) => x.currencyCode === "GBP")?.auto || false,
      audAuto: exchangeRates?.find((x) => x.currencyCode === "AUD")?.auto || false,
      mxnAuto: exchangeRates?.find((x) => x.currencyCode === "MXN")?.auto || false,
    },
    validationSchema: Yup.object({
      cad: Yup.number().when("cadAuto", ([cadAuto], schema) => {
        if (cadAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "CAD"}));
      }),
      gbp: Yup.number().when("gbpAuto", ([gbpAuto], schema) => {
        if (gbpAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "GBP"}));
      }),
      aud: Yup.number().when("audAuto", ([audAuto], schema) => {
        if (audAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "AUD"}));
      }),
      mxn: Yup.number().when("mxnAuto", ([audAuto], schema) => {
        if (audAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "MXN"}));
      }),
      cadAuto: Yup.boolean(),
      gbpAuto: Yup.boolean(),
      audAuto: Yup.boolean(),
      mxnAuto: Yup.boolean(),
    }),
    onSubmit: (values: any) => {
      var exchanges: ExchangeOptionItem[] = [];
      exchanges.push({currency: "CAD", marketplace: "CA", rate: values.cad, auto: values.cadAuto});
      exchanges.push({currency: "GBP", marketplace: "UK", rate: values.gbp, auto: values.gbpAuto});
      exchanges.push({currency: "AUD", marketplace: "AU", rate: values.aud, auto: values.audAuto});
      exchanges.push({currency: "MXN", marketplace: "MX", rate: values.mxn, auto: values.mxnAuto});

      if (validation.dirty) {
        const updatePromise = updateExchangeRates(exchanges)(dispatch);
        updatePromise.then((success) => {
          if (success) {
            validation.resetForm();
          }
        });
      }
    },
  });

  document.title = t("PageTitles.ExchangeRates");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "#",
    },
    {
      label: t("Settings.ExchangeRates.Title"),
      url: "",
    },
  ];

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={validation.dirty} navigateTo="">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardHeader>
                  <CardTitle className="fw-medium mb-0 align-items-center d-flex">
                    <i className="bx bx-dollar fs-18 me-1"></i>
                    {t("Settings.ExchangeRates.Title")}
                  </CardTitle>
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  {loading.list ? (
                    <Loader />
                  ) : (
                    <Row className="mb-3">
                      <Col xs={12} lg>
                        <Label className="form-label text-nowrap">{t("Settings.ExchangeRates.Canada")}</Label>
                        <InputGroup>
                          <InputGroupText>1 USD</InputGroupText>
                          <Input
                            id="cad"
                            type="number"
                            step="any"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cad}
                            invalid={validation.touched.cad && validation.errors.cad ? true : false}
                            disabled={validation.values.cadAuto}
                          />
                          <InputGroupText>CAD</InputGroupText>
                        </InputGroup>
                        <FormFeedback>{validation.values.cadAuto && validation.errors.cad}</FormFeedback>

                        <div className="form-check form-switch mt-3 float-end ">
                          <Input
                            id="cadAuto"
                            type="checkbox"
                            role="switch"
                            className="form-check-input"
                            onChange={(e) => {
                              validation.handleChange(e);
                              validation.setFieldValue("cad", defaultCurrencies.find((x) => x.currencyCode === "CAD")?.rate);
                            }}
                            onBlur={validation.handleBlur}
                            checked={validation.values.cadAuto}
                          />
                          <Label htmlFor="cadAuto" className="form-label user-select-none">
                            {t("Settings.ExchangeRates.Automatic")}
                          </Label>
                        </div>
                      </Col>

                      <div className="d-block d-lg-none my-3" style={{border: "solid thin #dedede"}}></div>

                      <Col xs={12} lg>
                        <Label className="form-label text-nowrap">{t("Settings.ExchangeRates.UnitedKingdom")}</Label>
                        <InputGroup>
                          <InputGroupText>1 USD</InputGroupText>
                          <Input
                            id="gbp"
                            type="number"
                            step="any"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.gbp}
                            invalid={validation.touched.gbp && validation.errors.gbp ? true : false}
                            disabled={validation.values.gbpAuto}
                          />
                          <InputGroupText>GBP</InputGroupText>
                        </InputGroup>
                        <FormFeedback>{validation.values.gbpAuto && validation.errors.gbp}</FormFeedback>

                        <div className="form-check form-switch mt-3 float-end ">
                          <Input
                            id="gbpAuto"
                            type="checkbox"
                            role="switch"
                            className="form-check-input"
                            onChange={(e) => {
                              validation.handleChange(e);
                              validation.setFieldValue("gbp", defaultCurrencies.find((x) => x.currencyCode === "GBP")?.rate);
                            }}
                            onBlur={validation.handleBlur}
                            checked={validation.values.gbpAuto}
                          />
                          <Label htmlFor="gbpAuto" className="form-label user-select-none">
                            {t("Settings.ExchangeRates.Automatic")}
                          </Label>
                        </div>
                      </Col>

                      <div className="d-block d-lg-none my-3" style={{border: "solid thin #dedede"}}></div>

                      <Col xs={12} lg>
                        <Label className="form-label text-nowrap">{t("Settings.ExchangeRates.Australia")}</Label>
                        <InputGroup>
                          <InputGroupText>1 USD</InputGroupText>
                          <Input
                            id="aud"
                            type="number"
                            step="any"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.aud}
                            invalid={validation.touched.aud && validation.errors.aud ? true : false}
                            disabled={validation.values.audAuto}
                          />
                          <InputGroupText>AUD</InputGroupText>
                        </InputGroup>
                        <FormFeedback>{validation.values.audAuto && validation.errors.audAuto}</FormFeedback>

                        <div className="form-check form-switch mt-3 float-end ">
                          <Input
                            id="audAuto"
                            type="checkbox"
                            role="switch"
                            className="form-check-input"
                            onChange={(e) => {
                              validation.handleChange(e);
                              validation.setFieldValue("aud", defaultCurrencies.find((x) => x.currencyCode === "AUD")?.rate);
                            }}
                            onBlur={validation.handleBlur}
                            checked={validation.values.audAuto}
                          />
                          <Label htmlFor="audAuto" className="form-label user-select-none">
                            {t("Settings.ExchangeRates.Automatic")}
                          </Label>
                        </div>
                      </Col>

                      <div className="d-block d-lg-none my-3" style={{border: "solid thin #dedede"}}></div>

                      <Col xs={12} lg>
                        <Label className="form-label text-nowrap">{t("Settings.ExchangeRates.Mexico")}</Label>
                        <InputGroup>
                          <InputGroupText>1 USD</InputGroupText>
                          <Input
                            id="mxn"
                            type="number"
                            step="any"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mxn}
                            invalid={validation.touched.mxn && validation.errors.mxn ? true : false}
                            disabled={validation.values.mxnAuto}
                          />
                          <InputGroupText>MXN</InputGroupText>
                        </InputGroup>
                        <FormFeedback>{validation.values.mxnAuto && validation.errors.mxnAuto}</FormFeedback>

                        <div className="form-check form-switch mt-3 float-end ">
                          <Input
                            id="mxnAuto"
                            type="checkbox"
                            role="switch"
                            className="form-check-input"
                            onChange={(e) => {
                              validation.handleChange(e);
                              validation.setFieldValue("mxn", defaultCurrencies.find((x) => x.currencyCode === "MXN")?.rate);
                            }}
                            onBlur={validation.handleBlur}
                            checked={validation.values.mxnAuto}
                          />
                          <Label htmlFor="mxnAuto" className="form-label user-select-none">
                            {t("Settings.ExchangeRates.Automatic")}
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
              <Row className="justify-content-end">
                <Col xs="auto">{Object.keys(validation.errors).length > 0 && <ErrorPopover key={generateRandomGuid()} validationErrors={validation.errors} />}</Col>
                <Col xs="auto">
                  <Button color="success" type="submit" disabled={loading.update || Object.keys(validation.errors).length > 0}>
                    {loading.update && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                    {t("Settings.ExchangeRates.Button.SaveChanges")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </SettingsLayout>
        </Container>
      </div>
    </Restricted>
  );
};

export default ExchangeRates;
