import React from "react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Container, Spinner} from "reactstrap";
import {t} from "i18next";

const SubscriptionRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract URL parameters if needed.
    const params = new URLSearchParams(window.location.search);
    const subscribed = params.get("subscribed");
    const subscriptionValue = params.get("subscriptionValue");

    // Perform any logging or tracking here if needed.
    console.log("Subscription:", subscribed, "Value:", subscriptionValue);

    // Redirect to getting started page after a short delay.
    setTimeout(() => {
      navigate("/getting-started");
    }, 3000); // 1-second delay for user experience, adjust as needed.
  }, [navigate]);

  return (
    <React.Fragment>
      <div className="page-content">
        <section className="section">
          <Container className="text-center">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner size="sm" color="secondary" className="me-2"></Spinner>
              <p className=" mb-0">{t("RedirectingPleaseWait")}</p>
            </div>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionRedirect;
