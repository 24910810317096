import React, {ForwardedRef, RefAttributes, forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Row, Table} from "reactstrap";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  flexRender,
  ColumnDef,
  PaginationState,
  RowSelectionState,
  SortingState,
  ColumnResizeMode,
  ColumnResizeDirection,
} from "@tanstack/react-table";
import {TablePagination} from "./_Pagination";
import {SorterQuery} from "helpers/types";
import {SortingDirection} from "models/enums/sorting_direction";
import {useLocation} from "react-router-dom";
import classNames from "classnames";
import Checkbox from "../Checkbox";
import Loader from "../Loader";
import _ from "lodash";
import {UserShipmentItem} from "models/user_shipment_item";

export const selectRowColumn = <T,>(): ColumnDef<T, any> => ({
  id: "#",
  enableHiding: false,
  header: (cell) => <Checkbox onChange={cell.table.toggleAllRowsSelected} indeterminate={cell.table.getIsSomeRowsSelected()} value={cell.table.getIsAllRowsSelected()} />,
  cell: (cell) => {
    return <Checkbox value={cell.row.getIsSelected()} onChange={cell.row.toggleSelected} />;
  },
});
export type DataTableProps<D> = {
  busy: boolean;
  columns: ColumnDef<D, any>[];
  data: D[];
  className?: string;
  totalDataLength?: number;
  tableClass?: string;
  theadClass?: string;
  trClass?: string;
  thClass?: string;
  tdClass?: string;
  tbodyClass?: string;
  divClass?: string;
  pagination?: PaginationState;
  hiddenColumns?: string[];
  canExport?: boolean;
  showColumnSelect?: boolean;
  hideTopPaginationStage?: boolean;
  hideBottomPaginationStage?: boolean;
  hovered?: boolean;
  height?: string;
  maxHeight?: string;
  renderSortingSelect?: () => JSX.Element;
  renderOnEmpty?: () => JSX.Element;
  onPaginationChanged?: (paginationState: PaginationState) => void;
  onSortingChanged?: (sortingStage?: SorterQuery) => void;
  onSelectionChanged?: (selectedRows: D[]) => void;
};

export type DataTableRef = {
  resetSelection: VoidFunction;
  setFilter: (columnId: string, filterValue: any) => void;
  clearFilters: VoidFunction;
};

const DataTable = <D,>(props: DataTableProps<D>, ref: ForwardedRef<DataTableRef>) => {
  const [pagination, setPagination] = useState<PaginationState>({pageIndex: props.pagination?.pageIndex || 0, pageSize: props.pagination?.pageSize || 10});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnResizeMode] = useState<ColumnResizeMode>("onChange");
  const [columnResizeDirection] = useState<ColumnResizeDirection>("ltr");
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const table = useReactTable({
    columns: props.columns,
    data: props.data,
    getSubRows: (row: any) => row.subRows,
    columnResizeMode,
    columnResizeDirection,
    initialState: {
      columnVisibility: _.fromPairs(props.hiddenColumns?.map((c) => [c, false]) || []),
      pagination,
    },
    state: {
      pagination,
      rowSelection,
      sorting,
    },
    pageCount: Math.max(1, Math.ceil((props.totalDataLength || props.data.length) / pagination.pageSize)),
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        var val = updater(pagination);
        setPagination(val);
        props.onPaginationChanged?.(val);
      } else {
        setPagination(updater);
        props.onPaginationChanged?.(updater);
      }
    },
    onSortingChange: (updater) => {
      if (typeof updater === "function") {
        var newSorting = updater(sorting);
        setSorting(newSorting);
        if (newSorting != null && newSorting.length > 0) {
          props.onSortingChanged?.({
            sortBy: newSorting[0].id,
            sortingOrder: newSorting[0].desc ? SortingDirection.DESCENDING : SortingDirection.ASCENDING,
          });
        } else {
          props.onSortingChanged?.({
            sortBy: "",
            sortingOrder: undefined,
          });
        }
      }
    },
    manualSorting: props.onSortingChanged ? true : false,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: (updater) => {
      if (typeof updater === "function") {
        var val = updater(rowSelection);
        setRowSelection(val);
        props.onSelectionChanged?.(props.data.filter((item, i) => val[i]));
      } else {
        setRowSelection(updater);
        props.onSelectionChanged?.(props.data.filter((item, i) => updater[i]));
      }
    },
    manualPagination: !!props.pagination,
    enableRowSelection: true,
  });

  const {t} = useTranslation();

  useImperativeHandle(
    ref,
    () => {
      return {
        resetSelection: () => {
          table.setRowSelection((prev) => ({}));
        },
        setFilter: (columnId: string, filterValue: any) => {
          table.getColumn(columnId)?.setFilterValue(filterValue);
        },
        clearFilters: () => {
          table.resetColumnFilters();
        },
      };
    },
    [table],
  );

  const renderSorting = (headerId: string) => {
    if (searchParams?.get("sortBy") === headerId && searchParams?.get("sortingOrder") === SortingDirection.DESCENDING.toString()) {
      return <i className="bx bx-sort-down"></i>;
    } else if (searchParams?.get("sortBy") === headerId && searchParams?.get("sortingOrder") === SortingDirection.ASCENDING.toString()) {
      return <i className="bx bx-sort-up"></i>;
    } else if (sorting != null && sorting.length > 0) {
      if (sorting[0].id === headerId && sorting[0].desc) {
        return <i className="bx bx-sort-down"></i>;
      } else if (sorting[0].id === headerId && !sorting[0].desc) {
        return <i className="bx bx-sort-up"></i>;
      } else {
        return null;
      }
    }
  };

  const tableRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <TablePagination
        busy={props.busy}
        table={table}
        totalDataLength={props.totalDataLength || props.data.length}
        rowsCount={props.data.length}
        pagination={pagination}
        showColumnSelect={props.showColumnSelect}
        canExport={props.canExport}
        showSizeSelect
        showPagination={props.pagination && props.onPaginationChanged && props.data.length > 0 ? true : false}
        hideTopPaginationStage={props.hideTopPaginationStage}
        hideBottomPaginationStage={props.hideBottomPaginationStage}
        renderSortingSelect={props.renderSortingSelect}
      >
        <div className="placeholder-glow">
          {props.busy && props.data.length === 0 ? (
            <div className="mb-4">
              <div className="placeholder w-100"></div>
              <Loader />
            </div>
          ) : (
            <div className={`${props.busy ? "placeholder w-100" : ""}`}>
              {props.renderOnEmpty && props.data.length === 0 ? (
                <>{props.renderOnEmpty()}</>
              ) : (
                <div className={classNames("table-responsive table-card", props.divClass)} ref={tableRef} style={{height: props.height, maxHeight: props.maxHeight}}>
                  <Table hover={props.hovered} className={classNames("align-middle", "my-0", props.tableClass)}>
                    <thead className={classNames("table-light text-muted", props.theadClass)} style={{zIndex: 2, backgroundColor: "var(--vz-card-bg-custom)"}}>
                      {table.getHeaderGroups().map(({id, headers}) => (
                        <tr className={classNames(props.trClass, "align-middle")} key={id}>
                          {headers.map((header) => (
                            <th
                              key={header.id}
                              className={classNames(props.thClass, "")}
                              colSpan={header.colSpan}
                              style={{
                                width: header.getSize(),
                                color: "var(--vz-heading-color)",
                              }}
                            >
                              <Row className="" onClick={header.column.getToggleSortingHandler()} role={header.column.getCanSort() ? "button" : ""}>
                                <Col className="user-select-none">{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</Col>
                                <Col xs="auto" className="ml-1 text-dark fw-bold">
                                  {renderSorting(header.column.id)}
                                </Col>
                              </Row>
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody className={classNames(props.tbodyClass)} style={{height: "15px"}}>
                      {props.data.length === 0
                        ? !props.renderOnEmpty && (
                            <tr>
                              <td colSpan={table.getVisibleLeafColumns().length} className="text-center text-warning-emphasis">
                                {t("No records available")}
                              </td>
                            </tr>
                          )
                        : table.getRowModel().rows.map((row) => {
                            const isRead = (row.original as any).isRead;
                            const leftItems = (row.original as UserShipmentItem).totalItems - (row.original as UserShipmentItem).soldItems;

                            return (
                              <React.Fragment key={row.id}>
                                <tr
                                  className={classNames(
                                    props.trClass,
                                    "align-middle",
                                    isRead === false && "table-light",
                                    leftItems === 0 && (row.original as UserShipmentItem).totalItems > 0 && "bg-success bg-opacity-10",
                                  )}
                                >
                                  {row.getVisibleCells().map((cell) => {
                                    return (
                                      <td key={cell.id} className={props.tdClass}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                      </td>
                                    );
                                  })}
                                </tr>
                              </React.Fragment>
                            );
                          })}
                    </tbody>
                    {props.columns.find((column) => column.footer) && (
                      <tfoot>
                        {table.getFooterGroups().map((footerGroup) => (
                          <tr className={props.trClass} key={footerGroup.id}>
                            {footerGroup.headers.map((header) => (
                              <td className={props.tdClass} key={header.id}>
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tfoot>
                    )}
                  </Table>
                </div>
              )}
            </div>
          )}
        </div>
      </TablePagination>
    </>
  );
};

// little type assertion for typed component
export default forwardRef(DataTable) as <D>(props: DataTableProps<D> & RefAttributes<DataTableRef>) => ReturnType<typeof DataTable>;
