import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form} from "reactstrap";
import {useFormik} from "formik";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {UserStore} from "models/user_stores";
import {setUserStoreName} from "slices/thunks";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface RenameUserStoreProps {
  isOpen: boolean;
  toggle: () => void;
  store: UserStore;
}
const RenameUserStore = (props: RenameUserStoreProps) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const storesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Stores.loading,
      currentUserStore: state.Stores.currentUserStore,
    }),
  );
  const {loading, currentUserStore} = useSelector(storesData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {storeName: currentUserStore.name},
    validationSchema: Yup.object().shape({
      storeName: Yup.string()
        .required(t("Stores.NameRequired"))
        .max(50, t("Stores.NameMaxLength")),
    }),
    onSubmit: (values: any) => {
      const updatePromise = setUserStoreName(currentUserStore.userStoreId, values.storeName)(dispatch);
      updatePromise.then((isSuccess) => {
        if (isSuccess) {
          props.toggle();
          validation.resetForm();
        }
      });
    },
  });

  useEffect(() => {
    validation.resetForm();

    validation.initialValues = {
      storeName: currentUserStore.name,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserStore.name]);

  const toggle = () => {
    props.toggle();
    validation.resetForm();
  };
  return (
    <Modal id="showSetUserStoreNameModal" className="modal-md" backdrop="static" isOpen={props.isOpen} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="bg-light p-3" toggle={props.toggle}>
        {t("Stores.SetUserStoreName")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col>
              <h5>{t("Stores.NameTitle")}</h5>
              <p>{t("Stores.NameInfo")}</p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <div className="mb-3">
                <ValidatedInput validation={validation} field={"storeName"} maxLength={100} disableValidationUI />
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" className="btn btn-light" disabled={loading.update} onClick={toggle}>
            {t("Close")}
          </Button>
          <Button
            type="submit"
            className="btn btn-success"
            disabled={loading.update}
            onClick={() => {
              if (validation.isValid) {
                validation.handleSubmit();
              }
            }}
          >
            {loading.update && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
            {t("Save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RenameUserStore;
