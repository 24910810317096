import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {CheckboxItem} from "./Components/CheckboxItem";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Alert, Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {SellerList, SellerType} from "models/product_details";
import {ColumnDef, FilterFn} from "@tanstack/react-table";
import {SellerHistoryChart, SellerHistorySeries} from "./Charts/SellerHistoryChart";
import {getToday} from "helpers/utilities";
import {getRatingPowerToolTip} from "./Helpers";
import DisplayPrice from "Components/Common/DisplayPrice";
import Moment from "react-moment";
import DisplayNumber from "Components/Common/DisplayNumber";
import i18n from "i18n";
import moment from "moment";
import NoData from "Components/Common/NoData";
import _ from "lodash";
import { AmazonMarketplace } from "helpers/marketplace_helper";

interface CompetitorAnalysisProps {
  destinationMarketplace?: AmazonMarketplace;
}
const CompetitorAnalysis = ({destinationMarketplace}: CompetitorAnalysisProps) => {
  const {t} = useTranslation();
  const [competitionLevelIconClass, setCompetitionLevelIconClass] = useState<string>("");
  const [competitionLevelBadgeCss, setCompetitionLevelBadgeCss] = useState("");
  const [competitionLevelDescription, setCompetitionLevelDescription] = useState("");
  const [competitionLevelDescriptionCss, setCompetitionLevelDescriptionCss] = useState<string>("");
  const [tableFilter, setTableFilter] = useState<"FBA" | "FBM" | "AMAZON" | "ALL">("ALL");
  const [fbaSellerCount, setFbaSellerCount] = useState<number>(0);
  const [fbmSellerCount, setFbmSellerCount] = useState<number>(0);
  const [amazonSellerCount, setAmazonSellerCount] = useState<number>(0);
  const [sellerHistoryChartFilterDay, setSellerHistoryChartFilterDay] = useState<"ALL" | "7DAY" | "30DAY" | "90DAY">("90DAY");
  const [sellerHistorySeries, setSellerHistorySeries] = useState<SellerHistorySeries[]>([]);
  const [sellerHistoryCategories, setSellerHistoryCategories] = useState<string[]>([]);

  const tableRef = useRef<DataTableRef>(null);
  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.ProductDetails.loading,
      details: state.ProductDetails.details,
    }),
  );
  const {loading, details} = useSelector(productDetailsData);

  const sellerTypeFilter: FilterFn<SellerList> = (row, _columnId, filterValue) => {
    if (filterValue === "FBA") return row.original.sellerType === SellerType.FBA;
    else if (filterValue === "FBM") return row.original.sellerType === SellerType.FBM;
    else if (filterValue === "AMAZON") return row.original.sellerType === SellerType.AMAZON;
    else return true;
  };

  const columns = useMemo<ColumnDef<SellerList, any>[]>(
    () => [
      {
        header: t("ProductDetails.CompetitorAnalysis.TableColumn.SellerId"),
        size: 150,
        accessorFn: (item) => item.ratingPercentage,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          return (
            <span className="hstack gap-2">
              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                <img src={destinationMarketplace?.flag /*sellerMarketplace?.flag*/} alt="Country Flag" className="rounded-circle" />
              </div>
              <div>
                <div className="hstack">
                  <Link to={`https://www.amazon.${"com"}/sp?seller=${row.sellerId}`} target="_blank" className="link-secondary link-offset-2 text-decoration-underline">
                    {row.sellerId}
                    <i className="ri-arrow-right-up-line"></i>
                  </Link>
                  {getRatingPowerToolTip(row)}
                </div>
                {row.isBuyBoxWinner && (
                  <span>
                    <i className="text-secondary mdi mdi-basket me-1 align-middle"></i>
                    <span className="text-secondary text-nowrap small fw-medium">Buybox</span>
                  </span>
                )}
              </div>
            </span>
          );
        },
      },
      {
        id: "sellerType",
        enableColumnFilter: true,
        header: t("ProductDetails.CompetitorAnalysis.TableColumn.SellerType"),
        size: 200,
        accessorFn: (item) => item.sellerType,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          switch (row.sellerType) {
            case SellerType.AMAZON:
              return <span className="badge rounded-pill bg-danger-subtle text-danger fs-12">Amazon</span>;
            case SellerType.OWNER:
              return <span className="badge rounded-pill bg-primary-subtle text-primary fs-12">Owner</span>;
            case SellerType.FBM:
              return <span className="badge rounded-pill bg-success-subtle text-success fs-12">FBM</span>;
            case SellerType.FBA:
              return <span className="badge rounded-pill bg-warning-subtle text-warning fs-12">FBA</span>;
            default:
              return <span className="badge rounded-pill bg-primary-subtle text-primary fs-12">--</span>;
          }
        },
        filterFn: sellerTypeFilter,
      },
      {
        header: t("ProductDetails.CompetitorAnalysis.TableColumn.Rating"),
        size: 100,
        accessorFn: (item) => item.ratingCount,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          return (
            <span className="d-flex align-items-center">
              <i className="ri-star-fill text-warning fs-16 me-1"></i> {row.ratingCount}
            </span>
          );
        },
      },
      {
        header: t("ProductDetails.CompetitorAnalysis.TableColumn.Price"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          const prices = details?.competitorAnalysis?.sellerList?.map((item) => item.price).filter((price) => price !== undefined);
          const minPrice = _.min(prices);
          const isLowest = row.price === minPrice;
          return (
            <>
              <DisplayPrice source={destinationMarketplace?.currency} value={row.price} decimals={2} notation="abbreviated" />
              {isLowest && (
                <span className="text-nowrap d-flex align-items-center">
                  <i className="text-warning mdi mdi-trending-down me-1"></i>
                  <span className="text-secondary text-nowrap small">{t("ProductDetails.CompetitorAnalysis.Label.Lowest")}</span>
                </span>
              )}
            </>
          );
        },
      },
      {
        header: t("ProductDetails.CompetitorAnalysis.TableColumn.IsRemoteFba"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          return (
            <span className="d-flex align-items-center">
              <CheckboxItem status={row.isRemoteFBA} />
            </span>
          );
        },
      },
      {
        header: t("ProductDetails.CompetitorAnalysis.TableColumn.IsAmazon"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          return (
            <span className="d-flex align-items-center">
              <CheckboxItem status={row.isAmazon} />
            </span>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, details, destinationMarketplace],
  );

  const handleCompetitionLevelStyle = useCallback(() => {
    if (details?.competitorAnalysis) {
      switch (details?.competitorAnalysis?.competitionLevel) {
        case "Strong":
          setCompetitionLevelIconClass("mdi mdi-speedometer text-danger");
          setCompetitionLevelBadgeCss("bg-danger");
          setCompetitionLevelDescriptionCss("alert-danger");
          setCompetitionLevelDescription(
            details?.competitorAnalysis?.isAmazonExist
              ? t("ProductDetails.CompetitorAnalysis.Label.Description.StrongBecauseOfAmazon")
              : t("ProductDetails.CompetitorAnalysis.Label.Description.Strong"),
          );
          break;
        case "Weak":
          setCompetitionLevelIconClass("mdi mdi-speedometer-slow text-success");
          setCompetitionLevelBadgeCss("bg-success");
          setCompetitionLevelDescriptionCss("alert-success");
          setCompetitionLevelDescription(t("ProductDetails.CompetitorAnalysis.Label.Description.Weak"));
          break;
        case "Medium":
          setCompetitionLevelIconClass("mdi mdi-speedometer-medium text-warning");
          setCompetitionLevelBadgeCss("bg-warning");
          setCompetitionLevelDescriptionCss("alert-warning");
          setCompetitionLevelDescription(t("ProductDetails.CompetitorAnalysis.Label.Description.Medium"));
          break;
        default:
          setCompetitionLevelIconClass("");
          setCompetitionLevelBadgeCss("");
          setCompetitionLevelDescriptionCss("");
          setCompetitionLevelDescription("");
          break;
      }
    }
  }, [details]); //eslint-disable-line

  const handleSellerListFilterChange = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.resetSelection();
      if (tableFilter === "ALL") {
        tableRef.current.clearFilters();
      } else {
        tableRef.current.setFilter("sellerType", tableFilter);
      }
    }
  }, [tableFilter]);

  const handleTableSellerCounts = useCallback(() => {
    if (details?.competitorAnalysis?.sellerList) {
      const amazon = details?.competitorAnalysis?.sellerList?.filter((seller) => seller.sellerType === SellerType.AMAZON);
      const fba = details?.competitorAnalysis?.sellerList?.filter((seller) => seller.sellerType === SellerType.FBA);
      const fbm = details?.competitorAnalysis?.sellerList?.filter((seller) => seller.sellerType === SellerType.FBM);
      setAmazonSellerCount(amazon.length);
      setFbaSellerCount(fba.length);
      setFbmSellerCount(fbm.length);
    }
  }, [details]);

  const handleSellerHistoryChartFilter = useCallback(() => {
    if (details.competitorAnalysis) {
      if (details.competitorAnalysis?.sellerHistory) {
        var sortedList = details.competitorAnalysis.sellerHistory && details.competitorAnalysis.sellerHistory.length > 0 ? [...details.competitorAnalysis.sellerHistory] : [];
        sortedList.sort((a, b) => {
          if (a.date && b.date) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
          }
          return 0;
        });

        if (sellerHistoryChartFilterDay === "ALL") {
        } else if (sellerHistoryChartFilterDay === "7DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(7, "days")));
        } else if (sellerHistoryChartFilterDay === "30DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(30, "days")));
        } else if (sellerHistoryChartFilterDay === "90DAY") {
          sortedList = sortedList.filter((item) => moment(item.date).isAfter(getToday().subtract(90, "days")));
        }

        let series: SellerHistorySeries[] = sortedList.map((item) => {
          let fba = (item.fbaSellerCount ?? 0) > 0 ? item.fbaSellerCount : null;
          let fbm = (item.fbmSellerCount ?? 0) > 0 ? item.fbmSellerCount : null;
          return {
            fbaSellerCount: fba,
            fbmSellerCount: fbm,
            isAmazonExist: item.isAmazonExist,
          } as SellerHistorySeries;
        });
        let categories: any[] = sortedList.map((item) => item.date);
        setSellerHistorySeries(series);
        setSellerHistoryCategories(categories);
      }
    }
  }, [details, sellerHistoryChartFilterDay]);
  useEffect(() => {
    handleSellerHistoryChartFilter();
  }, [handleSellerHistoryChartFilter]);

  useEffect(() => {
    handleTableSellerCounts();
  }, [handleTableSellerCounts]);

  useEffect(() => {
    handleSellerListFilterChange();
  }, [handleSellerListFilterChange]);

  useEffect(() => {
    handleCompetitionLevelStyle();
  }, [handleCompetitionLevelStyle]);

  return (
    <Card className="card-animate">
      <CardBody>
        <h5 className="fs-15">{t("ProductDetails.CompetitorAnalysis.Title")}</h5>

        <Row className="mt-3">
          <Col xl={4} lg={6} className="same-height">
            <div className="d-flex flex-column gap-0 h-100">
              <div className="p-2 border card rounded d-flex justify-content-center mb-3">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent h1">
                      <i className={`${competitionLevelIconClass}`}></i>
                    </div>
                  </div>
                  <div className="me-3">
                    <p className="text-muted mb-1 fs-18">{t("ProductDetails.CompetitorAnalysis.Label.Competition")}</p>
                    <h4 className="fs-22 fw-semibold ff-secondary">
                      <span className={`fs-14 badge rounded-pill ${competitionLevelBadgeCss}`}>{t(details?.competitorAnalysis?.competitionLevel ?? "")}</span>
                    </h4>
                  </div>
                </div>
              </div>
              <Alert className={`alert ${competitionLevelDescriptionCss} border-0 rounded-0 d-flex align-items-center mb-4`}>
                <div className="fw-semibold">{competitionLevelDescription}</div>
              </Alert>
            </div>
          </Col>
          <Col xl={4} lg={6} className="same-height">
            <Card>
              <CardBody>
                <div className="fs-15">
                  <div className="d-flex pb-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-store align-middle text-primary me-2"></i>
                        {t("ProductDetails.CompetitorAnalysis.Label.FbaSellerCount")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <DisplayNumber className="mb-0" value={details?.competitorAnalysis?.fbaSellerCount} renderOnEmpty={<>0</>} />
                    </div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-store align-middle text-primary me-2"></i>
                        {t("ProductDetails.CompetitorAnalysis.Label.RemoteFbaSellerCount")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <DisplayNumber className="mb-0" value={details?.competitorAnalysis?.remoteFbaSellerCount} renderOnEmpty={<>0</>} />
                    </div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-store align-middle text-primary me-2"></i>
                        {t("ProductDetails.CompetitorAnalysis.Label.FbmSellerCount")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <DisplayNumber className="mb-0" value={details?.competitorAnalysis?.fbmSellerCount} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                    </div>
                  </div>
                  <div className="d-flex pt-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-link-variant align-middle text-primary me-2"></i>
                        {t("ProductDetails.CompetitorAnalysis.Label.TotalSellerCount")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0 d-flex align-items-center">
                        <i className="ri-bar-chart-fill text-success me-1"></i>{" "}
                        <DisplayNumber
                          value={(details?.competitorAnalysis?.fbaSellerCount ?? 0) + (details?.competitorAnalysis?.remoteFbaSellerCount ?? 0) + (details?.competitorAnalysis?.fbmSellerCount ?? 0)}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={4} lg={6} className="same-height">
            <Card>
              <CardBody>
                <div className="fs-15">
                  <div className="d-flex pb-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-store align-middle text-primary me-2"></i>
                        {t("ProductDetails.CompetitorAnalysis.Label.AverageFbaSellerCount")} <i className="small text-muted">{t("ProductDetails.CompetitorAnalysis.Label.Last30Days")}</i>
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0">
                        <DisplayNumber value={details?.competitorAnalysis?.averageFBASellerCount} renderOnEmpty={<>0</>} />
                      </p>
                    </div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="ri-amazon-fill align-middle text-primary me-2"></i>
                        {t("ProductDetails.CompetitorAnalysis.Label.IsAmazonExist")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <span className="mb-0">
                        <span className={details?.competitorAnalysis?.isAmazonExist ? "text-danger" : "text-success"}>{details?.competitorAnalysis?.isAmazonExist ? t("Yes") : t("No")}</span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex pt-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="ri-amazon-fill align-middle text-primary me-2"></i>
                        {t("ProductDetails.CompetitorAnalysis.Label.AmazonLastSeen")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0">
                        {details?.competitorAnalysis?.isAmazonExist ? (
                          <Moment locale={i18n.language} fromNow>
                            {details?.competitorAnalysis?.amazonLastSeen}
                          </Moment>
                        ) : (
                          <span className="text-muted">{t("N/A")}</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={6} sm={12} className="same-height">
            <Card className="mb-0">
              <CardHeader className="align-items-center d-flex">
                <h5 className="fs-15 card-title mb-0 flex-grow-1">{t("ProductDetails.CompetitorAnalysis.Card.SellerHistoryChart.Title")}</h5>
                <div className="flex-shrink-0">
                  <div className="d-flex justify-content-end gap-1">
                    <div role="group" className="gap-2 btn-group">
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${sellerHistoryChartFilterDay === "ALL" &&
                          "text-decoration-underline "}`}
                        onClick={() => setSellerHistoryChartFilterDay("ALL")}
                      >
                        {t("ProductDetails.CompetitorAnalysis.Button.AllTime")}
                      </button>
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${sellerHistoryChartFilterDay === "7DAY" &&
                          "text-decoration-underline "}`}
                        onClick={() => setSellerHistoryChartFilterDay("7DAY")}
                      >
                        {t("ProductDetails.CompetitorAnalysis.Button.7Days")}
                      </button>
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${sellerHistoryChartFilterDay === "30DAY" &&
                          "text-decoration-underline "}`}
                        onClick={() => setSellerHistoryChartFilterDay("30DAY")}
                      >
                        {t("ProductDetails.CompetitorAnalysis.Button.30Days")}
                      </button>
                      <button
                        type="button"
                        className={`link-body-emphasis link-underline-opacity-75-hover align-items-center d-flex nowrap p-0 btn btn-link ${sellerHistoryChartFilterDay === "90DAY" &&
                          "text-decoration-underline "}`}
                        onClick={() => setSellerHistoryChartFilterDay("90DAY")}
                      >
                        {t("ProductDetails.CompetitorAnalysis.Button.90Days")}
                      </button>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className="card-body" style={{minHeight: "40vh"}}>
                {sellerHistorySeries.length > 0 ? (
                  <SellerHistoryChart dataColors='["--vz-warning", "--vz-success", "--vz-danger"]' categories={sellerHistoryCategories} series={sellerHistorySeries} />
                ) : (
                  <NoData icon="mdi mdi-note-search-outline" />
                )}
              </div>
            </Card>
          </Col>
          <Col xl={6} sm={12} className="same-height">
            <Card>
              <CardHeader className="bg-light py-2">
                <div className="d-flex align-items-center">
                  <h5 className="fs-15 flex-grow-1 mb-0">
                    {t("ProductDetails.CompetitorAnalysis.Table.Title")}
                    <span className="text-muted small">{details?.productInfo?.asin}</span>
                  </h5>
                  <div className="d-flex gap-1">
                    <Button color="secondary" size="sm" className={tableFilter === "FBA" ? "btn-soft-secondary" : "btn-ghost-secondary"} onClick={() => setTableFilter("FBA")}>
                      FBA <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbaSellerCount}</span>
                    </Button>
                    <Button color="secondary" size="sm" className={tableFilter === "FBM" ? "btn-soft-secondary" : "btn-ghost-secondary"} onClick={() => setTableFilter("FBM")}>
                      FBM <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbmSellerCount}</span>
                    </Button>
                    <Button color="secondary" size="sm" className={tableFilter === "AMAZON" ? "btn-soft-secondary" : "btn-ghost-secondary"} onClick={() => setTableFilter("AMAZON")}>
                      Amazon <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{amazonSellerCount}</span>
                    </Button>
                    <Button color="secondary" size="sm" className={tableFilter === "ALL" ? "btn-soft-secondary" : "btn-ghost-secondary"} onClick={() => setTableFilter("ALL")}>
                      {t("ProductDetails.CompetitorAnalysis.Button.All")}{" "}
                      <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbaSellerCount + fbmSellerCount + amazonSellerCount}</span>
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  ref={tableRef}
                  columns={columns}
                  data={details?.competitorAnalysis?.sellerList || []}
                  totalDataLength={details?.competitorAnalysis?.sellerList?.length || 0}
                  thClass="text-black"
                  busy={loading.list}
                  hovered
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CompetitorAnalysis;
