import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserInventory} from "models/user_inventory";
import { ShipmentSummary } from "slices/orders/reducer";

export type InventoryLoadingState = LoadingStates<"list" | "filter" | "archive" | "unarchive" | "update" | "download">;

export type InventoryState = {
  loading: InventoryLoadingState;
  userInventories: Partial<PagedList<UserInventory>>;
  shipments: ShipmentSummary[];
  selectedAll: boolean;
  excludedItems: UserInventory[];
  selectedItems: UserInventory[];
  error: ApiError;
};

const initialState: InventoryState = {
  loading: {
    list: false,
    filter: true,
    archive: false,
    unarchive: false,
    update: false,
    download:false
  },
  userInventories: {items: [] },
  shipments: [],
  selectedAll: false,
  excludedItems: [] as UserInventory[],
  selectedItems: [] as UserInventory[],
  error: {} as ApiError,
};

const InventorySlice = createSlice({
  name: "Inventory",
  initialState,
  reducers: {
    setUserInventories(state, action: PayloadAction<PagedList<UserInventory>>) {
      state.userInventories = action.payload;
    },
    setUserInventoryShipments(state, action: PayloadAction<ShipmentSummary[]>) {
      state.shipments = action.payload;
    },
    setSelectAllUserInventories(state, action: PayloadAction<boolean>) {
      state.selectedAll = action.payload;
      state.excludedItems = [];
      state.selectedItems = [];
    },
    updateItem(state, action: PayloadAction<UserInventory>) {
      const itemToUpdate = action.payload;
      state.userInventories.items = state.userInventories.items?.map((item) => {
        if (item.userInventoryId === itemToUpdate.userInventoryId) {
          return itemToUpdate;
        }
        else {
          return item;
        }
      })
    },
    selectItem(state, action: PayloadAction<UserInventory>) {
      const itemTodoSelect = action.payload;
      if(state.selectedAll) {
          state.excludedItems = state.excludedItems.filter(item => item.userInventoryId !== itemTodoSelect.userInventoryId);
      } else {
        const existInSelectedItems = state.selectedItems.find(item => item.userInventoryId === itemTodoSelect.userInventoryId);
        if(!existInSelectedItems) 
          state.selectedItems.push(itemTodoSelect);
      }
    },
    deselectItem(state, action: PayloadAction<UserInventory>) {
      const itemTodoDeselect = action.payload;
      if(state.selectedAll) {
        const existInExcludedItems = state.excludedItems.find(item => item.userInventoryId === itemTodoDeselect.userInventoryId);
        if(!existInExcludedItems) 
          state.excludedItems.push(itemTodoDeselect);
      } else {
        const existInSelectedItems = state.selectedItems.find(item => item.userInventoryId === itemTodoDeselect.userInventoryId);
        if(!existInSelectedItems) {
          state.excludedItems.push(itemTodoDeselect);
        }

        if(existInSelectedItems) {
          state.selectedItems = state.selectedItems.filter(item => item.userInventoryId !== itemTodoDeselect.userInventoryId);
        }
      }
    },
    removeFromList(state, action: PayloadAction<string[]>) {
      const ids= action.payload;
      state.userInventories.items = state.userInventories.items?.filter(item => !ids.includes(item.userInventoryId));
    },
    loading(state, action: PayloadAction<[keyof InventoryLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },

    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.userInventories = initialState.userInventories;
      state.selectedAll = initialState.selectedAll;
      state.excludedItems = initialState.excludedItems;
      state.selectedItems = initialState.selectedItems;
      state.error = initialState.error;
    },
  },
});

export const {setUserInventories, setUserInventoryShipments, setSelectAllUserInventories, updateItem, selectItem, deselectItem, removeFromList, loading, apiError, reset} = InventorySlice.actions;

export default InventorySlice.reducer;
