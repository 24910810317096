import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Input, Spinner, Label} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createTypedModal} from "helpers/modal_helpers";
import {UserSearchNote} from "models/user_search_note";
import {useProfile} from "Components/Hooks/useProfile";
import DisplayDate from "Components/Common/DisplayDate";
import {useState, useCallback} from "react";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {updateNote} from "slices/notes/thunk";

interface ModalData {
  asin: string;
  userSearchId: string;
  marketplaceTarget?: string;
  note?: UserSearchNote;
}
export const EditNoteModal = createTypedModal<ModalData>("edit_note");

const EditNote = () => {
  const {open, data} = EditNoteModal.useModal();
  if (!open) return null;
  return <EditNoteContent data={data} />;
};

const EditNoteContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const dispatch = useDispatch();

  const notesSelector = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Notes.loading,
    }),
  );

  const {loading} = useSelector(notesSelector);

  const [editedNote, setEditedNote] = useState(data?.note?.note || "");

  const toggle = () => {
    EditNoteModal.close();
  };

  const handleUpdate = useCallback(async () => {
    const updatePromise = updateNote({asin: data?.asin!, note: editedNote, userSearchId: data?.userSearchId!})(dispatch);
    updatePromise.then((success) => {
      if (success) {
        EditNoteModal.close();
      }
    });
  }, [editedNote, data, dispatch]);

  return (
    <>
      <Modal backdrop="static" isOpen={true} toggle={toggle} fade={true} centered={true} size="md">
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Notes.Dialog.Edit.Title")}
          <span className="ms-1 fs-13 text-muted fw-medium">
            -{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: t("SearchResults.Dialog.Notes.Description", {asin: data?.asin}),
              }}
            ></span>
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center flex-column gap-3">
            {data?.note && (
              <Row>
                <Col xs="auto" className="pe-0 d-flex align-items-center">
                  <i className="ri-calendar-2-line text-muted fs-16"></i>
                </Col>
                <Col className="d-flex align-items-center">
                  <Label className="mb-0 me-2">{t("Notes.Dialog.Edit.Label.LastUpdate")}:</Label>
                  <DisplayDate id="event-start-date-tag" tz={userProfile?.timezone} value={data.note.createDate} format="D MMM YYYY HH:mm" />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="auto" className="pe-0 d-flex align-items-start">
                <i className="mdi mdi-note-edit-outline text-muted fs-16"></i>
              </Col>
              <Col>
                <Label for="editedNote">{t("Notes.Dialog.Edit.Label.YourNote")}:</Label>
                <Input type="textarea" className="mb-2" name="editedNote" value={editedNote} maxLength={500} onChange={(e) => setEditedNote(e.target.value)} rows={4} autoFocus />
                <span className="text-muted">
                  {editedNote.length}
                  <i className="mdi mdi-slash-forward"></i>500
                </span>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button color="success" onClick={handleUpdate} disabled={!editedNote.trim() || loading.update}>
              {loading.update && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("Save")}
            </Button>
            <Button color="light" onClick={toggle} disabled={loading.update}>
              {t("Cancel")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditNote;
