import i18n from "i18n";
import {AmazonBusinessModel} from "models/enums/user_search_type";

interface BusinessModelBadgeProps {
  model?: AmazonBusinessModel;
  size?: "sm" | "md" | "lg";
}

export const BusinessModelBadge = ({model, size = "md"}: BusinessModelBadgeProps) => {
  const badgeClass =
    model === AmazonBusinessModel.CROSS_BORDER_ARBITRAGE
      ? "badge-gradient-success"
      : model === AmazonBusinessModel.DROPSHIPPING
      ? "badge-gradient-secondary"
      : model === AmazonBusinessModel.WHOLESALE
      ? "badge-gradient-warning"
      : "";

  const fontSize = size === "sm" ? "fs-11" : size === "lg" ? "fs-14" : "fs-12";

  let text = "";
  switch (model) {
    case AmazonBusinessModel.CROSS_BORDER_ARBITRAGE:
      text = i18n.t("Cross-border Arbitrage");
      break;
    case AmazonBusinessModel.DROPSHIPPING:
      text = i18n.t("Dropshipping");
      break;
    case AmazonBusinessModel.WHOLESALE:
      text = i18n.t("Wholesale");
      break;
    default:
      text = "";
  }

  return (
    <span className={`badge rounded-pill ${badgeClass} ${fontSize}`}>
      {text}
    </span>
  );
};
