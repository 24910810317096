import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Table, UncontrolledPopover} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {BestSellingProducts} from "models/dashboard";
import errorImage from "assets/images/svg/product.svg";
import SimpleBar from "simplebar-react";
import DisplayNumber from "Components/Common/DisplayNumber";
import NoData from "Components/Common/NoData";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import DisplayPrice from "Components/Common/DisplayPrice";
import {SellThisModal} from "Components/Common/SellThis";

const BestSellingProductsWidget = () => {
  const {t} = useTranslation();
  const [bestSellingProducts, setBestSellingProducts] = useState<BestSellingProducts[]>([]);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      filter: state.Dashboard.filter,
      statistics: state.Dashboard.statistics,
    }),
  );
  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics && statistics.bestSellingProducts) {
      setBestSellingProducts(statistics.bestSellingProducts);
    }
  }, [statistics]); //eslint-disable-line

  return (
    <React.Fragment>
      <Col xl={8} className="same-height">
        <Card className="card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.BestSellingProducts.Title")}</h4>
            <div className="flex-shrink-0">
              <Link className="link-body-emphasis text-decoration-underline link-underline-opacity-25 link-underline-opacity-75-hover mb-0" to={`/inventory`}>
                {t("Dashboard.BestSellingProducts.Link.ViewInventory")}
              </Link>
            </div>
          </div>
          <CardBody>
            {bestSellingProducts.length > 0 ? (
              <div className="table-responsive table-card">
                <SimpleBar style={{height: "558px"}}>
                  <table className="table-hover table table-centered align-middle mb-0 border-top-0">
                    <thead className="text-muted table-light sticky-top">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">{t("Dashboard.BestSellingProducts.TableColumn.SKU")}</th>
                        <th scope="col">{t("Dashboard.BestSellingProducts.TableColumn.Product")}</th>
                        <th scope="col">{t("Dashboard.BestSellingProducts.TableColumn.Stock")}</th>
                        <th scope="col">{t("Dashboard.BestSellingProducts.TableColumn.Orders")}</th>
                        <th scope="col">{t("Dashboard.BestSellingProducts.TableColumn.Revenue")}</th>
                        <th scope="col">{t("Dashboard.BestSellingProducts.TableColumn.Profit")}</th>
                        <th scope="col">{t("Dashboard.BestSellingProducts.TableColumn.RoiMargin")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bestSellingProducts.map((product: BestSellingProducts, index: number) => {
                        const imagePath = product && `https://m.media-amazon.com/images/I/${product.imageSmall}`;
                        const profitPerUnit = (product.profit ?? 0) / (product.unitCount ?? 1) || 0;
                        return (
                          <tr key={index}>
                            <td className="pe-1">
                              <div className="avatar-sm rounded flex-shrink-0 overflow-hidden">
                                <img
                                  className="rounded w-100"
                                  src={imagePath}
                                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = errorImage;
                                  }}
                                  alt=""
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center text-muted text-nowrap">
                                <DomainToFlag marketplace={product.marketplace} />
                                <span>{`${product.marketplace} - ${product.storeName}`}</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <CopyAsinWidget asin={product.asin} index={product.asin} asLink={true} marketplace={product.marketplace} hideCopyIcon={false} />
                              </div>
                              <div className="d-flex align-items-center nowrap">{product.sku}</div>
                            </td>
                            <td>
                              <p className="ellipsis-two-lines mb-0" title={product.productName}>
                                {product.productName}
                              </p>
                              <span className="text-muted ellipsis-single-line">{product.categoryName}</span>
                            </td>
                            <td>
                              {product.afnFulfillableQuantity === undefined || product.afnFulfillableQuantity <= 0 ? (
                                <>
                                  <h5 className="fs-14 my-1 fw-normal">
                                    <span className="badge rounded-pill bg-danger-subtle text-danger">{t("Dashboard.BestSellingProducts.Label.OutOfStock")}</span>
                                  </h5>
                                  <Button
                                    color="link"
                                    className="link-body-emphasis text-decoration-underline link-underline-opacity-75-hover align-items-center d-flex nowrap p-0"
                                    onClick={() => SellThisModal.open({asin: product.asin, marketplace: product.marketplace})}
                                  >
                                    <i className="mdi mdi-cube-send fs-18 me-1"></i>
                                    {t("Dashboard.BestSellingProducts.Button.Resend")}
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Link id={`BestSellingProducts-Stock-${index}`} className="link-body-emphasis link-opacity-75-hover fw-semibold d-flex align-items-center custom-width" to={""}>
                                    <span>{product.afnFulfillableQuantity || 0}</span>
                                    <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
                                  </Link>
                                  <UncontrolledPopover trigger="legacy" placement="right" target={`BestSellingProducts-Stock-${index}`}>
                                    <Table className="align-middle table table-hover mb-0">
                                      <thead className="table-light">
                                        <tr>
                                          <th>{t("Inventory.TableColumn.Stock.Tooltip.QuantityType")}</th>
                                          <th>{t("Inventory.TableColumn.Stock.Tooltip.Units")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{t("Inventory.TableColumn.Stock.Tooltip.Available")}</td>
                                          <td>{product.afnFulfillableQuantity || 0}</td>
                                        </tr>
                                        <tr>
                                          <td>{t("Inventory.TableColumn.Stock.Tooltip.Inbound")}</td>
                                          <td>{(product.afnInboundWorkingQuantity || 0) + (product.afnInboundShippedQuantity || 0) + (product.afnInboundReceivingQuantity || 0) || 0}</td>
                                        </tr>
                                        <tr>
                                          <td>{t("Inventory.TableColumn.Stock.Tooltip.Reserved")}</td>
                                          <td>{product.afnReservedQuantity || 0}</td>
                                        </tr>
                                        <tr>
                                          <td>{t("Inventory.TableColumn.Stock.Tooltip.Unfulfillable")}</td>
                                          <td>{product.afnUnsellableQuantity || 0}</td>
                                        </tr>
                                        <tr>
                                          <td>{t("Inventory.TableColumn.Stock.Tooltip.Researching")}</td>
                                          <td>{product.afnResearchingQuantity || 0}</td>
                                        </tr>
                                        <tr>
                                          <th scope="row">{t("Inventory.TableColumn.Stock.Tooltip.Total")}</th>
                                          <th scope="row">{product.afnTotalQuantity || 0}</th>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </UncontrolledPopover>
                                </>
                              )}
                            </td>
                            <td>
                              <h5 className="fs-14 my-1 align-items-center d-flex">
                                {product.orderCount} <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
                              </h5>
                              <span className="text-muted nowrap">{(product.unitCount || 0) <= 1 ? `${product.unitCount} ${t("Unit")}` : `${product.unitCount} ${t("Units")}`}</span>
                            </td>
                            <td>
                              <h5 className="fs-14 my-1 text-nowrap">
                                <DisplayPrice source={filter?.currencyCode} value={product.revenue} decimals={2} notation="abbreviated" />
                              </h5>
                            </td>
                            <td>
                              <span className="text-muted">
                                <DisplayPrice source={filter?.currencyCode} value={product.profit} decimals={2} notation="abbreviated" />
                              </span>
                              <h5 className="fs-14 my-1 text-nowrap">
                                <DisplayPrice source={filter?.currencyCode} value={profitPerUnit} decimals={2} suffix={` ${t("Per Unit")}`} notation="abbreviated" />
                              </h5>
                            </td>
                            <td>
                              <span className="text-muted">
                                  <DisplayNumber value={product.roi} suffix="%" decimals={0} />
                              </span>
                              <h5 className="fs-14 my-1">
                                  <DisplayNumber value={product.margin} suffix="%" decimals={0} />
                              </h5>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BestSellingProductsWidget;
