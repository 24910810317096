import {useTranslation} from "react-i18next";

type SellerTypeProps = {
  type: number | null;
  hideIfNA?: boolean | true;
};

export const SellerTypeBadge = ({type, hideIfNA}: SellerTypeProps) => {
  const {t} = useTranslation();

  const sellerTypes = [
    {name: "Local AMZ", color: "danger"},
    {name: "AMZ", color: "danger"},
    {name: "FBA", color: "warning"},
    {name: "FBM", color: "success"},
  ];

  const sellerType = (type !== null && sellerTypes[type]) || {name: "", color: ""};

  if (!sellerType.name) return hideIfNA ? null : t("NA");

  return (
    <>
      <span className={`badge rounded-pill bg-${sellerType.color}-subtle text-${sellerType.color} fs-11`}>
        <i className={`text-${sellerType.color} mdi mdi-basket me-1`}></i>
        {sellerType.name}
      </span>
    </>
  );
};
