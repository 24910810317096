import {Config} from "models/config";

const config: Config = {
  defaults: {
    LANGUAGE: "en",
    THEME: "light",
  },
  env: {
    isDevelopment: process.env.NODE_ENV === "development",
    isProduction: process.env.NODE_ENV === "production",
    isTest: process.env.NODE_ENV === "test",
  },
  api: {
    // IDENTITY_API_URL: 'http://localhost:11200',
    // PAYMENT_API_URL: "http://localhost:14200",
    // NOTIFICATION_API_URL: 'http://localhost:12200',
    // AMAZON_API_URL: "http://localhost:16000",
    // SEARCH_API_URL: "http://localhost:13200",
    // CRAWLER_API_URL: 'http://localhost:6100',
    // ADMIN_API_URL: 'http://localhost:17200',

    IDENTITY_API_URL: "https://identity.sellthis.com",
    PAYMENT_API_URL: "https://payment.sellthis.com",
    NOTIFICATION_API_URL: "https://notification.sellthis.com",
    AMAZON_API_URL: "https://amz.sellthis.com",
    SEARCH_API_URL: "https://search.sellthis.com",
    CRAWLER_API_URL: "https://crawlerapi.sellthis.com",
    ADMIN_API_URL: "https://admin.sellthis.com",
  },
};

export default config;
