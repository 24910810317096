import sizeMe, {SizeMeProps} from "react-sizeme";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, Card, CardBody, Col, Collapse, Row} from "reactstrap";
import {UserSearchProduct} from "models/user_search_product";
import {StackGridInstance} from "interfaces/stackGridTypes";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {markSearchProductReviewed} from "slices/search-result/thunk";
import {ReportErrorModal} from "../Modals/ReportError";
import ProfitAnalysis from "./_ProfitAnalysis";
import PriceAnalysis from "./_PriceAnalysis";
import CheckListItems from "./_CheckListItems";
import SaleAnalysis from "./_SaleAnalysis";
import ProductIsExist from "./_ProductIsExist";
import CompetitorAnalysis from "./_CompetitorAnalysis";
import VariationAnalysis from "./_VariationAnalysis";
import SellerAnalysis from "./_SellerAnalysis";
import RestrictionCheck from "./_RestrictionCheck";
import ProductSize from "./_ProductSize";
import Summary from "./_Summary";
import StackGrid from "react-stack-grid";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { ActiveMarketplaces, AmazonMarketplace } from "helpers/marketplace_helper";

type Props = SizeMeProps & {
  item: UserSearchProduct;
};

const SearchResultItem = ({item, size}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [autoMarkUsed, setAutoMarkUsed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const stackGridRef = useRef<StackGridInstance>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();
  const {t} = useTranslation();
  const {searchId} = useParams();

    const searchResultData = createSelector(
      (state: RootState) => state,
      (state) => ({
        detailedModeVisibility: state.SearchResult.detailedModeVisibility,
      }),
    );
    const {detailedModeVisibility} = useSelector(searchResultData);

  var width = size.width || 0;

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (stackGridRef.current) {
        stackGridRef.current.updateLayout();
      }
    }, 200); 

    return () => clearTimeout(timer);
  }, [isCollapsed]);

  useEffect(() => {
    setIsCollapsed(isCollapsed);
  }, [isCollapsed]);

  useMemo(() => {
    return setIsCollapsed(!detailedModeVisibility);
  }, [detailedModeVisibility]);

  const handleMarkReviewed = async () => {
    if (!autoMarkUsed && item.isReviewed !== true) {
      await markSearchProductReviewed({userSearchProductId: item.userSearchProductId, isReviewed: true})(dispatch);
      setAutoMarkUsed(true);
    }
  };

  const onLoad = useCallback(() => {
    let source = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === item.marketplaceSource);
    let destination = ActiveMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === item.marketplaceTarget);
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  // Listen for window resize events
  useEffect(() => {
    const handleResize = () => {
      if (stackGridRef.current) {
        stackGridRef.current.updateLayout();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div key={`SearchResultItem-${item.userSearchProductId}`} ref={containerRef} onClick={handleMarkReviewed} onTouchEnd={handleMarkReviewed}>
      <Card className="border">
        <Summary item={item} sourceMarketplace={sourceMarketplace} destinationMarketplace={destinationMarketplace}></Summary>
        <Button color="link" className="" onClick={handleCollapse}>
          <i className={!isCollapsed ? "bx bxs-chevrons-up fs-22" : "bx bxs-chevrons-down fs-22"}></i>
        </Button>

        <Collapse isOpen={!isCollapsed} onExited={() => stackGridRef.current?.updateLayout()}>
          <CardBody className="pb-0">
            <Row>
              <Col>
                <StackGrid
                  ref={stackGridRef}
                  component="div"
                  itemComponent="div"
                  className="StackGrid-component"
                  columnWidth={width <= 700 ? "100%" : width <= 1100 ? "50%" : width <= 1460 ? "33.33%" : "25%"}
                  gutterWidth={20}
                  duration={200}
                  easing="ease-out"
                  appearDelay={60}
                >
                  <Col>
                    <ProfitAnalysis item={item} sourceMarketplace={sourceMarketplace} destinationMarketplace={destinationMarketplace}></ProfitAnalysis>
                  </Col>
                  <Col>
                    <PriceAnalysis item={item}></PriceAnalysis>
                  </Col>
                  <Col>
                    <CheckListItems item={item}></CheckListItems>
                  </Col>
                  <Col>
                    <SaleAnalysis item={item}></SaleAnalysis>
                  </Col>
                  <Col>
                    <ProductIsExist item={item}></ProductIsExist>
                  </Col>
                  <Col>
                    <CompetitorAnalysis item={item}></CompetitorAnalysis>
                  </Col>
                  <Col>
                    <VariationAnalysis item={item}></VariationAnalysis>
                  </Col>
                  <Col>
                    <SellerAnalysis item={item}></SellerAnalysis>
                  </Col>
                  <Col>
                    <RestrictionCheck item={item}></RestrictionCheck>
                  </Col>
                  <Col>
                    <ProductSize item={item}></ProductSize>
                  </Col>
                </StackGrid>
              </Col>
            </Row>
            <Row>
              {searchId && (
                <Col className="fs-12 float-end d-flex justify-content-end">
                  <em className="mb-3 me-2 d-flex align-items-center">
                    <span className="mt-3 ">
                      <i className="ri-bug-fill align-middle fs-14 me-1"></i>
                    </span>
                    <Button
                      color="link"
                      onClick={async () => ReportErrorModal.open({asin: item.asin, marketplaceTarget: item.marketplaceTarget})}
                      className="p-0 mt-3 link-dark link-offset-2 text-decoration-underline text-muted"
                    >
                      {t("SearchResults.ReportError")}
                    </Button>
                  </em>
                </Col>
              )}
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default sizeMe()(SearchResultItem);