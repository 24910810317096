import React from "react";
import {Spinner} from "reactstrap";

import {toast} from "react-toastify";

const Loader = (props: any) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mx-2 my-2">
        <Spinner color="secondary">Loading...</Spinner>
      </div>
      {toast.error(props.error, {position: "top-right", hideProgressBar: true, progress: undefined, toastId: ""})}
    </React.Fragment>
  );
};

export default Loader;
