import {SearchResultQuery, SearchResults} from "models/search_result";
import {
  addFavItem,
  addOrUpdateNoteAction,
  apiError,
  loading,
  markReviewedItem,
  removeFavItem,
  removeNoteAction,
  reset,
  setCurrentFavoriteList,
  setDefinedFilters,
  setDetailedModeVisibility,
  setFavoriteList,
  setInUserShipment,
  setResult,
  setShowCheckList,
} from "./reducer";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {ResponseModel} from "models/response_model";
import {
  postAddToUserFavoriteList,
  postDeleteFilter,
  postDeleteUserFavoriteList,
  postExportSearchResults,
  postGetFilters,
  postGetSearchResults,
  postGetUserFavorites,
  postMarkSearchProductReviewed,
  postRemoveFromUserFavoriteList,
  postEditUserFavoriteList,
  postSaveFilter,
  postSaveUserFavorite,
  postSetUserShowCheckListOptionChoice,
  postSaveNote,
  postRemoveNote,
} from "services/search_service";
import {SendReportErrorRequest} from "models/user";
import {postSendReportError} from "services/identity_service";
import {UserSearchResultsFilter} from "models/user_search_result_filter";
import {SaveNoteCommand, ExportSearchResultsCommand, MarkSearchProductReviewedCommand, SaveOrUpdateSearchResultsFilterCommand, RemoveNoteCommand} from "api/command";
import {GetUserFavoriteListQuery} from "api/query";
import {EditUserFavoriteListCommand, SaveUserFavoriteListCommand, UserFavorite} from "models/user_favorites";
import {PagedList} from "helpers/types";
import {renderFavoriteToast} from "Components/Common/ToastChangeFavoriteList";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {exportExcelForList} from "Components/Common/ExcelExport";
import i18n from "i18n";
import { UserSearchNote } from "models/user_search_note";
import { updateSearchResultLocalData } from "helpers/local_storage";

export const getUserSearchResults = (query: SearchResultQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const response: ResponseModel = await postGetSearchResults(query);
    const result: SearchResults = response.data;
    dispatch(setResult(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const getUserSearchResultsFilters = () => async (dispatch: any) => {
  try {
    dispatch(loading(["definedFilters", true]));
    const response: ResponseModel = await postGetFilters();
    const result: UserSearchResultsFilter[] = response.data;
    dispatch(setDefinedFilters(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["definedFilters", false]));
  }
};

export const getUserFavoriteList = (data: GetUserFavoriteListQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["listFavorite", true]));
    const response: ResponseModel = await postGetUserFavorites(data);
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listFavorite", false]));
  }
};

export const setUserShowCheckListOptionChoice = (choice: boolean, amazonBusinessModel: AmazonBusinessModel) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postSetUserShowCheckListOptionChoice({showCheckList: choice, amazonBusinessModel});
    dispatch(setShowCheckList(choice));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const deleteFilter = (filterName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await postDeleteFilter({filterName});
    dispatch(getUserSearchResultsFilters());
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const saveFilter = (command: SaveOrUpdateSearchResultsFilterCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postSaveFilter(command);
    dispatch(getUserSearchResultsFilters());
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const changeDetailedModeVisibility = (data: boolean) => (dispatch: any) => {
  updateSearchResultLocalData({showDetails: data});
  dispatch(setDetailedModeVisibility(data));
};

export const changeCurrentFavoriteList = (userFavorite: UserFavorite) => (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    dispatch(setCurrentFavoriteList(userFavorite));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return true;
  } finally {
    dispatch(loading(["update", false]));
    return true;
  }
};

export const createUserFavoriteList = (data: SaveUserFavoriteListCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const response: ResponseModel = await postSaveUserFavorite(data);
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListCreateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const deleteUserFavoriteList = (userFavorite: UserFavorite) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const response: ResponseModel = await postDeleteUserFavoriteList({userFavoriteId: userFavorite.userFavoriteId});
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const addFavoriteItem = (userFavoriteId: string, asin: string, marketplace: string, showToast: boolean) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    await postAddToUserFavoriteList({asin, userFavoriteId, marketplace});
    if (showToast) renderFavoriteToast("add", asin);
    dispatch(addFavItem({userFavoriteId, asin}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const removeFavoriteItem = (userFavoriteId: string, asin: string, marketplace: string, showToast: boolean) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postRemoveFromUserFavoriteList({userFavoriteId, asin, marketplace});
    if (showToast) renderFavoriteToast("remove", asin);
    dispatch(removeFavItem({userFavoriteId, asin, marketplace}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
    return false;
  }
};

export const editFavoriteList = (command:EditUserFavoriteListCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["editFavoriteList", true]));
    const response: ResponseModel = await postEditUserFavoriteList(command);
    const list: Partial<PagedList<UserFavorite>> = response.data;
    dispatch(setFavoriteList(list));
    renderSuccessToast(i18n.t("SearchResults.Dialog.AddToFavorites.Toast.FavoriteListUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["editFavoriteList", false]));
  }
};

export const changeInUserShipmentStatusForSearchResult = (asin: string, inUserShipment: boolean) => async (dispatch: any) => {
  try {
    dispatch(setInUserShipment({asin, inUserShipment}));
    return true;
  } catch (error) {
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
    return false;
  }
};

export const sendReportError = (sendReportErrorRequest: SendReportErrorRequest) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    await postSendReportError(sendReportErrorRequest);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const markSearchProductReviewed = (command: MarkSearchProductReviewedCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["markReviewed", true]));
    await postMarkSearchProductReviewed(command);
    dispatch(markReviewedItem(command));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["markReviewed", false]));
  }
};

export const saveNote = (command: SaveNoteCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["note", true]));
    const response: ResponseModel = await postSaveNote(command);
    const createdNote: UserSearchNote = response.data;
    dispatch(addOrUpdateNoteAction(createdNote));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["note", false]));
  }
};

export const removeNote = (command: RemoveNoteCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["note", true]));
    const response: ResponseModel = await postRemoveNote(command);
    const createdNote: UserSearchNote = response.data;
    dispatch(removeNoteAction(createdNote));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["note", false]));
  }
};

export const exportSearchResults = (command: ExportSearchResultsCommand, fileName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["export", true]));
    const response: ResponseModel = await postExportSearchResults(command);
    exportExcelForList(response.data, fileName, true);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["export", false]));
  }
};
export const resetSearchResultState = () => async (dispatch: any) => {
  dispatch(reset());
};
