import {Input, InputGroup, InputGroupText} from "reactstrap";
import {FormikValidation, NumberRange} from "helpers/types";
import {useTranslation} from "react-i18next";
import {FormikErrors} from "formik";

export type ValidatedNumberRangeProps<T> = {
  validation: FormikValidation<T>;
  field: keyof T;
  className?: string;
  size?: "sm" | "lg";
  minPlaceholder?: string;
  maxPlaceholder?: string;
};

const ValidatedNumberRange = <T,>({field, validation, size, minPlaceholder, maxPlaceholder}: ValidatedNumberRangeProps<T>) => {
  const {t} = useTranslation();
  const fieldName = field.toString();
  const {value, error} = validation.getFieldMeta(fieldName);

  const errorObj = error as string | FormikErrors<NumberRange> | undefined;
  const errorMessage = errorObj ? (typeof errorObj === "string" ? errorObj : errorObj.min || errorObj.max) : null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value: inputValue} = e.target;
    const newValue = inputValue ? Number(inputValue) : undefined;
    
    validation.setFieldValue(fieldName, {
      ...value,
      [name]: newValue
    });
  };

  const handleBlur = () => {
    // Only set touched if both values are filled
    if (value?.min !== undefined && value?.max !== undefined) {
      validation.setFieldTouched(fieldName, true, false);
    }
  };

  return (
    <>
      <InputGroup>
        <Input
          type="number"
          name="min"
          className="border-end-0"
          placeholder={minPlaceholder || t("Min")}
          value={value?.min}
          min={0}
          onChange={handleChange}
          onBlur={handleBlur}
          bsSize={size}
          invalid={!!errorMessage}
        />
        <InputGroupText className="bg-white px-0 py-1 text-body-secondary">
          <i className="ri-arrow-right-line"></i>
        </InputGroupText>
        <Input
          type="number"
          name="max"
          className="border-start-0 text-end"
          placeholder={maxPlaceholder || t("Max")}
          value={value?.max}
          onChange={handleChange}
          onBlur={handleBlur}
          bsSize={size}
          invalid={!!errorMessage}
        />
      </InputGroup>
      {errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>}
    </>
  );
};

export default ValidatedNumberRange;
