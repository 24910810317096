import i18n from "i18n";
import {ScanAndSaveStatus} from "models/enums/scan_and_save_status";

interface ScanAndSaveBadgeProps {
  status?: ScanAndSaveStatus;
  size?: "sm" | "md" | "lg";
}

export const ScanAndSaveBadge = ({status, size = "sm"}: ScanAndSaveBadgeProps) => {
  let badgeClass = "";
  let text = "";

  switch (status) {
    case ScanAndSaveStatus.UNDEFINED:
      badgeClass = "bg-secondary-subtle text-secondary";
      text = i18n.t("Undefined");
      break;
    case ScanAndSaveStatus.WAITING:
      badgeClass = "bg-warning-subtle text-warning";
      text = i18n.t("Waiting");
      break;
    case ScanAndSaveStatus.PLANNED:
      badgeClass = "bg-info-subtle text-info";
      text = i18n.t("Planned");
      break;
    case ScanAndSaveStatus.WORKING:
      badgeClass = "bg-info-subtle text-info";
      text = i18n.t("Working");
      break;
    case ScanAndSaveStatus.DONE:
      badgeClass = "bg-success-subtle text-success";
      text = i18n.t("Done");
      break;
    case ScanAndSaveStatus.CANCELED:
      badgeClass = "bg-danger-subtle text-danger";
      text = i18n.t("Canceled");
      break;
  }

  const fontSize = size === "sm" ? "fs-12" : size === "lg" ? "fs-16" : "fs-14";

  return (
    <span className={`badge rounded-pill ${badgeClass} ${fontSize}`}>
      {text === "Working" && <i className="mdi mdi-spin mdi-loading me-1"></i>}
      {text}
    </span>
  );
};
