import {AdminGetUsersQuery} from "api/query";
import {useCallback, useEffect} from "react";
import {useFormik} from "formik";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {DateRange} from "helpers/types";
import {getAllSubscriptionData} from "slices/admin/users/thunk";
import {Subscription} from "models/subscription";
import {Tag} from "models/tag";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import ValidatedInput from "Components/Common/ValidatedInput";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import ValidatedDateRangeInput from "Components/Common/ValidatedDateRangeInput";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  fields: FieldConfig<AdminGetUsersQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {storeStatusSelectOptions, subscriptionStatusSelectOptions, userStatusSelectOptions} = useSelectOptions();

  const usersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
      subscriptions: state.AdminUsers.subscriptions,
      tags: state.Common.tags,
    }),
  );
  const {loading, subscriptions, tags} = useSelector(usersData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {} as AdminGetUsersQuery,
    validationSchema: Yup.object<AdminGetUsersQuery>({
      search: Yup.string(),
      registerDateRange: Yup.object<DateRange>(),
      lastLoginDateRange: Yup.object<DateRange>(),
      subscriptionIds: Yup.array<String>(),
      subscriptionStatus: Yup.string(),
      storeStatus: Yup.string(),
      userStatus: Yup.array<String>(),
      userTagIds: Yup.array<String>(),
    }),
    onSubmit: (values: AdminGetUsersQuery) => {
      const payload: AdminGetUsersQuery = {
        page: values.page,
        pageSize: values.pageSize,
        search: values.search,
        storeStatus: values.storeStatus,
        subscriptionStatus: values.subscriptionStatus,
        lastLoginDateRange: values.lastLoginDateRange,
        registerDateRange: values.registerDateRange,
        subscriptionIds: values.subscriptionIds,
        userStatus: values.userStatus,
        userTagIds: values.userTagIds,
        sortBy: values.sortBy,
        sortingOrder: values.sortingOrder,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<AdminGetUsersQuery>(props.fields, validation);

  const onLoad = useCallback(
    () => {
      getAllSubscriptionData()(dispatch);
      validation.handleSubmit();
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <Card>
          <CardBody>
            <div className="live-preview">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="align-items-center">
                  <Col>
                    <div className="flex-shrink-0">
                      <Label className="form-label text-muted">{t("Admin.Users.Filters.Title")}</Label>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 ">
                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.Search")}</Label>
                    <ValidatedInput validation={validation} field="search" maxLength={100} placeholder={t("Admin.Users.Filters.Label.SearchPlaceholder")} disableValidationUI />
                  </Col>

                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.RegisterDate")}</Label>
                    <ValidatedDateRangeInput validation={validation} field="registerDateRange" />
                  </Col>

                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.LastLoginDate")}</Label>
                    <ValidatedDateRangeInput validation={validation} field="lastLoginDateRange" />
                  </Col>

                  <Col xs={12} md={6} lg={3} className="mb-3">
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.Subscription")}</Label>
                    <ValidatedMultiSelect
                      options={subscriptions?.map((item: Subscription) => ({label: item.name, value: item.subscriptionId})) || []}
                      validation={validation}
                      field="subscriptionIds"
                      value={validation.values.subscriptionIds}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={3}>
                    <Label htmlFor="subscriptionStatus">{t("Admin.Users.Filters.Label.SubscriptionStatus")}</Label>
                    <ValidatedSelect options={subscriptionStatusSelectOptions} validation={validation} field="subscriptionStatus" value={validation.values.subscriptionStatus} />
                  </Col>

                  <Col xs={12} md={6} lg={3}>
                    <Label htmlFor="storeStatus">{t("Admin.Users.Filters.Label.StoreStatus")}</Label>
                    <ValidatedSelect options={storeStatusSelectOptions} validation={validation} field="storeStatus" value={validation.values.storeStatus} />
                  </Col>

                  <Col xs={12} md={6} lg={3}>
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.UserStatus")}</Label>
                    <ValidatedMultiSelect options={userStatusSelectOptions} validation={validation} field="userStatus" value={validation.values.userStatus} />
                  </Col>

                  <Col xs={12} md={6} lg={3}>
                    <Label htmlFor="search">{t("Admin.Users.Filters.Label.UserTags")}</Label>
                    <ValidatedMultiSelect
                      options={tags?.map((item: Tag) => ({label: item.name, value: item.tagId})) || []}
                      validation={validation}
                      field="userTagIds"
                      value={validation.values.userTagIds}
                      optionStyle="tagColor"
                    />
                  </Col>

                  <Col sm={2} className="align-content-end">
                    <Button id="submit" type="submit" color="secondary" className="me-0 mt-3" disabled={loading.filter} onClick={() => validation.setFieldValue("page", 1)}>
                      {loading.filter ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("Admin.Users.Filters.Button.Apply")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default Filters;
