import React, {useCallback, useEffect, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, Input, InputGroup, InputGroupText, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane} from "reactstrap";
import {Trans, useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAnalyzeOption, resetDefaultAnalyze, updateAnalyzeSettings} from "slices/settings/thunk";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {useFormik} from "formik";
import {IUpdateAnalyzeItemDto} from "models/user_setting_analyze_options";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/useProfile";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Select from "react-select";
import ErrorPopover from "Components/Common/ErrorPopover";
import CreateAnalyzeOption from "./Modals/CreateAnalyzeOption";
import classnames from "classnames";
import * as Yup from "yup";

const PAGE_IDENTIFIER: ConstantPage = "settings.analyzeOptions";
const AnalyzeOptionsEdit = () => {
  const CROSS_BORDER_ARBITRAGE_TAB = "crossBorderArbitrageTab";
  const WHOLESALE_TAB = "wholesaleTab";
  const DROPSHIPPING_TAB = "dropshippingTab";
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const {userSettingAnalyzeOptionId} = useParams();
  const [activeTab, setActiveTab] = useState(CROSS_BORDER_ARBITRAGE_TAB);
  const [settingsOptions, setSettingsOptions] = useState<SelectOptionsType[]>();
  const [selectedSettingOption, setSelectedSettingOption] = useState<SelectOptionsType>();
  const [validationHasErrors, setValidationHasErrors] = useState<boolean>(false);
  const [crossBorderArbitrageHasError, setCrossBorderArbitrageHasError] = useState(false);
  const [wholesaleHasError, setWholesaleHasError] = useState(false);
  const [dropshippingHasError, setDropshippingHasError] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const resetDialogRef = useRef<DialogRef>(null);
  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      currentAnalyzeOption: state.Settings.currentAnalyzeOption,
      analyzeList: state.Settings.analyzeList,
    }),
  );
  const {loading, currentAnalyzeOption, analyzeList} = useSelector(settingsPageData);

  useEffect(() => {
    if (userSettingAnalyzeOptionId && hasPermission(PAGE_IDENTIFIER)) {
      getAnalyzeOption(userSettingAnalyzeOptionId)(dispatch);
    }
  }, [dispatch, userSettingAnalyzeOptionId]); //eslint-disable-line

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentAnalyzeOption?.name,
      priceChangeRecheckMin: currentAnalyzeOption?.priceChangeRecheckMin,
      priceChangeRecheckMinWs: currentAnalyzeOption?.priceChangeRecheckMinWS,
      priceChangeRecheckMinDs: currentAnalyzeOption?.priceChangeRecheckMinDS,
      priceChangeRecheckMax: currentAnalyzeOption?.priceChangeRecheckMax,
      priceChangeRecheckMaxWs: currentAnalyzeOption?.priceChangeRecheckMaxWS,
      priceChangeRecheckMaxDs: currentAnalyzeOption?.priceChangeRecheckMaxDS,
      competitorAnalysisMediumMin: currentAnalyzeOption?.competitorAnalysisMediumMin,
      competitorAnalysisMediumMinWs: currentAnalyzeOption?.competitorAnalysisMediumMinWS,
      competitorAnalysisMinDs: currentAnalyzeOption?.competitorAnalysisMinDS,
      competitorAnalysisMediumMax: currentAnalyzeOption?.competitorAnalysisMediumMax,
      competitorAnalysisMediumMaxWs: currentAnalyzeOption?.competitorAnalysisMediumMaxWS,
      variationAnalysisRecheck: currentAnalyzeOption?.variationAnalysisRecheck,
      variationAnalysisRecheckWs: currentAnalyzeOption?.variationAnalysisRecheckWS,
      variationAnalysisRecheckDs: currentAnalyzeOption?.variationAnalysisRecheckDS,
      newProductWarningDay: currentAnalyzeOption?.newProductWarningDay,
      newProductWarningDayWs: currentAnalyzeOption?.newProductWarningDayWS,
      maximumDimensionalWeight: currentAnalyzeOption?.maximumDimensionalWeight,
      maximumDimensionalWeightWs: currentAnalyzeOption?.maximumDimensionalWeightWS,
      showCheckList: currentAnalyzeOption?.showCheckList,
      showCheckListWs: currentAnalyzeOption?.showCheckListWS,
      showCheckListDs: currentAnalyzeOption?.showCheckListDS,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Settings.AnalyzeOptions.Validation.Name")),
      priceChangeRecheckMin: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMin")),
      priceChangeRecheckMinWs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMin")),
      priceChangeRecheckMinDs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMin")),
      priceChangeRecheckMax: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMax")),
      priceChangeRecheckMaxWs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMax")),
      priceChangeRecheckMaxDs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.PriceChangeRecheckMax")),
      competitorAnalysisMediumMin: Yup.number().required(t("Settings.AnalyzeOptions.Validation.CompetitorAnalysisMediumMin")),
      competitorAnalysisMediumMinWs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.CompetitorAnalysisMediumMin")),
      competitorAnalysisMinDs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.CompetitorAnalysisMediumMin")),
      competitorAnalysisMediumMax: Yup.number().required(t("Settings.AnalyzeOptions.Validation.CompetitorAnalysisMediumMax")),
      competitorAnalysisMediumMaxWs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.CompetitorAnalysisMediumMax")),
      variationAnalysisRecheck: Yup.number().required(t("Settings.AnalyzeOptions.Validation.VariationAnalysisRecheck")),
      variationAnalysisRecheckWs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.VariationAnalysisRecheck")),
      variationAnalysisRecheckDs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.VariationAnalysisRecheck")),
      newProductWarningDay: Yup.number().required(t("Settings.AnalyzeOptions.Validation.NewProductWarningDay")),
      newProductWarningDayWs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.NewProductWarningDay")),
      maximumDimensionalWeight: Yup.number().required(t("Settings.AnalyzeOptions.Validation.MaximumDimensionalWeight")),
      maximumDimensionalWeightWs: Yup.number().required(t("Settings.AnalyzeOptions.Validation.MaximumDimensionalWeight")),
      showCheckList: Yup.boolean(),
      showCheckListWs: Yup.boolean(),
      showCheckListDs: Yup.boolean(),
    }),
    onSubmit: (values) => {
      const payload: IUpdateAnalyzeItemDto = {
        userSettingAnalyzeOptionId: userSettingAnalyzeOptionId as string,
        name: values.name,
        priceChangeRecheckMin: values.priceChangeRecheckMin,
        priceChangeRecheckMinWs: values.priceChangeRecheckMinWs,
        priceChangeRecheckMinDs: values.priceChangeRecheckMinDs,
        priceChangeRecheckMax: values.priceChangeRecheckMax,
        priceChangeRecheckMaxWs: values.priceChangeRecheckMaxWs,
        priceChangeRecheckMaxDs: values.priceChangeRecheckMaxDs,
        competitorAnalysisMediumMin: values.competitorAnalysisMediumMin,
        competitorAnalysisMediumMinWs: values.competitorAnalysisMediumMinWs,
        competitorAnalysisMinDs: values.competitorAnalysisMinDs,
        competitorAnalysisMediumMax: values.competitorAnalysisMediumMax,
        competitorAnalysisMediumMaxWs: values.competitorAnalysisMediumMaxWs,
        variationAnalysisRecheck: values.variationAnalysisRecheck,
        variationAnalysisRecheckWs: values.variationAnalysisRecheckWs,
        variationAnalysisRecheckDs: values.variationAnalysisRecheckDs,
        newProductWarningDay: values.newProductWarningDay,
        newProductWarningDayWs: values.newProductWarningDayWs,
        maximumDimensionalWeight: values.maximumDimensionalWeight,
        maximumDimensionalWeightWs: values.maximumDimensionalWeightWs,
        showCheckList: values.showCheckList,
        showCheckListWs: values.showCheckListWs,
        showCheckListDs: values.showCheckListDs,
      };

      if (validation.dirty) {
        const updatePromise = updateAnalyzeSettings(payload)(dispatch);
        updatePromise.then((success) => {
          if (success) {
            validation.resetForm();
          }
        });
      }
    },
  });

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onAnalyzeListChange = useCallback(() => {
    if (analyzeList.length > 0) {
      const optionArray: SelectOptionsType[] = [];
      analyzeList.forEach((item) => {
        optionArray.push({value: item.userSettingAnalyzeOptionId, label: item.name});
      });
      setSettingsOptions(optionArray);

      const selectedOption = optionArray.find((option) => option.value === userSettingAnalyzeOptionId);
      if (selectedOption) setSelectedSettingOption(selectedOption);
    }
  }, [analyzeList]); //eslint-disable-line

  const onValidationErrorsChange = useCallback(() => {
    if (validation && validation.errors) {
      if (Object.keys(validation.errors).length > 0) {
        setValidationHasErrors(true);
      } else {
        setValidationHasErrors(false);
      }

      const errors = validation.errors;
      if (
        errors.priceChangeRecheckMin ||
        errors.priceChangeRecheckMax ||
        errors.competitorAnalysisMediumMin ||
        errors.competitorAnalysisMediumMax ||
        errors.variationAnalysisRecheck ||
        errors.newProductWarningDay ||
        errors.maximumDimensionalWeight ||
        errors.showCheckList
      ) {
        setCrossBorderArbitrageHasError(true);
      } else {
        setCrossBorderArbitrageHasError(false);
      }

      if (
        errors.priceChangeRecheckMinWs ||
        errors.priceChangeRecheckMaxWs ||
        errors.competitorAnalysisMediumMinWs ||
        errors.competitorAnalysisMediumMaxWs ||
        errors.variationAnalysisRecheckWs ||
        errors.newProductWarningDayWs ||
        errors.maximumDimensionalWeightWs ||
        errors.showCheckListWs
      ) {
        setWholesaleHasError(true);
      } else {
        setWholesaleHasError(false);
      }

      if (errors.priceChangeRecheckMinDs || errors.priceChangeRecheckMaxDs || errors.competitorAnalysisMinDs || errors.variationAnalysisRecheckDs || errors.showCheckListDs) {
        setDropshippingHasError(true);
      } else {
        setDropshippingHasError(false);
      }
    }
  }, [validation.errors]); //eslint-disable-line

  useEffect(() => {
    onValidationErrorsChange();
  }, [onValidationErrorsChange]);

  useEffect(() => {
    onAnalyzeListChange();
  }, [onAnalyzeListChange]);

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "/account/settings/analyze-options",
    },
    {
      label: t("Settings.AnalyzeOptions.Title"),
      url: "/account/settings/analyze-options",
    },
    {
      label: currentAnalyzeOption.name,
      url: "",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={validation.dirty} navigateTo={redirectUrl}>
            <>
              <Card>
                <CardHeader className="fw-medium">
                  <CardTitle className="fw-medium">
                    <i className="mdi mdi-chart-timeline-variant-shimmer fs-18 align-middle me-1"></i>
                    {t("Settings.AnalyzeOptions.Title")} {">"} {currentAnalyzeOption.name}
                  </CardTitle>
                  <Row className="mt-3">
                    <Col md={4}>
                      <Select
                        id="selectSettings"
                        name="selectSettings"
                        options={settingsOptions}
                        onChange={(e: any) => {
                          const url = `/account/settings/analyze-options/edit/${e.value}`;
                          setRedirectUrl(url);
                        }}
                        value={selectedSettingOption}
                      />
                    </Col>
                    <Col></Col>
                    <Col xs="auto" className="mt-3 mt-md-0">
                      <Button type="button" className="btn-sm fw-semibold" onClick={() => setIsCreateModalOpen(true)}>
                        <i className="ri-add-line align-middle me-1"></i>
                        {t("Settings.AnalyzeOptions.Button.Create")}
                      </Button>
                      <CreateAnalyzeOption isOpen={isCreateModalOpen} toggle={() => setIsCreateModalOpen(!setIsCreateModalOpen)} />
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Nav className="nav-custom-gradient arrow-navtabs nav-pills" role="tablist">
                  <NavItem>
                    <NavLink
                      id="navLinkCrossBorderArbitrage"
                      role="button"
                      className={classnames({active: activeTab === CROSS_BORDER_ARBITRAGE_TAB})}
                      onClick={() => tabChange(CROSS_BORDER_ARBITRAGE_TAB)}
                    >
                      {crossBorderArbitrageHasError && <i className="ri text-danger ri-close-circle-fill me-1 align-middle"></i>}
                      {t("Cross-border Arbitrage")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink id="navLinkWholesale" role="button" className={classnames({active: activeTab === WHOLESALE_TAB})} onClick={() => tabChange(WHOLESALE_TAB)} type="button">
                      {wholesaleHasError && <i className="ri text-danger ri-close-circle-fill me-1 align-middle"></i>}
                      {t("Wholesale")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink id="navLinkdropshipping" role="button" className={classnames({active: activeTab === DROPSHIPPING_TAB})} onClick={() => tabChange(DROPSHIPPING_TAB)} type="button">
                      {dropshippingHasError && <i className="ri text-danger ri-close-circle-fill me-1 align-middle"></i>}
                      {t("Dropshipping")}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent className="mt-3" activeTab={activeTab}>
                  <TabPane tabId={CROSS_BORDER_ARBITRAGE_TAB}>
                    <Row className="d-flex">
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.PriceChange.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Cross-border Arbitrage")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Stable" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="priceChangeRecheckMin"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMin}
                                    invalid={validation.touched.priceChangeRecheckMin && validation.errors.priceChangeRecheckMin ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Recheck" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm" className="d-flex align-items-center">
                                  <Input
                                    id="priceChangeRecheckMin"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMin}
                                    invalid={validation.touched.priceChangeRecheckMin && validation.errors.priceChangeRecheckMin ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                  <span className="text-muted mx-2">{t("and")}</span>
                                  <Input
                                    id="priceChangeRecheckMax"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMax}
                                    invalid={validation.touched.priceChangeRecheckMax && validation.errors.priceChangeRecheckMax ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.NotStable" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="priceChangeRecheckMax"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMax}
                                    invalid={validation.touched.priceChangeRecheckMax && validation.errors.priceChangeRecheckMax ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.PriceChange.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Cross-border Arbitrage")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Weak" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="competitorAnalysisMediumMin"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMin}
                                    invalid={validation.touched.competitorAnalysisMediumMin && validation.errors.competitorAnalysisMediumMin ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Medium" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm" className="d-flex align-items-center">
                                  <Input
                                    id="competitorAnalysisMediumMin"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMin}
                                    invalid={validation.touched.competitorAnalysisMediumMin && validation.errors.competitorAnalysisMediumMin ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                  <span className="text-muted mx-2">{t("and")}</span>
                                  <Input
                                    id="competitorAnalysisMediumMax"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMax}
                                    invalid={validation.touched.competitorAnalysisMediumMax && validation.errors.competitorAnalysisMediumMax ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Strong" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="competitorAnalysisMediumMax"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMax}
                                    invalid={validation.touched.competitorAnalysisMediumMax && validation.errors.competitorAnalysisMediumMax ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Cross-border Arbitrage")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.VariationAnalysis.Recheck" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="variationAnalysisRecheck"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.variationAnalysisRecheck}
                                    invalid={validation.touched.variationAnalysisRecheck && validation.errors.variationAnalysisRecheck ? true : false}
                                  />
                                  <InputGroupText> {t("Settings.AnalyzeOptions.Label.Piece")}</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.NewProductWarning.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Cross-border Arbitrage")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>{t("Settings.AnalyzeOptions.Box.NewProductWarning.ListedProducts")}</span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="newProductWarningDay"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.newProductWarningDay}
                                    invalid={validation.touched.newProductWarningDay && validation.errors.newProductWarningDay ? true : false}
                                  />
                                  <InputGroupText>{t("Settings.AnalyzeOptions.Label.Days")}</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.NewProductWarning.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={12} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.Others.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Cross-border Arbitrage")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="d-flex">
                              <Col md={6}>
                                <Row>
                                  <Col>
                                    <span>{t("Settings.AnalyzeOptions.Box.Others.ShowCheckList")}</span>
                                  </Col>
                                  <Col xs="auto">
                                    <div className="form-check form-switch">
                                      {validation.values.showCheckList}
                                      <Input
                                        id="showCheckList"
                                        className="form-check-input form-switch-md "
                                        type="checkbox"
                                        role="switch"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.showCheckList as any}
                                        checked={validation.values.showCheckList}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={12} className="mt-3">
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 text-info me-2">
                                        <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                      </div>
                                      <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.Others.ShowCheckListDescription")}</div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="mt-3 mt-md-0">
                                <Row>
                                  <Col>
                                    <span>{t("Settings.AnalyzeOptions.Box.Others.MaximumDimensionalWeight")}</span>
                                  </Col>
                                  <Col xs="auto">
                                    <InputGroup size="sm">
                                      <Input
                                        id="maximumDimensionalWeight"
                                        type="number"
                                        step="any"
                                        className="width-50"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.maximumDimensionalWeight}
                                        invalid={validation.touched.maximumDimensionalWeight && validation.errors.maximumDimensionalWeight ? true : false}
                                      />
                                      <InputGroupText>lbs</InputGroupText>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12} className="mt-3">
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 text-info me-2">
                                        <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                      </div>
                                      <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.Others.MaximumDimensionalWeightDescription")}</div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={WHOLESALE_TAB}>
                    <Row className="d-flex">
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.PriceChange.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Wholesale")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Stable" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="priceChangeRecheckMinWs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMinWs}
                                    invalid={validation.touched.priceChangeRecheckMinWs && validation.errors.priceChangeRecheckMinWs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Recheck" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm" className="d-flex align-items-center">
                                  <Input
                                    id="priceChangeRecheckMinWs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMinWs}
                                    invalid={validation.touched.priceChangeRecheckMinWs && validation.errors.priceChangeRecheckMinWs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                  <span className="text-muted mx-2">{t("and")}</span>
                                  <Input
                                    id="priceChangeRecheckMaxWs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMaxWs}
                                    invalid={validation.touched.priceChangeRecheckMaxWs && validation.errors.priceChangeRecheckMaxWs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.NotStable" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="priceChangeRecheckMaxWs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMaxWs}
                                    invalid={validation.touched.priceChangeRecheckMaxWs && validation.errors.priceChangeRecheckMaxWs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.PriceChange.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Wholesale")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Weak" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="competitorAnalysisMediumMinWs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMinWs}
                                    invalid={validation.touched.competitorAnalysisMediumMinWs && validation.errors.competitorAnalysisMediumMinWs ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Medium" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm" className="d-flex align-items-center">
                                  <Input
                                    id="competitorAnalysisMediumMinWs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMinWs}
                                    invalid={validation.touched.competitorAnalysisMediumMinWs && validation.errors.competitorAnalysisMediumMinWs ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                  <span className="text-muted mx-2">{t("and")}</span>
                                  <Input
                                    id="competitorAnalysisMediumMaxWs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMaxWs}
                                    invalid={validation.touched.competitorAnalysisMediumMaxWs && validation.errors.competitorAnalysisMediumMaxWs ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Strong" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="competitorAnalysisMediumMaxWs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMediumMaxWs}
                                    invalid={validation.touched.competitorAnalysisMediumMaxWs && validation.errors.competitorAnalysisMediumMaxWs ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Wholesale")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.VariationAnalysis.Recheck" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="variationAnalysisRecheckWs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.variationAnalysisRecheckWs}
                                    invalid={validation.touched.variationAnalysisRecheckWs && validation.errors.variationAnalysisRecheckWs ? true : false}
                                  />
                                  <InputGroupText> {t("Settings.AnalyzeOptions.Label.Piece")}</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.NewProductWarning.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Wholesale")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>{t("Settings.AnalyzeOptions.Box.NewProductWarning.ListedProducts")}</span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="newProductWarningDayWs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.newProductWarningDayWs}
                                    invalid={validation.touched.newProductWarningDayWs && validation.errors.newProductWarningDayWs ? true : false}
                                  />
                                  <InputGroupText>{t("Settings.AnalyzeOptions.Label.Days")}</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.NewProductWarning.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={12} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.Others.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Wholesale")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="d-flex">
                              <Col md={6}>
                                <Row>
                                  <Col>
                                    <span>{t("Settings.AnalyzeOptions.Box.Others.ShowCheckList")}</span>
                                  </Col>
                                  <Col xs="auto">
                                    <div className="form-check form-switch">
                                      {validation.values.showCheckListWs}
                                      <Input
                                        id="showCheckListWs"
                                        className="form-check-input form-switch-md "
                                        type="checkbox"
                                        role="switch"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.showCheckListWs as any}
                                        checked={validation.values.showCheckListWs}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={12} className="mt-3">
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 text-info me-2">
                                        <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                      </div>
                                      <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.Others.ShowCheckListDescription")}</div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={6} className="mt-3 mt-md-0">
                                <Row>
                                  <Col>
                                    <span>{t("Settings.AnalyzeOptions.Box.Others.MaximumDimensionalWeight")}</span>
                                  </Col>
                                  <Col xs="auto">
                                    <InputGroup size="sm">
                                      <Input
                                        id="maximumDimensionalWeightWs"
                                        type="number"
                                        step="any"
                                        className="width-50"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.maximumDimensionalWeightWs}
                                        invalid={validation.touched.maximumDimensionalWeightWs && validation.errors.maximumDimensionalWeightWs ? true : false}
                                      />
                                      <InputGroupText>lbs</InputGroupText>
                                    </InputGroup>
                                  </Col>
                                  <Col md={12} className="mt-3">
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 text-info me-2">
                                        <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                      </div>
                                      <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.Others.MaximumDimensionalWeightDescription")}</div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={DROPSHIPPING_TAB}>
                    <Row className="d-flex">
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.PriceChange.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Dropshipping")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Stable" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="priceChangeRecheckMinDs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMinDs}
                                    invalid={validation.touched.priceChangeRecheckMinDs && validation.errors.priceChangeRecheckMinDs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.Recheck" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm" className="d-flex align-items-center">
                                  <Input
                                    id="priceChangeRecheckMinDs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMinDs}
                                    invalid={validation.touched.priceChangeRecheckMinDs && validation.errors.priceChangeRecheckMinDs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                  <span className="text-muted mx-2">{t("and")}</span>
                                  <Input
                                    id="priceChangeRecheckMaxDs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMaxDs}
                                    invalid={validation.touched.priceChangeRecheckMaxDs && validation.errors.priceChangeRecheckMaxDs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.PriceChange.NotStable" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="priceChangeRecheckMaxDs"
                                    type="number"
                                    step="any"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.priceChangeRecheckMaxDs}
                                    invalid={validation.touched.priceChangeRecheckMaxDs && validation.errors.priceChangeRecheckMaxDs ? true : false}
                                  />
                                  <InputGroupText>%</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.PriceChange.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Dropshipping")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.WeakDS" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="competitorAnalysisMinDs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMinDs}
                                    invalid={validation.touched.competitorAnalysisMinDs && validation.errors.competitorAnalysisMinDs ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.CompetitorAnalysis.Strong" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.competitorAnalysisMinDs}
                                    invalid={validation.touched.competitorAnalysisMinDs && validation.errors.competitorAnalysisMinDs ? true : false}
                                  />
                                  <InputGroupText>#</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.CompetitorAnalysis.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={6} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Dropshipping")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="mb-3 align-items-center">
                              <Col>
                                <span>
                                  <Trans i18nKey="Settings.AnalyzeOptions.Box.VariationAnalysis.Recheck" />
                                </span>
                              </Col>
                              <Col xs="auto">
                                <InputGroup size="sm">
                                  <Input
                                    id="variationAnalysisRecheckDs"
                                    type="number"
                                    className="width-50"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.variationAnalysisRecheckDs}
                                    invalid={validation.touched.variationAnalysisRecheckDs && validation.errors.variationAnalysisRecheckDs ? true : false}
                                  />
                                  <InputGroupText> {t("Settings.AnalyzeOptions.Label.Piece")}</InputGroupText>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                              <div className="d-flex">
                                <div className="flex-shrink-0 text-info me-2">
                                  <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                </div>
                                <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.VariationAnalysis.Description")}</div>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={12} className="same-height">
                        <Card>
                          <CardHeader className="align-items-center d-flex bg-light-subtle">
                            <h4 className="card-title mb-0 flex-grow-1">{t("Settings.AnalyzeOptions.Box.Others.Title")}</h4>
                            <span className="flex-shrink-0 text-muted fst-italic fs-12">{t("Dropshipping")}</span>
                          </CardHeader>
                          <CardBody>
                            <Row className="d-flex">
                              <Col md={6}>
                                <Row>
                                  <Col>
                                    <span>{t("Settings.AnalyzeOptions.Box.Others.ShowCheckList")}</span>
                                  </Col>
                                  <Col xs="auto">
                                    <div className="form-check form-switch">
                                      {validation.values.showCheckListDs}
                                      <Input
                                        id="showCheckListDs"
                                        className="form-check-input form-switch-md "
                                        type="checkbox"
                                        role="switch"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.showCheckListDs as any}
                                        checked={validation.values.showCheckListDs}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={12} className="mt-3">
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 text-info me-2">
                                        <i className="mdi mdi-progress-question fs-22 align-middle"></i>
                                      </div>
                                      <div className="flex-grow-1 text-muted">{t("Settings.AnalyzeOptions.Box.Others.ShowCheckListDescription")}</div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>

                <Row className="justify-content-end align-items-center">
                  <Col>
                    <i className="ri-arrow-go-back-line align-middle me-1 text-secondary"></i>
                    <Link
                      color="link"
                      className="link-secondary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={() => resetDialogRef.current?.show()}
                      to={""}
                    >
                      {t("Settings.CostAndFees.Button.RestoreDefaultSettings")}
                    </Link>
                  </Col>

                  {validationHasErrors && (
                    <Col xs="auto" className="pe-0">
                      <ErrorPopover key={currentAnalyzeOption?.userSettingAnalyzeOptionId} validationErrors={validation.errors} />
                    </Col>
                  )}

                  <Col xs="auto">
                    <Button type="submit" color="success" disabled={loading.update || Object.keys(validation.errors).length > 0}>
                      {loading.update && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                      {t("Settings.AnalyzeOptions.Button.SaveChanges")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          </SettingsLayout>
        </Container>
      </div>
      <Dialog
        ref={resetDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-arrow-go-back-line"
        message={t("Settings.AnalyzeOptions.Dialog.RestoreDefaultSettings.Description")}
        title={t("Settings.AnalyzeOptions.Dialog.RestoreDefaultSettings.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await resetDefaultAnalyze(currentAnalyzeOption?.userSettingAnalyzeOptionId)(dispatch);
          }
          resetDialogRef.current?.hide();
        }}
      />
    </React.Fragment>
  );
};

export default AnalyzeOptionsEdit;
