import {Badge, Button, Card, CardBody} from "reactstrap";
import {useTranslation} from "react-i18next";
import {UserSearchProduct} from "models/user_search_product";
import {SellerTypeBadge} from "./Components/SellerTypeBadge";
import {useCallback, useEffect, useState} from "react";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {FBASellersModal} from "../Modals/FBASellers";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayNumber from "Components/Common/DisplayNumber";

interface CompetitorAnalysisProps {
  item: UserSearchProduct;
}
const CompetitorAnalysis = ({item}: CompetitorAnalysisProps) => {
  const {t} = useTranslation();
  const [competitionLevel, setCompetitionLevel] = useState<string | undefined>();
  const onLoad = useCallback(
    () => {
      if (item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING) {
        setCompetitionLevel(item.competitorAnalysis?.competitionLevelDS ? item.competitorAnalysis.competitionLevelDS : undefined);
      } else {
        setCompetitionLevel(item.competitorAnalysis?.competitionLevel ? item.competitorAnalysis.competitionLevel : undefined);
      }
    },
    [item], //eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const competitionLevelColor = competitionLevel != null ? (competitionLevel === "Weak" ? "success" : competitionLevel === "Medium" ? "warning" : "danger") : "";
  return (
    <>
      <Card className="border card-animate mb-3">
        <CardBody>
          <h5>{t("SearchResults.Analyzes.CompetitorAnalysis.Title")}</h5>
          <ul className="list-group d-flex flex-group gap-1">
            <li className="d-flex justify-content-between">
              <span>{t("SearchResults.Analyzes.CompetitorAnalysis.Competition")}</span>
              {competitionLevel != null ? (
                <Badge color={competitionLevelColor} className="rounded-pill h-25 fs-11">
                  {t(`${competitionLevel}`)}
                  {competitionLevel === "Weak" && <i className="mdi mdi-check ms-1"></i>}
                </Badge>
              ) : (
                <span>{t("NA")}</span>
              )}
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex align-items-center">{t("SearchResults.Analyzes.CompetitorAnalysis.CurrentSellerType")}</div>
              <span>
                <SellerTypeBadge type={item.competitorAnalysis?.buyBoxType ?? null} hideIfNA={false} />
              </span>
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                {item.competitorAnalysis?.fbaSellerCount != null && item.competitorAnalysis?.fbaSellerCount > 0 ? (
                  <>
                    <Button
                      color="link"
                      className="p-0 link-secondary link-offset-2 text-decoration-underline"
                      onClick={() =>
                        FBASellersModal.open({
                          asin: item.asin,
                          currencyCodeTarget: item.currencyCodeTarget,
                          domainTarget: item.domainTarget,
                          fbaSellers: item.fbaSellers,
                          marketplaceTarget: item.marketplaceTarget,
                          competitorAnalysis: item.competitorAnalysis,
                        })
                      }
                    >
                      <span>{t("SearchResults.Analyzes.CompetitorAnalysis.FBASeller")}</span>
                    </Button>
                    <i id={`PopoverFBASellers-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
                    <DefaultUncontrolledTooltip target={`PopoverFBASellers-${item.userSearchProductId}`}>{t("SearchResults.Analyzes.CompetitorAnalysis.ClickForDetails")}</DefaultUncontrolledTooltip>
                  </>
                ) : (
                  <span>{t("SearchResults.Analyzes.CompetitorAnalysis.FBASeller")}</span>
                )}
              </div>
              <DisplayNumber value={item.competitorAnalysis?.fbaSellerCount} renderOnEmpty={<>{t("NA")}</>} />
            </li>
            <li className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span>{t("Remote FBA Seller")}</span> <i id={`PopoverFBASellerCountRemote-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
                <DefaultUncontrolledTooltip target={`PopoverFBASellerCountRemote-${item.userSearchProductId}`}>{t("Definitions.RemoteFBA")}</DefaultUncontrolledTooltip>
              </div>
              <DisplayNumber value={item.competitorAnalysis?.fbaSellerCountRemote} renderOnEmpty={<>{t("NA")}</>} />
            </li>
            <li className="d-flex justify-content-between">
              <span>{t("SearchResults.Analyzes.CompetitorAnalysis.FBMSeller")}</span>
              <span>{item.competitorAnalysis?.fbmSellerCount ?? t("NA")}</span>
            </li>
            <li className="d-flex justify-content-between">
              <span>{t("SearchResults.Analyzes.CompetitorAnalysis.IsAmazonExist")}</span>
              {item.competitorAnalysis?.isAmazonSell != null ? (
                <span className={item.competitorAnalysis.isAmazonSell ? "text-danger" : "text-success"}>{item.competitorAnalysis.isAmazonSell ? t("Yes") : t("No")}</span>
              ) : (
                <span>{t("NA")}</span>
              )}
            </li>
            <li className="d-flex justify-content-between">
              <div>
                <span>{t("SearchResults.Analyzes.CompetitorAnalysis.AmazonLastSeen")}</span>
              </div>
              <span>
                {item.competitorAnalysis?.lastAmazonSeenText != null && item.competitorAnalysis.lastAmazonSeenText !== ""
                  ? item.competitorAnalysis.lastAmazonSeenText
                      .replace("years", t("years"))
                      .replace("year", t("year"))
                      .replace("months", t("months"))
                      .replace("month", t("month"))
                      .replace("days", t("days"))
                      .replace("day", t("day"))
                      .replace("and", t("and"))
                      .replace("ago", t("ago"))
                  : t("NA")}
              </span>
            </li>
          </ul>
        </CardBody>
      </Card>
    </>
  );
};
export default CompetitorAnalysis;
