import {useTranslation} from "react-i18next";

interface DecisionReasonsLinkProps {
  items: string[];
  isWarning?: boolean | false;
}
export const DecisionReasonsLink = ({items, isWarning}: DecisionReasonsLinkProps) => {
  const {t} = useTranslation();
  return items.length === 1
    ? isWarning
      ? t("SearchResults.Summary.Warning", {count: items.length})
      : t("SearchResults.Summary.Reason", {count: items.length})
    : isWarning
    ? t("SearchResults.Summary.WarningPlural", {count: items.length})
    : t("SearchResults.Summary.ReasonPlural", {count: items.length});
};
