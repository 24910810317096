import {Page, Text, View, Document, StyleSheet, Image} from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import _ from "lodash";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
    padding: "40 10 40 30",
  },
  barcodeContainer: {
    width: "30%",
    marginBottom: 20,
    textAlign: "left",
  },
  barcodeLabel: {
    fontSize: 8,
  },
  newLabel: {
    fontSize: 10,
  },
});

const generateBarcode = (value: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, value, {
    format: "CODE128",
    displayValue: true,
    height: 30,
    flat: true,
    font: "Tahoma",
    fontSize: 16,
  });

  return canvas.toDataURL("image/png");
};

export type BarcodeItem = {
  barcode: string;
  productName: string;
};
interface FNSKUDocumentProps {
  products: BarcodeItem[];
}
const FNSKUDocument = ({products}: FNSKUDocumentProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {products.map((item, index) => (
        <View key={index} style={styles.barcodeContainer}>
          <Image src={generateBarcode(item.barcode)} />
          <Text style={styles.barcodeLabel}>{_.truncate(item.productName, {length: 42, omission: ""})}</Text>
          <Text style={styles.newLabel}>New</Text>
        </View>
      ))}
    </Page>
  </Document>
);

export default FNSKUDocument;
