import {generateError, renderSuccessToast} from "helpers/utilities";
import {
  FilterShipmentsQuery,
  IAddUserShipmentItemDto,
  IDeleteUserShipmentDto,
  IDeleteUserShipmentItemDto,
  IEditUserShipmentDto,
  IGetUserShipmentDetailsDto,
  IRefreshUserShipmentItemDto,
  IAddToAmazonInventoryDto,
  ISaveNewUserShipmentDto,
  ISetUserShipmentDetailsDto,
  ShipmentOrder,
  UserShipment,
} from "models/user_shipment";
import {
  postAddUserShipmentItem,
  postDeleteUserShipment,
  postDeleteUserShipmentItem,
  postEditUserShipment,
  postGetUserShipmentDetails,
  postGetUserShipments,
  postRefreshUserShipmentItem,
  postAddToAmazonInventory,
  postSaveNewUserShipment,
  postSetUserShipmentDetails,
} from "services/shipment_service";
import {ShipmentLoadingState, apiError, loading, reset, setCurrentUserShipment, setCurrentUserShipmentOrder, setUserShipmentList} from "./reducer";
import {ResponseModel} from "models/response_model";
import {PagedList} from "helpers/types";
import i18n from "i18n";
import {AnalyzeShipmentCommand} from "api/command";
import {postAnalyzeShipment} from "services/search_service";
import {refreshRemainingLimits} from "slices/thunks";
import {renderShowAnalyzeToast} from "Components/Common/ToastShowAnalyzeResult";
import {UserSearch} from "models/user_search";

export const getUserShipments = (query: FilterShipmentsQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserShipments(query);
    const pagedResult: PagedList<UserShipment> = result.data;
    dispatch(setUserShipmentList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const getUserShipmentDetails = (getUserShipmentDetailsRequest: IGetUserShipmentDetailsDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetUserShipmentDetails(getUserShipmentDetailsRequest);
    const shipment: UserShipment = result.data.userShipment;
    const shipmentOrder: ShipmentOrder[] = result.data.shipmentOrders;
    dispatch(setCurrentUserShipment(shipment));
    dispatch(setCurrentUserShipmentOrder(shipmentOrder));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const creteNewShipment = (data: ISaveNewUserShipmentDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    await postSaveNewUserShipment(data);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};
export const editUserShipment = (editUserShipmentRequest: IEditUserShipmentDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postEditUserShipment(editUserShipmentRequest);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const deleteUserShipment = (deleteUserShipmentRequest: IDeleteUserShipmentDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteUserShipment(deleteUserShipmentRequest);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const deleteUserShipmentItem = (productInfos: IDeleteUserShipmentItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await postDeleteUserShipmentItem(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const addUserShipmentItem = (productInfos: IAddUserShipmentItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postAddUserShipmentItem(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const setUserShipmentDetails = (newShipmentDetails: ISetUserShipmentDetailsDto, loadingType: keyof ShipmentLoadingState) => async (dispatch: any) => {
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postSetUserShipmentDetails(newShipmentDetails);
    const updatedResult: UserShipment = result.data;
    renderSuccessToast(i18n.t(result.message));
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const changeUserShipmentStatus = (newShipmentDetails: ISetUserShipmentDetailsDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["changeStatus", true]));
    const result: ResponseModel = await postSetUserShipmentDetails(newShipmentDetails);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["changeStatus", false]));
  }
};

export const refreshUserShipmentItem = (productInfos: IRefreshUserShipmentItemDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postRefreshUserShipmentItem(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const addToAmazonInventory = (productInfos: IAddToAmazonInventoryDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postAddToAmazonInventory(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const analyzeUserShipment = (command: AnalyzeShipmentCommand, navigate: any) => async (dispatch: any) => {
  try {
    dispatch(loading(["analyzeShipment", true]));
    const result: ResponseModel = await postAnalyzeShipment(command);
    const search: UserSearch = result.data;
    dispatch(refreshRemainingLimits());
    renderShowAnalyzeToast(i18n.t("Shipments.ShipmentDetails.Toast.AnalyzeSuccess"), `/searches/search-results/${search.userSearchId}`, navigate);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["analyzeShipment", false]));
  }
};

export const setLoading = (loadingType: keyof ShipmentLoadingState, isLoading: boolean) => async (dispatch: any) => {
  dispatch(loading([loadingType, isLoading]));
}
export const resetShipmentState = () => async (dispatch: any) => {
  dispatch(reset());
};
