import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserSearchNote} from "models/user_search_note";

type NotesLoadingState = LoadingStates<"filter"| "list" | "analyze" | "delete" | "rename" | "update">;

export type NotesState = {
  loading: NotesLoadingState;
  list: Partial<PagedList<UserSearchNote>>;
  error: ApiError;
};

const initialState: NotesState = {
  loading: {
    filter: true,
    list: false,
    analyze: false,
    delete: false,
    rename: false,
    update: false,
  },
  list: {items: []},
  error: {} as ApiError,
};

const NotesSlice = createSlice({
  name: "Notes",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<PagedList<UserSearchNote>>) {
      state.list = action.payload;
    },
    loading(state, action: PayloadAction<[keyof NotesLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    updateNoteAction(state, action: PayloadAction<UserSearchNote>) {
      state.list.items = (state.list.items || []).map((item) => {
        if (item.userSearchNoteId === action.payload.userSearchNoteId) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {setList, loading, updateNoteAction, apiError} = NotesSlice.actions;

export default NotesSlice.reducer;
