import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {useProfile} from "Components/Hooks/useProfile";
import {FulfillmentChannel} from "models/user_order";
import {RootState} from "slices";
import DomainToFlag from "Components/Common/DomainToFlag";
import Loader from "Components/Common/Loader";
import i18n from "i18n";
import Moment from "react-moment";
import DisplayDate from "Components/Common/DisplayDate";
import { ActiveMarketplaces, AmazonMarketplace } from "helpers/marketplace_helper";

const Summary = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [marketplace, setMarketplace] = useState<AmazonMarketplace>();
  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      order: state.OrderDetails.order,
      loading: state.OrderDetails.loading,
    }),
  );
  const {loading, order} = useSelector(ordersData);

  const onLoad = useCallback(() => {
    if (order.userStore) {
      const mp = ActiveMarketplaces.find((m) => m.marketplace === order.userStore.marketplace);
      setMarketplace(mp);
    }
  }, [order]); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="fw-medium mb-0">{t("OrderDetails.Card.OrderSummary.Title")}</CardTitle>
        </CardHeader>

        <CardBody>
          {loading.list ? (
            <Loader />
          ) : (
            <Row className="d-flex gx-5">
              <Col lg={6}>
                <ul className="list-group gap-2">
                  <li className="d-flex justify-content-between mb-1">
                    <span className="fw-medium">{t("OrderDetails.Card.OrderSummary.AmazonsShipBy")}</span>
                    <span>
                      <Moment locale={i18n.language} format="D MMM YYYY HH:mm">
                        {order.latestShipDate}
                      </Moment>
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span className="fw-medium">{t("OrderDetails.Card.OrderSummary.PurchaseDate")}</span>
                    <DisplayDate id={`DisplayDatePurchaseDate-${order.userOrderId}`} value={order.purchaseDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                  </li>
                </ul>
              </Col>
              <Col lg={6}>
                <ul className="list-group gap-2">
                  <li className="d-flex justify-content-between mb-1">
                    <span className="fw-medium">{t("OrderDetails.Card.OrderSummary.ShippingService")}</span>
                    <span className={`badge rounded-pill fs-12 ${order.shipmentServiceLevelCategory === "Expedited" ? "bg-warning-subtle text-warning" : "bg-dark-subtle text-dark"}`}>
                      {order.shipmentServiceLevelCategory}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span className="fw-medium">{t("OrderDetails.Card.OrderSummary.Fulfillment")}</span>
                    <span>{FulfillmentChannel[order.fulfillmentChannel || 2]}</span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span className="fw-medium">{t("OrderDetails.Card.OrderSummary.SalesChannel")}</span>
                    <span>
                      {marketplace && (
                        <>
                          <span className="me-1">Amazon.{marketplace.domain}</span>
                          <DomainToFlag marketplace={marketplace.marketplace} />
                        </>
                      )}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Summary;
