import {useMemo, useCallback} from "react";
import {formatCurrency} from "helpers/utilities";
import CountUp from "react-countup";
import _ from "lodash";
import { AllMarketplaces } from "helpers/marketplace_helper";

interface DisplayPriceProps {
  source?: string;
  value?: number;
  decimals: number;
  countUp?: boolean;
  prefix?: string;
  suffix?: string;
  className?: string;
  renderOnEmpty?: JSX.Element;
  notation: "decimal" | "abbreviated";
}

const DisplayPrice = (props: DisplayPriceProps) => {

  // Rounding down to a certain number of digits after the decimal point
  const toFixedFloor = (value: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.floor(value * factor) / factor;
  };

  const getCurrencySymbol = useMemo((): string => {
    return AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === props.source)?.priceSign || "";
  }, [props.source]);

  const formattingFn = useCallback(
    (value: number) => {
      return formatCurrency(getCurrencySymbol, props.decimals, props.notation, value, props.source);
    },
    [getCurrencySymbol, props.decimals, props.source, props.notation],
  );

  return (
    <span className={`text-nowrap ${props.className || ""}`}>
      {props.source &&
        (props.countUp ? (
          !_.isNil(props.value) ? (
            <CountUp
              start={0}
              prefix={`${props.prefix || ""}${getCurrencySymbol}`}
              suffix={` ${props.source}${props.suffix || ""}`}
              decimals={props.decimals}
              duration={2}
              end={toFixedFloor(props.value, props.decimals)}
              formattingFn={formattingFn}
            />
          ) : (
            props.renderOnEmpty
          )
        ) : !_.isNil(props.value) ? (
          `${props.prefix || ""}${formatCurrency(getCurrencySymbol, props.decimals, props.notation, toFixedFloor(props.value, props.decimals), props.source)}${props.suffix || ""}`
        ) : (
          props.renderOnEmpty
        ))}
    </span>
  );
};

export default DisplayPrice;
