import {useSelector} from "react-redux";
import {Flip, toast} from "react-toastify";
import {Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {AddToFavoritesModal} from "pages/Searches/SearchResults/Modals/AddToFavorites";
import i18n from "i18n";

const FAVORITE_TOAST_ID = "favorite";
type ToastChangeFavoriteType = "add" | "remove";
interface ToastChangeFavoriteListProps {
  type: ToastChangeFavoriteType;
  asin: string;
}

export const renderFavoriteToast = (processType: ToastChangeFavoriteType, asin: string) => {
  if (!toast.isActive(FAVORITE_TOAST_ID)) {
    toast(<ToastChangeFavoriteList type={processType} asin={asin} />, {
      toastId: FAVORITE_TOAST_ID,
      position: "bottom-right",
      type: "success" /* processType === "add" ? "success" : "error", */,
      hideProgressBar: false,
      closeButton: false,
      closeOnClick: false,
      transition: Flip,
    });
  } else {
    toast.update(FAVORITE_TOAST_ID, {
      render: <ToastChangeFavoriteList type={processType} asin={asin} />,
      position: "bottom-right",
      type: "success" /* processType === "add" ? "success" : "error", */,
      hideProgressBar: false,
      closeButton: false,
      closeOnClick: false,
      transition: Flip,
    });
  }
};

const ToastChangeFavoriteList = ({type, asin}: ToastChangeFavoriteListProps) => {
  const getSearchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      currentFavoriteList: state.SearchResult.currentFavoriteList,
    }),
  );

  const {currentFavoriteList} = useSelector(getSearchResultsData);

  const handleToggleModal = async () => {
    AddToFavoritesModal.open({asin});
  };

  return (
    <>
      <Row>
        <Col>
          {type === "add" ? (
            <>
              {currentFavoriteList?.name ? (
                <>
                  {i18n.t("Favorites.Toast.AddedToThisList")} <span className="fw-semibold "> {currentFavoriteList.name}</span>
                </>
              ) : (
                <> {i18n.t("Favorites.Toast.AddedToTheCurrentList")}</>
              )}
            </>
          ) : (
            <>{i18n.t("Favorites.Toast.RemovedFromYourFavorites")}</>
          )}
        </Col>
        <Col xs="auto">
          {type === "add" && currentFavoriteList?.name && (
            <span role="button" className="link-secondary link-offset-2 text-decoration-underline hand" onClick={handleToggleModal}>
              {i18n.t("Change")}
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};
