import {countryOptionContainer, defaultOptionContainer, singleSelectStyles, defaultValueContainer, countryValueContainer, tagColorOptionContainer, tagColorValueContainer} from "./Select/SelectStyle";
import {FormikValidation} from "helpers/types";
import {useTranslation} from "react-i18next";
import {FormFeedback} from "reactstrap";
import {SelectOptionsType} from "../Hooks/useSelectOptions";
import Select from "react-select";

interface ValidatedSelectProps<T> {
  value: any;
  field: keyof T;
  options: SelectOptionsType[] | ReadonlyArray<SelectOptionsType>;
  isClearable?: boolean;
  validation: FormikValidation<T>;
  className?: string;
  placeholder?: string;
  errorStyle?: "container" | "solid";
  optionStyle?: "default" | "country" | "tagColor";
  valueStyle?: "default" | "country" | "tagColor";
  submitOnChange?: boolean;
}
const ValidatedSelect = <T,>(componentProps: ValidatedSelectProps<T>) => {
  const {t} = useTranslation();
  const fieldName = componentProps.field.toString();
  const {error, touched} = componentProps.validation.getFieldMeta(fieldName);

  const handleChange = (selectedOption: any) => {
    const newValue = selectedOption ? selectedOption.value : undefined;

    componentProps.validation.setFieldValue(fieldName, newValue);
    if(componentProps.submitOnChange) 
      componentProps.validation.handleSubmit();
  };

  const option = componentProps.optionStyle === "country" ? countryOptionContainer : componentProps.optionStyle === "tagColor" ? tagColorOptionContainer : defaultOptionContainer;
  const value = componentProps.valueStyle === "country" ? countryValueContainer : componentProps.valueStyle === "tagColor" ? tagColorValueContainer : defaultValueContainer;
  return (
    <>
      <Select
        className={`${componentProps.className} ${touched && error && componentProps.errorStyle === "container" && "form-control is-invalid form-select-invalid"}`}
        name={fieldName}
        options={componentProps.options}
        isMulti={false}
        isClearable={componentProps.isClearable}
        onBlur={componentProps.validation.handleBlur}
        value={componentProps.options.filter((option: any) => {
          return componentProps.value && componentProps.value === option.value;
        })}
        onChange={handleChange}
        components={{Option: option, SingleValue: value}}
        placeholder={componentProps.placeholder}
        noOptionsMessage={() => t("NoOptions")}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={true}
        styles={singleSelectStyles}
        menuPortalTarget={document.body}
      />
      {touched && error ? (
        <FormFeedback type="invalid" className="searches validation-width">
          {error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedSelect;
