import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {CSSProperties, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Container, Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {ColumnDef} from "@tanstack/react-table";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {ScanAndSave} from "models/scan_and_save";
import {exportAsins} from "slices/scan-and-save/thunk";
import {cancelScan, getAdminScanAndSaveList, resetAdminScanAndSaveState, restartScan, undoDeleteScan} from "slices/admin/scanAndSave/thunk";
import {AdminScanAndSaveQuery} from "api/query";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {ScanAndSaveStatus} from "models/enums/scan_and_save_status";
import {ScanAndSaveBadge} from "Components/Common/Badges/ScanAndSaveBadge";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NoResult from "Components/Common/NoResult";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import DisplayNumber from "Components/Common/DisplayNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import SendAnalysis, { SendAnalysisModal } from "Components/Common/Modals/SendAnalysis";

export type AdminScanAndSaveListRef = {
  reload: VoidFunction;
};
interface AdminScanAndSavePageProps {
  edit?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "adminScanAndSave";
const AdminScanAndSavePage = (props: AdminScanAndSavePageProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [selectedScan, setSelectedScan] = useState<ScanAndSave>();
  const restartDialogRef = useRef<DialogRef>(null);
  const cancelDialogRef = useRef<DialogRef>(null);
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<AdminScanAndSaveListRef>(null);
  const undoDeleteDialogRef = useRef<DialogRef>(null);

  const [localQuery, updateLocalQuery] = useImmer<AdminScanAndSaveQuery>({
    action: "filtering",
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  let filterFields: FieldConfig<AdminScanAndSaveQuery>[] = [
    {field: "startDate", queryParam: "start", type: "date", defaultValue: getRelativeDateByTimezone(30, userProfile?.timezone)},
    {field: "endDate", queryParam: "end", type: "date", defaultValue: getToday().toDate()},
    {field: "marketplace", queryParam: "marketplace", type: "array"},
    {field: "userId", queryParam: "userId", type: "string"},
    {field: "statuses", queryParam: "statuses", type: "array"},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];
  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<AdminScanAndSaveQuery>(filterFields);

  const debouncedLoadList = useDebounce(() => {
    getAdminScanAndSaveList(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission("adminScanAndSave") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as AdminScanAndSaveQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const scanAndSaveData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminScanAndSave.loading,
      list: state.AdminScanAndSave.scanAndSaveList,
    }),
  );

  const {loading, list} = useSelector(scanAndSaveData);

  const columns = useMemo<ColumnDef<ScanAndSave, any>[]>(
    () => [
      {
        header: t("Actions"),
        enableSorting: false,
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const disableCancelStyle: CSSProperties = row.status === ScanAndSaveStatus.CANCELED || row.status === ScanAndSaveStatus.DONE ? {pointerEvents: "none", color: "#dedede"} : {};
          const disableRestartStyle: CSSProperties = row.status !== ScanAndSaveStatus.CANCELED && row.status !== ScanAndSaveStatus.DONE ? {pointerEvents: "none", color: "#dedede"} : {};
          return (
            <>
              <div className="d-flex flex-row align-items-center">
                <Col xs="auto">
                  <Link
                    to=""
                    id={`RestartScan-${row.id}`}
                    className="btn btn-ghost-success px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedScan(row);
                      restartDialogRef.current?.show();
                    }}
                    style={disableRestartStyle}
                  >
                    <i className="mdi mdi-sync-circle align-middle"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`RestartScan-${row.id}`}>{t("Admin.ScanAndSave.Tooltip.Restart")}</DefaultUncontrolledTooltip>
                </Col>
                <Col xs="auto">
                  <Link
                    to=""
                    id={`CancelScan-${row.id}`}
                    className="btn btn-ghost-danger px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedScan(row);
                      cancelDialogRef.current?.show();
                    }}
                    style={disableCancelStyle}
                  >
                    <i className="mdi mdi-close-circle align-middle"></i>
                  </Link>
                  <DefaultUncontrolledTooltip target={`CancelScan-${row.id}`}>{t("Admin.ScanAndSave.Tooltip.Cancel")}</DefaultUncontrolledTooltip>
                </Col>
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.User"),
        enableSorting: false,
        size: 300,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <img src={row?.avatarUrl || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image" alt="user-profile" />
              </div>
              <div className="flex-grow-1 ellipsis-two-lines">
                <div className="fw-semibold">
                  {row?.user?.fullName}
                  {row?.user?.userId === userProfile?.userId && <Badge className="ms-1 rounded-pill">{t("You")}</Badge>}
                </div>
                <div className="text-muted" title={row?.user?.email}>
                  {row?.user?.email}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.ScanName"),
        enableSorting: true,
        accessorKey: "name",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <div className="ellipsis-two-lines" title={row.searchName}>
                {row.searchName}
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Url"),
        enableSorting: false,
        size: 400,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <div className="ellipsis-two-lines" title={row.url} style={{maxWidth: 250}}>
                <Link to={row.url} className="link-secondary link-offset-2 text-decoration-underline" target="_blank">
                  {row.url}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.CreateDate"),
        enableSorting: true,
        accessorKey: "createOn",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <>
              <DisplayDate id={`DisplayDateScanAndSaveCreateDate-${row.id}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Status"),
        enableSorting: true,
        accessorKey: "status",
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return (
            <div className="d-fle align-items-center">
              {row.deleted ? (
                <>
                  <span className={`float-start badge rounded-pill bg-danger-subtle text-danger fs-12`}>{t("Deleted")}</span>
                  <Button
                    type="button"
                    id={`UndoDelete-${row.id}`}
                    className="btn btn-ghost-secondary waves-effect py-0 px-1 fs-16"
                    onClick={() => {
                      setSelectedScan(row);
                      undoDeleteDialogRef.current?.show();
                    }}
                  >
                    <i className="mdi mdi-undo-variant align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`UndoDelete-${row.id}`}>{t("Admin.ScanAndSave.Tooltip.UndoDelete")}</DefaultUncontrolledTooltip>
                </>
              ) : (
                <ScanAndSaveBadge status={row.status} />
              )}
            </div>
          );
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Total"),
        enableSorting: true,
        accessorKey: "foundTotalItems",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <DisplayNumber value={row.total} />;
        },
      },
      {
        header: t("Admin.ScanAndSave.TableColumn.Sent"),
        accessorKey: "sent",
        sortingFn: "alphanumeric",
        size: 90,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          return <DisplayNumber value={row.sent} />;
        },
      },
      {
        header: " ",
        size: 50,
        enableResizing: false,
        cell: (cellProps) => {
          const row = cellProps.row.original as ScanAndSave;
          const [isExporting, setIsExporting] = useState(false); // eslint-disable-line
          return (
            <div className="d-flex align-items-center">
              <Link
                to=""
                id={`Analyze-${row.id}`}
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 || row.total === row.sent ? "disabled" : ""}`}
                onClick={() => {
                  //setSelectedScan(row);
                  //setIsSendingAnalysis(true);
                  SendAnalysisModal.open({
                    id: row.id,
                    listRef,
                    searchName: row.searchName,
                    total: row.total,
                    showAdminFields: true,
                  })
                }}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Analyze-${row.id}`}>{t("Admin.ScanAndSave.Button.Analyze")}</DefaultUncontrolledTooltip>

              <Link
                to=""
                id={`DownloadScan-${row.id}`}
                className={`btn btn-ghost-secondary px-1 py-0 fs-18 ${row.total === 0 || isExporting ? "disabled" : ""}`}
                onClick={() => {
                  setIsExporting(true);
                  const exportPromise = exportAsins({asinFetcherTaskId: row.id}, row.searchName)(dispatch);
                  exportPromise.then(() => {
                    setIsExporting(false);
                  });
                }}
              >
                {isExporting ? <Spinner size="sm"></Spinner> : <i className="mdi mdi-cloud-download align-middle"></i>}
              </Link>
              <DefaultUncontrolledTooltip target={`DownloadScan-${row.id}`}>{t("Admin.ScanAndSave.Button.Download")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      dispatch(resetAdminScanAndSaveState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = t("PageTitles.ScanAndSave");

  //#endregion
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Admin.ScanAndSave.Title")} menus={[{label: t("Admin.ScanAndSave.Title")}]} />
          <Filters listRef={listRef} fields={filterFields} busy={loading.filter} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list?.items || []}
                    totalDataLength={list?.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Admin.ScanAndSave.NoResult.Title")} description={t("Admin.ScanAndSave.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <SendAnalysis />
      <Dialog
        ref={restartDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.restart}
        iconClass="ri-restart-line"
        message={t("Admin.ScanAndSave.Dialog.Restart.Description")}
        title={t("Admin.ScanAndSave.Dialog.Restart.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedScan) {
              await restartScan(selectedScan)(dispatch);
            }
          }
          restartDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={cancelDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="ri-close-circle-line"
        message={t("Admin.ScanAndSave.Dialog.Cancel.Description")}
        title={t("Admin.ScanAndSave.Dialog.Cancel.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedScan) {
              await cancelScan(selectedScan)(dispatch);
            }
          }
          cancelDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={undoDeleteDialogRef}
        color="secondary"
        buttons={["yes", "no"]}
        busy={loading.cancel}
        iconClass="mdi mdi-undo-variant"
        message={t("Admin.ScanAndSave.Dialog.UndoDelete.Description")}
        title={t("Admin.ScanAndSave.Dialog.UndoDelete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedScan) {
              await undoDeleteScan(selectedScan)(dispatch);
              listRef.current?.reload();
            }
          }
          undoDeleteDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default AdminScanAndSavePage;
