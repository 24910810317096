import useSelectOptions, {MultiSelectTranslations, SelectOptionsType} from "../Hooks/useSelectOptions";
import {FormikValidation} from "helpers/types";
import {FormFeedback} from "reactstrap";
import {MultiSelect} from "react-multi-select-component";
import {countryItemRenderer, defaultItemRenderer, tagColorItemRenderer, userItemRenderer} from "./Select/MultiSelectStyle";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";

interface ValidatedMultiSelectProps<T> {
  value: any;
  field: keyof T;
  options: SelectOptionsType[];
  isClearable?: boolean;
  validation: FormikValidation<T>;
  className?: string;
  errorStyle?: "container" | "solid";
  translations?: Partial<MultiSelectTranslations>;
  optionStyle?: "default" | "country" | "tagColor" | "user";
  busy?: boolean;
  submitOnChange?: boolean;
}
const ValidatedMultiSelect = <T,>(componentProps: ValidatedMultiSelectProps<T>) => {
  const {multiSelectTranslations} = useSelectOptions();
  const fieldName = componentProps.field.toString();
  const {error, touched} = componentProps.validation.getFieldMeta(fieldName);

  const handleChange = (selectedOption: any) => {
    const newValue = selectedOption ? selectedOption.map((option: any) => option.value) : undefined;
    componentProps.validation.setFieldValue(fieldName, newValue);

    if (componentProps.submitOnChange) componentProps.validation.handleSubmit();
  };
  const layoutData = createSelector(
    (state: RootState) => state,
    (state) => ({
      layoutModeType: state.Layout.layoutModeType,
    }),
  );
  const {layoutModeType} = useSelector(layoutData);

  const mergedTranslations: MultiSelectTranslations = {
    ...multiSelectTranslations,
    ...componentProps.translations,
  };

  const option =
    componentProps.optionStyle === "country"
      ? countryItemRenderer
      : componentProps.optionStyle === "tagColor"
      ? tagColorItemRenderer
      : componentProps.optionStyle === "user"
      ? userItemRenderer
      : defaultItemRenderer;
  return (
    <>
      <MultiSelect
        className={`${componentProps.className} ${layoutModeType} ${touched && error && componentProps.errorStyle === "container" && "form-control is-invalid form-select-invalid h-auto"}`}
        ItemRenderer={option}
        options={componentProps.options}
        value={
          componentProps.options?.filter((option: any) => {
            return componentProps.value && componentProps.value.includes(option.value);
          }) || []
        }
        isLoading={componentProps.busy}
        onChange={handleChange}
        labelledBy={"Select"}
        isCreatable={false}
        overrideStrings={mergedTranslations}
      />
      {touched && error ? (
        <FormFeedback type="invalid" className="searches validation-width">
          {error}
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedMultiSelect;
