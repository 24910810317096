const SuccessRateCalculator = (actualValue?: number, expectedValue?: number) => {
  if (actualValue === undefined || expectedValue === undefined || actualValue === null || expectedValue === null) {
    return 0;
  }

  if (actualValue <= 0) {
    return 0;
  }

  if (expectedValue <= 0) {
    return 100;
  }

  var successRate = ((actualValue ?? 0) / (expectedValue ?? 1)) * 100;

  if (successRate > 100) {
    successRate = 100;
  }

  return successRate;
};

export default SuccessRateCalculator;
