import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import EditUserFavoriteList, {UpdateFavoriteListModal} from "./Modals/EditUserFavoriteList";
import ReAnalyze, {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Button, Card, CardBody, Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {ColumnDef} from "@tanstack/react-table";
import {useNavigate} from "react-router-dom";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {GetUserFavoriteListQuery} from "api/query";
import {UserFavorite} from "models/user_favorites";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {ActiveMarketplaces} from "helpers/marketplace_helper";
import {deleteUserFavoriteList, getUserFavorites} from "slices/favorites/thunk";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import NoResult from "Components/Common/NoResult";
import DisplayDate from "Components/Common/DisplayDate";

export type FavoritesListRef = {
  reload: VoidFunction;
};
const Favorites = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {userProfile, hasPermission} = useProfile();
  const [localQuery, updateLocalQuery] = useImmer<GetUserFavoriteListQuery>({
    action: "filtering",
    page: 1,
    pageSize: 10,
    marketplace: undefined,
  });

  const [selectedFavoriteList, setSelectedFavoriteList] = useState<UserFavorite>();
  const deleteDialogRef = useRef<DialogRef>(null);
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<FavoritesListRef>(null);
  const dispatch: any = useDispatch();

  let filterFields: FieldConfig<GetUserFavoriteListQuery>[] = [
    {field: "marketplace", queryParam: "marketplace", type: "array"},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];

  const debouncedLoadList = useDebounce(() => {
    getUserFavorites(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);
  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<GetUserFavoriteListQuery>(filterFields);

  useEffect(() => {
    if (hasPermission("favorites") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as GetUserFavoriteListQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const favoritesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Favorites.loading,
      list: state.Favorites.list,
    }),
  );
  const {loading, list} = useSelector(favoritesData);

  const columns = useMemo<ColumnDef<UserFavorite, any>[]>(
    () => [
      {
        header: t("Actions"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  ReAnalyzeModal.open({
                    searchName: row.name,
                    businessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
                    store: row.marketplace,
                    favoriteId: row.userFavoriteId,
                    type: AnalyzeType.FAVORITE,
                  });
                }}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
              </Button>

              <Button
                color="link"
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  UpdateFavoriteListModal.open({favorite: row});
                }}
              >
                <i className="ri-pencil-fill align-middle"></i>
              </Button>

              <Button
                color="link"
                className="btn btn-ghost-danger px-1 py-0 fs-18"
                onClick={() => {
                  setSelectedFavoriteList(row);
                  deleteDialogRef.current?.show();
                }}
              >
                <i className="ri-delete-bin-fill align-middle"></i>
              </Button>
            </div>
          );
        },
      },
      {
        header: t("Favorites.TableColumn.Name"),
        enableSorting: true,
        size: 100,
        accessorKey: "name",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <span className="link-secondary link-offset-2 text-decoration-underline" onClick={() => navigate(`/favorites/${row.userFavoriteId}`)} role="button">
              {row.name}
            </span>
          );
        },
      },
      {
        header: t("Favorites.TableColumn.Description"),
        enableSorting: true,
        accessorKey: "description",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          const textClass = row.description ? "" : "text-muted fst-italic";
          const description = row.description ? row.description : t("Favorites.NoDescription");
          return <span className={`${textClass} ellipsis-two-lines`}>{description}</span>;
        },
      },
      {
        header: t("Favorites.TableColumn.Marketplace"),
        enableSorting: true,
        size: 200,
        accessorKey: "marketplace",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          const marketplaceInfo = ActiveMarketplaces.find((mp) => mp.marketplace === row.marketplace);
          return (
            <>
              {marketplaceInfo && (
                <>
                  <span>
                    <span className="hstack gap-2">
                      <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                        <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                      </div>
                      {`${marketplaceInfo.countryName} (${marketplaceInfo.marketplace})`}
                    </span>
                  </span>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Favorites.TableColumn.Total"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return <>{row.count}</>;
        },
      },
      {
        header: t("Favorites.TableColumn.CreateDate"),
        enableSorting: true,
        size: 100,
        accessorKey: "createDate",
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <>
              <DisplayDate id={`DisplayDateCreateDate-${row.userFavoriteId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
    ],
    [t, userProfile], // eslint-disable-line
  );

  document.title = t("PageTitles.Favorites");

  return (
    <Restricted require={"search"} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Favorites.Title")} menus={[{label: t("Favorites.Title")}]} />
          <Filters fields={filterFields} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list?.items || []}
                    totalDataLength={list?.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Favorites.EmptyList.Title")} description={t("Favorites.EmptyList.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Restricted require="favorites" update>
        <EditUserFavoriteList />
        <ReAnalyze />
      </Restricted>
      <Restricted require="favorites" delete>
        <Dialog
          ref={deleteDialogRef}
          color="danger"
          buttons={["yes", "no"]}
          busy={loading.delete}
          iconClass="ri-delete-bin-line"
          message={t("Favorites.Dialog.Delete.Description")}
          title={t("Favorites.Dialog.Delete.Title")}
          onButtonClick={async (button, hide) => {
            if (button === "yes") {
              if (selectedFavoriteList) {
                await deleteUserFavoriteList(selectedFavoriteList?.userFavoriteId, localQuery)(dispatch);
              }
            }
            deleteDialogRef.current?.hide();
          }}
        />
      </Restricted>
    </Restricted>
  );
};

export default Favorites;
