import {t} from "i18next";
import ReactApexChart from "react-apexcharts";

interface StrokedCircularRadialProps {
  progress: number;
  label: string;
  height: number;
}
export const StrokedCircularRadial = (props: StrokedCircularRadialProps) => {
  const series = [props.progress.toFixed(0) || 0] as any;

  var options: ApexCharts.ApexOptions = {
    chart: {
      type: "radialBar",
      height: 280,
      offsetY: 0,
    },
    colors: ["#20E647"],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 0,
          size: "70%",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 1,
            left: 0,
            blur: 2,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            offsetY: -30,
            color: "#888",
            fontSize: "13px",
          },
          value: {
            offsetY: -10,
            color: "#495057",
            fontSize: "30px",
            show: true,
            formatter: function(val: any) {
              return t("PercentSign", {value: val});
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -20,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
        opacityFrom: 1,
        opacityTo: 1,
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [props.label],
  };
  return <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="radialBar" height={props.height} />;
};
