import ShipmentInfo from "./_ShipmentInfo";
import Stats from "./_Stats";
import ProjectedSalesAndProfits from "./_ProjectedSalesAndProfits";
import CostAndProfitAnalysis from "./_CostAndProfitAnalysis";
import SalesHistory from "./_SalesHistory";
import ProductList from "./_ProductList";
import SoldOutProducts from "./_SoldOutProducts";
import Orders from "./_Orders";
import {Row} from "reactstrap";
import RemainingProducts from "./_RemainingProducts";
import FinanceStats from "./_FinanceStats";

const ShippedOrCompleted = () => {
  return (
    <>
      <ShipmentInfo />

      <Stats />

      <Row>
        <FinanceStats />
        <ProjectedSalesAndProfits />
      </Row>

      <CostAndProfitAnalysis />

      <SalesHistory />

      <ProductList />

      <Row className="g-4">
        <RemainingProducts />
        <SoldOutProducts />
      </Row>
      <Orders />
    </>
  );
};

export default ShippedOrCompleted;
