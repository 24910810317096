import {useCallback, useEffect, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {DateRange, FormikValidation, NumberRange} from "helpers/types";
import {useProfile} from "./useProfile";
import moment from "moment";

export type FieldType = "number" | "numberRange" | "string" | "array" | "date" | "dateRange" | "bool";

export type FieldConfig<T> = {
  field: keyof T;
  queryParam: string;
  type: FieldType;
  defaultValue?: T[keyof T];
  hideQuery?: boolean;
  hideChip?: boolean;
  chipName?: string;
  disabled?: boolean;
};

export const useUrlQuery = <T>(fields: FieldConfig<T>[], validation?: FormikValidation<T>) => {
  const {search: LocationSearch, pathname: LocationPathname} = useLocation();
  const navigate = useNavigate();
  const {userProfile} = useProfile();

  const fieldsMap = useMemo(() => {
    return fields.reduce((acc, field) => {
      acc[field.queryParam] = field;
      return acc;
    }, {} as Record<string, FieldConfig<T>>);
  }, [fields]);

  const searchParams = useMemo(() => new URLSearchParams(LocationSearch), [LocationSearch]);

  // Read field from the URL
  const readQueryParams = useCallback(() => {
    const params: Partial<T> = {};
    //console.time("render1");
    fields.forEach(({field, queryParam, type, disabled}) => {
      const value = searchParams.get(queryParam);
      if (!value || disabled) return;

      try {
        switch (type) {
          case "array":
            params[field] = value.split(",") as T[keyof T];
            break;
          case "bool":
            params[field] = ((value === "true") as unknown) as T[keyof T];
            break;
          case "date":
            if (!userProfile) break;

            if (queryParam.includes("start")) {
              params[field] = moment(value, "YYYY-MM-DD").tz(userProfile.timezone).startOf("day").tz("Etc/GMT").toDate() as T[keyof T];
            } else if (queryParam.includes("end")) {
              params[field] = moment(value, "YYYY-MM-DD").tz(userProfile.timezone).endOf("day").tz("Etc/GMT").toDate() as T[keyof T];
            } else {
              params[field] = moment(value, "YYYY-MM-DD").toDate() as T[keyof T];
            }
            break;
          case "dateRange":
            if (!userProfile) break;
            const delimiter = ";";
            const [start, end] = value.split(delimiter);

            // Handle null/empty values for start and end dates
            const dateRange: DateRange = {
              start: start
                ? moment(start, "YYYY-MM-DD")
                    .tz(userProfile.timezone)
                    .startOf("day")
                    .tz("Etc/GMT")
                    .toDate()
                : undefined,
              end: end
                ? moment(end, "YYYY-MM-DD")
                    .tz(userProfile.timezone)
                    .endOf("day")
                    .tz("Etc/GMT")
                    .toDate()
                : undefined,
            };
            params[field] = dateRange as T[keyof T];
            break;
          case "numberRange":
            const [min, max] = value.split(";").map((v) => (v ? Number(v) : undefined));
            const range: NumberRange = {
              min: Number(min) !== undefined ? Number(min) : undefined,
              max: Number(max) !== undefined ? Number(max) : undefined,
            };
            params[field] = range as T[keyof T];
            break;
          default:
            params[field] = value as T[keyof T];
        }
      } catch (error) {
        console.warn(`Error parsing parameter ${queryParam}:`, error);
      }
    });
    //console.timeEnd("render1");
    return params;
  }, [searchParams, fieldsMap]); // eslint-disable-line

  /// Updating urls by formik values
  const updateQuery = useCallback(
    (values: Partial<T>) => {
      fields.forEach(({field, queryParam, hideQuery: hide, type, disabled}) => {
        const value = values[field];
        if (value === undefined || value === null || (Array.isArray(value) && value.length === 0) || (typeof value === "string" && value.trim() === "") || disabled ) {
          searchParams.delete(queryParam);
          return;
        }

        if (!hide) {
          if (type === "array" && Array.isArray(value)) {
            searchParams.set(queryParam, value.join(","));
          } else if (type === "date" && value instanceof Date) {
            searchParams.set(queryParam, moment(value).format("YYYY-MM-DD"));
          } else if (type === "dateRange") {
            const dateRange = value as DateRange;
            if (dateRange.start || dateRange.end) {
              const startDate = dateRange.start ? moment(dateRange.start).format("YYYY-MM-DD") : "";
              const endDate = dateRange.end ? moment(dateRange.end).format("YYYY-MM-DD") : "";
              searchParams.set(queryParam, `${startDate};${endDate}`);
            } else {
              searchParams.delete(queryParam);
            }
          } else if (type === "numberRange") {
            const range = value as NumberRange;
            if (range.min === undefined && range.max === undefined) {
              searchParams.delete(queryParam);
            } else {
              const minVal = range.min !== undefined && range.min !== null ? range.min.toString() : "";
              const maxVal = range.max !== undefined && range.max !== null ? range.max.toString() : "";
              if (!minVal && !maxVal) {
                searchParams.delete(queryParam);
              } else {
                searchParams.set(queryParam, `${minVal};${maxVal}`);
              }
            }
          } else {
            searchParams.set(queryParam, value as string);
          }
        }
      });
      searchParams.set("_t", Date.now().toString());
      const decodedSearch = decodeURIComponent(searchParams.toString());
      navigate({
        pathname: LocationPathname,
        search: decodedSearch,
      });
    },
    [fields, navigate, LocationPathname, searchParams],
  );

  const updateFormik = useCallback(() => {
    if (validation) {
      //console.time("render3");
      const urlParams = readQueryParams();

      // Add default values
      const formValues = Object.values(fieldsMap).reduce(
        (values, {field, defaultValue, disabled}) => {
          if (!(field in urlParams) && defaultValue !== undefined && !disabled) {
            values[field] = defaultValue;
          }
          return values;
        },
        {...urlParams},
      );

      validation.setValues(formValues as T);
      //console.timeEnd("render3");
    }
  }, [fieldsMap, readQueryParams]); // eslint-disable-line

  useEffect(() => {
    updateFormik();
  }, [updateFormik]);

  return {
    updateQuery,
    readQueryParams,
    searchParams,
  };
};
