import { AllMarketplaces, AmazonMarketplace } from "helpers/marketplace_helper";
import {UserSearchProduct} from "models/user_search_product";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, Col} from "reactstrap";

interface ProductOffersProps {
  row: UserSearchProduct;
}
const ProductOffers = ({row}: ProductOffersProps) => {
  const {t} = useTranslation();
  
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();

  useEffect(() => {
    if (row) {
      let source = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.domain === row.domainSource);
      let destination = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.domain === row.domainTarget);
      setSourceMarketplace(source);
      setDestinationMarketplace(destination);
    }
  }, [row]);//eslint-disable-line
  return (
    <Card className="border card-animate">
      <CardHeader>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="card-title mb-0">{t("Admin.SearchLogs.Box.Results.ProductOffers.Title")}</h6>
        </div>
      </CardHeader>
      <CardBody>
        <div className="d-flex">
          <Col xs={12} sm={6} className="d-flex text-nowrap align-items-center">
            <img src={sourceMarketplace?.flag} alt="Country Flag" className="" height="20" />
            <span className="ms-1 text-nowrap">{t(sourceMarketplace?.marketplace ?? "")}</span>
          </Col>
          <Col xs={12} sm={6}>
            <div className="d-flex justify-content-end">
              <h2 className="mb-0 mt-1">
                {row.spapiProductOffersSourceSucceeded === true ? (
                  <div className="d-flex align-items-center">
                   <i className="text-success bx bxs-check-circle"></i>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                   <i className="text-danger bx bxs-x-circle"></i>
                  </div>
                )}
              </h2>
            </div>
          </Col>
        </div>
        <div className="d-flex">
          <Col className="d-flex text-nowrap align-items-center">
            <img src={destinationMarketplace?.flag} alt="Country Flag" className="" height="20" />
            <span className="ms-1 text-nowrap">{t(destinationMarketplace?.marketplace ?? "")}</span>
          </Col>
          <Col>
            <div className="d-flex justify-content-end">
              <h2 className="mb-0 mt-1">
                {row.spapiProductOffersSucceeded === true ? (
                  <div className="d-flex align-items-center">
                   <i className="text-success bx bxs-check-circle"></i>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                   <i className="text-danger bx bxs-x-circle"></i>
                  </div>
                )}
              </h2>
            </div>
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProductOffers;
