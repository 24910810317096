import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {ISetUserShipmentDetailsDto, UserShipment} from "models/user_shipment";
import {useState} from "react";
import {changeUserShipmentStatus} from "slices/thunks";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import Checkbox from "Components/Common/Checkbox";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import {getToday} from "helpers/utilities";

interface ChangeShipmentStatusProps {
  userShipment: UserShipment;
}
const ChangeShipmentStatus = (props: ChangeShipmentStatusProps) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch: any = useDispatch();

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
    }),
  );

  const {loading} = useSelector(shipmentsData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amazonShipmentId: props.userShipment.amazonShipmentId,
      shipDate: props.userShipment.shipDate,
    },
    validationSchema: Yup.object().shape({
      amazonShipmentId: Yup.string().max(50, t("Shipments.AmazonShipmentIdMaxLength")),
      shipDate: Yup.date()
        .required(t("Shipments.ShipDateRequired"))
        .min(new Date("2023-01-01"), t("Start date must be later than January 01, 2023"))
        .typeError(t("Invalid date type")),
      updateInventory: Yup.boolean(),
    }),
    onSubmit: (values: any) => {
      let newShipmentDetails: ISetUserShipmentDetailsDto = {
        userShipmentId: props.userShipment.userShipmentId,
        userInventoryId: "",
        userShipmentItemId: "",
        field: "ShipDate",
        newValue: values.updateInventory ? 1 : 0,
        amazonShipmentId: values.amazonShipmentId,
        shipDate: values.shipDate,
        from: "Shipments",
      };

      const setPromise = changeUserShipmentStatus(newShipmentDetails)(dispatch);
      setPromise.then((isSuccess) => {
        if (isSuccess) {
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button type="button" color="secondary" className="modal-btn btn-sm" onClick={toggle}>
        <i className="ri-amazon-fill label-icon align-middle fs-16 me-2"></i>
        {t("Shipments.ShipmentDetails.MarkAsShipped")}
      </Button>
      <Modal id="showChangeShipmentStatusModal" isOpen={isOpen} toggle={toggle} fade={true} centered={true} size="lg">
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Shipments.ShipmentDetails.MarkAsShipped")}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <h5>{t("Shipments.ShipmentDetails.EnterShipmentDetails")}</h5>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col></Col>
          </Row>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="g-3">
              <Col xs={12} md={4}>
                <Label>{t("Shipments.AmazonShipmentId")}</Label>
                <ValidatedInput validation={validation} field={"amazonShipmentId"} maxLength={50} disableValidationUI />
              </Col>
              <Col xs={12} md={4}>
                <Label htmlFor="shipDate">{t("Shipments.ShipDate")}</Label>
                <ValidatedDate
                  className="w-100"
                  field="shipDate"
                  validation={validation}
                  value={validation.values.shipDate}
                  max={getToday()
                    .add(2, "month")
                    .toDate()}
                ></ValidatedDate>
              </Col>
              <Col xs={12} md={4}>
                <Label></Label>
                <div className="shipment-details input-checkbox">
                  <ValidationWrapper validation={validation} field="updateInventory">
                    <Checkbox>
                      <span>{t("Shipments.UpdateInventory")}</span>
                    </Checkbox>
                  </ValidationWrapper>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button
              type="button"
              color="light"
              disabled={loading.changeStatus}
              onClick={() => {
                toggle();
              }}
            >
              {t("Close")}
            </Button>
            <Button
              type="submit"
              color="secondary"
              disabled={loading.changeStatus}
              onClick={() => {
                validation.setFieldTouched("shipDate");
                validation.setFieldTouched("amazonShipmentId");

                if (validation.isValid) {
                  validation.handleSubmit();
                }
              }}
            >
              {loading.changeStatus && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("Shipments.ShipmentDetails.MarkAsShipped")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ChangeShipmentStatus;
