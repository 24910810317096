import {useProfile} from "Components/Hooks/useProfile";
import {getMenuModeLocalData} from "helpers/local_storage";
import {getToday} from "helpers/utilities";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const ProtectedRoutes = () => {
  const {userProfile, isUserLoggedIn, isAdmin} = useProfile();
  const location = useLocation();
  const hasSubscription = userProfile?.hasSubscription;
  const isTokenValid = userProfile && new Date(userProfile.tokenInfo.expiresAt) > getToday().toDate();
  
  // If token is invalid
  if (!isTokenValid) {
    if (location.pathname !== "/") {
      return <Navigate to={`/account/login?return_url=${location.pathname}`} />;
    } else {
      return <Navigate to="/account/login" />;
    }
  }

  // If user has no subscription
  if (!hasSubscription && location.pathname !== "/account/subscription/new") {
    return <Navigate to="/account/subscription/new" />;
  }

  // If user has subscription and is trying to access "/account/subscription/new"
  if (hasSubscription && location.pathname === "/account/subscription/new") {
    return <Navigate to="/" />;
  }

  // If user trying to access login page when already logged in
  if (location.pathname === "/" || location.pathname === "/account/login") {
    const menuMode = getMenuModeLocalData();
    if (menuMode === "Admin" && isAdmin()) return <Navigate to="/admin/system-monitoring" />;
    else return <Navigate to="/dashboard" />;
  }

  if (isUserLoggedIn()) {
    return <Outlet />;
  }

  // Redirect to target page
  return <Outlet />;
};

export default ProtectedRoutes;
