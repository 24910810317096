import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {AdminSearchesListRef} from "..";
import {getSelectUserQuery} from "services/admin_service";
import {AdminUserSearchesQuery} from "api/query";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import ValidateDate from "Components/Common/ValidatedDate";
import ValidatedAsyncSelect from "Components/Common/ValidatedAsyncSelect";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import useSelectOptions, {UserSelectOption} from "Components/Hooks/useSelectOptions";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";

interface FiltersProps {
  listRef: RefObject<AdminSearchesListRef>;
  busy: boolean;
  fields: FieldConfig<AdminUserSearchesQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {searchStatusSelectOptions, businessModelSelectOptions} = useSelectOptions();

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );

  const {activeMarketplaceOptions} = useSelector(commonData);
  // validation
  const validation = useFormik({
    initialValues: {} as AdminUserSearchesQuery,
    validationSchema: Yup.object<AdminUserSearchesQuery>({
      startDate: Yup.date(),
      endDate: Yup.date(),
      marketplace: Yup.array<String>().notRequired(),
      amazonBusinessModels: Yup.array<String>().notRequired(),
      userId: Yup.string().notRequired(),
      statuses: Yup.array<String>().notRequired(),
    }),
    onSubmit: (values) => {
      const payload: AdminUserSearchesQuery = {
        page: values.page,
        pageSize: values.pageSize,
        startDate: values.startDate,
        endDate: values.endDate,
        marketplace: values.marketplace,
        statuses: values.statuses,
        userId: values.userId,
        amazonBusinessModels: values.amazonBusinessModels,
        sortBy: values.sortBy,
        sortingOrder: values.sortingOrder,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<AdminUserSearchesQuery>(props.fields, validation);

  const loadUserOptions = async (inputValue: string): Promise<UserSelectOption[]> => {
    if (inputValue === "") return [];

    const response = await getSelectUserQuery({query: inputValue});
    const data: UserSelectOption[] = await response.data;

    return data.map((item: UserSelectOption) => ({
      label: item.label,
      value: item.value,
      avatar: item.avatar,
      email: item.email,
    }));
  };

  const onLoad = useCallback(
    () => {
      validation.handleSubmit();
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]); // eslint-disable-line

  return (
    <PlaceholderGlow busy={false}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form onSubmit={validation.handleSubmit}>
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Admin.Searches.Filters.Title")}</Label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="startDate">{t("Admin.Searches.Filters.Label.StartDate")}</Label>
                  <ValidateDate field="startDate" validation={validation} value={validation.values.startDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="endDate">{t("Admin.Searches.Filters.Label.EndDate")}</Label>
                  <ValidateDate field="endDate" validation={validation} value={validation.values.endDate}></ValidateDate>
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="marketplace">{t("Admin.Searches.Filters.Label.Marketplace")}</Label>
                  <ValidatedMultiSelect
                    className="filter-input"
                    options={activeMarketplaceOptions}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    optionStyle="country"
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="user">{t("Admin.Searches.Filters.Label.User")}</Label>
                  <ValidatedAsyncSelect
                    className="filter-input"
                    loadOptions={loadUserOptions}
                    validation={validation}
                    field="userId"
                    placeholder={t("Admin.Searches.Filters.Label.SelectPlaceholder")}
                    noOptionsMessage={t("Admin.Searches.Filters.Label.TypeToFindUser")}
                    loadingMessage={t("Admin.Searches.Filters.Label.UserSearching")}
                    value={validation.values.userId}
                    isClearable
                    errorStyle="solid"
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="marketplace">{t("Business Model")}</Label>
                  <ValidatedMultiSelect
                    className="filter-input"
                    options={businessModelSelectOptions}
                    validation={validation}
                    field="amazonBusinessModels"
                    value={validation.values.amazonBusinessModels}
                  />
                </Col>
                <Col sm={2} className="mb-3 searches filters-col">
                  <Label htmlFor="status">{t("Admin.Searches.Filters.Label.Status")}</Label>
                  <ValidatedMultiSelect
                    className="filter-input"
                    options={searchStatusSelectOptions}
                    validation={validation}
                    field="statuses"
                    value={validation.values.statuses}
                  />
                </Col>
                <Col sm={2}>
                  <label className="form-label">&nbsp;</label>
                  <Button type="submit" color="secondary" className="d-block" disabled={props.busy} onClick={() => validation.setFieldValue("page", 1)}>
                    {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("Admin.Searches.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
