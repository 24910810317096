interface SummaryCardProps {
  icon: string;
  iconColorClassName?: string;
  label: string;
  cardClassName?: string;
  content: JSX.Element;
}

export const SummaryCard = (props: SummaryCardProps) => {
  return (
    <div className="d-flex align-items-center justify-items-center col-12 col-sm-auto">
      <div className={`p-2 border card mb-0 rounded w-100 ${props.cardClassName || ""}`}>
        <div className="d-flex align-items-center">
          <div className="avatar-sm me-2">
            <div className={`avatar-title rounded bg-transparent fs-24 ${props.iconColorClassName || "text-secondary"}`}>
              <i className={props.icon}></i>
            </div>
          </div>
          <div className="flex-grow-1 me-3">
            <p className="text-muted mb-1">{props.label}:</p>
            <h5 className="mb-0 d-flex align-items-center">{props.content}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};
