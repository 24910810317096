import CreateOrEditFavoriteList, {CreateOrEditFavoriteListModal} from "./CreateOrEditFavoriteList";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Input, Button, ModalFooter} from "reactstrap";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UserFavorite} from "models/user_favorites";
import {Link} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import {useDebounce} from "Components/Hooks/useDebounce";
import {useProfile} from "Components/Hooks/useProfile";
import {changeCurrentFavoriteList, getUserFavoriteList, addFavoriteItem, deleteUserFavoriteList} from "slices/search-result/thunk";
import {removeFavItem} from "slices/search-result/reducer";
import {createTypedModal} from "helpers/modal_helpers";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {ActiveMarketplaces} from "helpers/marketplace_helper";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";

interface ModalData {
  asin: string;
  marketplaceTarget?: string;
}
export const AddToFavoritesModal = createTypedModal<ModalData>("add_to_favorites");
const favoriteSelector = createSelector([(state: RootState) => state.SearchResult], (searchResult) => ({
  loading: searchResult.loading,
  favoriteList: searchResult.favoriteList,
  currentFavoriteList: searchResult.currentFavoriteList,
}));

export type FavoriteListRef = {
  reload: VoidFunction;
};

const AddToFavorites = () => {
  const {open, data} = AddToFavoritesModal.useModal();
  if (!open) return null;
  return <AddToFavoritesContent data={data} />;
};

export const AddToFavoritesContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [selectedFavoriteList, setSelectedFavoriteList] = useState<UserFavorite>();
  const {hasPermission} = useProfile();
  const deleteDialogRef = useRef<DialogRef>(null);

  const {favoriteList, loading, currentFavoriteList} = useSelector(favoriteSelector);

  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<FavoriteListRef>(null);
  const debouncedLoadList = useDebounce(async () => {
    if (hasPermission("favorites"))
      getUserFavoriteList({
        page: 1,
        pageSize: 999,
        marketplace: data?.marketplaceTarget,
      })(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const handleListSelection = async (userFavorite: UserFavorite) => {
    dispatch(
      removeFavItem({
        userFavoriteId: userFavorite.userFavoriteId,
        asin: data?.asin,
        marketplace: data?.marketplaceTarget,
      }),
    );
    changeCurrentFavoriteList(userFavorite)(dispatch);
    await addFavoriteItem(userFavorite.userFavoriteId, data!.asin, data?.marketplaceTarget!, true)(dispatch);
    toggle();
  };

  const toggle = () => {
    AddToFavoritesModal.close();
  };

  const columns = useMemo<ColumnDef<UserFavorite, any>[]>(
    () => [
      {
        header: t("SearchResults.Dialog.AddToFavorites.TableColumn.Select"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <>
              <div className="align-items-center w-100">
                <div className="form-check form-radio-inline form-radio-success">
                  <Input
                    className="form-check-input"
                    type="radio"
                    name={"radioButton" + row.userFavoriteId}
                    id={"radioButton" + row.userFavoriteId}
                    onChange={() => handleListSelection(row)}
                    checked={currentFavoriteList?.userFavoriteId === row.userFavoriteId}
                  />
                </div>
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "name",
        header: t("SearchResults.Dialog.AddToFavorites.TableColumn.ListName"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return <>{row.name}</>;
        },
      },
      {
        accessorKey: "description",
        header: t("SearchResults.Dialog.AddToFavorites.TableColumn.Description"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          const textClass = row.description ? "" : "text-muted fst-italic";
          const description = row.description ? row.description : t("SearchResults.Dialog.AddToFavorites.NoDescription");
          return <span className={`${textClass} ellipsis-two-lines`}>{description}</span>;
        },
      },
      {
        accessorKey: "marketplace",
        header: t("SearchResults.Dialog.AddToFavorites.TableColumn.Marketplace"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          const marketplaceInfo = ActiveMarketplaces.find((marketplaceInfo) => marketplaceInfo.marketplace === row.marketplace);
          return (
            <>
              {marketplaceInfo && (
                <span>
                  <span className="hstack gap-2">
                    <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                      <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                    </div>
                    {`${marketplaceInfo.countryName} (${marketplaceInfo.marketplace})`}
                  </span>
                </span>
              )}
            </>
          );
        },
      },
      {
        header: t("Actions"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserFavorite;
          return (
            <div className="d-flex">
              <Button
                id={`Edit-${row.userFavoriteId}`}
                className="btn btn-ghost-info px-1 py-0 fs-16"
                onClick={() => CreateOrEditFavoriteListModal.open({listRef, favorite: row, marketplaceTarget: row.marketplace})}
              >
                <i className="ri-pencil-fill"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Edit-${row.userFavoriteId}`}>{t("SearchResults.Dialog.AddToFavorites.Tooltip.Edit")}</DefaultUncontrolledTooltip>

              <Link
                id={`Delete-${row.userFavoriteId}`}
                to=""
                className="btn btn-ghost-danger px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedFavoriteList(row);
                  deleteDialogRef.current?.show();
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Link>
              <DefaultUncontrolledTooltip target={`Delete-${row.userFavoriteId}`}>{t("SearchResults.Dialog.AddToFavorites.Tooltip.Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
    ],
    [t, data], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <Modal backdrop="static" isOpen={true} toggle={toggle} fade={true} centered={true} size="lg">
        <PlaceholderGlow busy={loading.listFavorite || loading.update}>
          <>
            <ModalHeader className="bg-light p-3" toggle={toggle}>
              {t("SearchResults.Dialog.AddToFavorites.Title")}
            </ModalHeader>
            <ModalBody>
              <CreateOrEditFavoriteList />
              {favoriteList?.items && favoriteList.items?.length > 0 && (
                <Row>
                  <Col>
                    <h5>{t("SearchResults.Dialog.AddToFavorites.Subtitle")}</h5>
                  </Col>
                </Row>
              )}
              <Row className="mt-3">
                <Col>
                  <DataTable
                    ref={tableRef}
                    columns={columns}
                    data={favoriteList.items || []}
                    totalDataLength={favoriteList.totalCount}
                    thClass="text-black"
                    busy={loading.listFavorite}
                    renderOnEmpty={() => <>{t("SearchResults.Dialog.AddToFavorites.NoResult", {value: data?.marketplaceTarget})}</>}
                    hovered
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Button type="button" className="btn btn-secondary add-btn mt-2" onClick={() => CreateOrEditFavoriteListModal.open({marketplaceTarget: data?.marketplaceTarget!, listRef: listRef})}>
                    <i className="ri-add-line align-bottom me-1"></i>
                    {t("SearchResults.Dialog.AddToFavorites.Button.NewList")}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <Button type="button" className="btn btn-light" onClick={toggle}>
                  {t("Close")}
                </Button>
              </div>
            </ModalFooter>
          </>
        </PlaceholderGlow>
      </Modal>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("SearchResults.Dialog.AddToFavorites.Dialog.Delete.Description")}
        title={t("SearchResults.Dialog.AddToFavorites.Dialog.Delete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (selectedFavoriteList) {
              await deleteUserFavoriteList(selectedFavoriteList)(dispatch);
            }
          }
          deleteDialogRef.current?.hide();
        }}
      />
    </>
  );
};

export default AddToFavorites;
