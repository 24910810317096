import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserOrder, UserOrderWithShipments} from "models/user_order";

export type OrdersLoadingState = LoadingStates<"list" | "filter" | "updateCost" | "updateShipment">;
export type UserOrderStats = {
  totalSales: number;
  totalProfit: number;
  avgMargin: number;
  avgRoi: number;
  totalOrder: number;
};

export type ShipmentSummary = {
  userShipmentId: string;
  name: string;
  userStoreId: string;
  marketplace: string;
}
export type OrdersState = {
  loading: OrdersLoadingState;
  userOrders: Partial<PagedList<UserOrderWithShipments>>;
  currentUserOrder: UserOrder;
  stats: UserOrderStats;
  shipments: ShipmentSummary[];
  error: ApiError;
};

const initialState: OrdersState = {
  loading: {
    list: false,
    filter: true,
    updateCost: false,
    updateShipment: false,
  },
  userOrders: {items: []},
  currentUserOrder: {} as UserOrder,
  stats: {} as UserOrderStats,
  shipments: [] as ShipmentSummary[],
  error: {} as ApiError,
};

const OrdersSlice = createSlice({
  name: "Orders",
  initialState,
  reducers: {
    setUserOrders(state, action: PayloadAction<PagedList<UserOrderWithShipments>>) {
      state.userOrders = action.payload;
    },
    setUserOrderStats(state, action: PayloadAction<UserOrderStats>) {
      state.stats = action.payload;
    },
    setUserOrderShipments(state, action: PayloadAction<ShipmentSummary[]>) {
      state.shipments = action.payload;
    },
    updateOrder(state, action: PayloadAction<UserOrderWithShipments>) {
      // update item on state.userORders
      state.userOrders.items = state.userOrders.items?.map((item) => {
        if (item.userOrderId === action.payload.userOrderId) {
          return action.payload;
        }
        return item;
      });
    },
    loading(state, action: PayloadAction<[keyof OrdersLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.userOrders = initialState.userOrders;
      state.userOrders = initialState.userOrders;
      state.currentUserOrder = initialState.currentUserOrder;
      state.stats = initialState.stats;
      state.error = initialState.error;
    },
  },
});

export const {setUserOrders, setUserOrderStats, setUserOrderShipments, updateOrder, loading, apiError, reset} = OrdersSlice.actions;

export default OrdersSlice.reducer;
