import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {AdminScanAndSaveListRef} from "..";
import {AdminScanAndSaveQuery} from "api/query";
import {getSelectUserQuery} from "services/admin_service";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedAsyncSelect from "Components/Common/ValidatedAsyncSelect";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import useSelectOptions, {UserSelectOption} from "Components/Hooks/useSelectOptions";
import * as Yup from "yup";

interface FiltersProps {
  listRef: RefObject<AdminScanAndSaveListRef>;
  busy: boolean;
  fields: FieldConfig<AdminScanAndSaveQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {scanAndSaveStatusSelectOptions} = useSelectOptions();

  const onLoad = useCallback(() => {
    validation.handleSubmit();
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  // validation
  const validation = useFormik({
    initialValues: {} as AdminScanAndSaveQuery,
    validationSchema: Yup.object<AdminScanAndSaveQuery>({
      startDate: Yup.date(),
      endDate: Yup.date(),
      statuses: Yup.array<String>().notRequired(),
    }),
    onSubmit: (values: AdminScanAndSaveQuery) => {
      const payload: AdminScanAndSaveQuery = {
        page: values.page,
        pageSize: values.pageSize,
        startDate: values.startDate,
        endDate: values.endDate,
        statuses: values.statuses || undefined,
        userId: values.userId,
        action: "filtering",
      };

      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<AdminScanAndSaveQuery>(props.fields, validation);

  const loadUserOptions = async (inputValue: string): Promise<UserSelectOption[]> => {
    if (inputValue === "") return [];

    const response = await getSelectUserQuery({query: inputValue});
    const data: UserSelectOption[] = await response.data;

    return data.map((item: UserSelectOption) => ({
      label: item.label,
      value: item.value,
      avatar: item.avatar,
      email: item.email,
    }));
  };
  return (
    <PlaceholderGlow busy={props.busy}>
      <Card>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Admin.ScanAndSave.Filters.Title")}</Label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={2} className="mb-3 scan-and-save filters-col">
                  <Label htmlFor="startDate">{t("Admin.ScanAndSave.Filters.StartDate")}</Label>
                  <ValidatedDate field="startDate" validation={validation} value={validation.values.startDate}></ValidatedDate>
                </Col>
                <Col sm={2} className="mb-3 scan-and-save filters-col">
                  <Label htmlFor="endDate">{t("Admin.ScanAndSave.Filters.EndDate")}</Label>
                  <ValidatedDate field="endDate" validation={validation} value={validation.values.endDate}></ValidatedDate>
                </Col>
                <Col sm={2} className="mb-3 scan-and-save filters-col">
                  <Label htmlFor="statusFilter">{t("Admin.ScanAndSave.Filters.User")}</Label>
                  <ValidatedAsyncSelect
                    className="filter-input"
                    loadOptions={loadUserOptions}
                    validation={validation}
                    field="userId"
                    placeholder={t("Admin.ScanAndSave.Filters.Label.SelectPlaceholder")}
                    noOptionsMessage={t("Admin.ScanAndSave.Filters.Label.TypeToFindUser")}
                    loadingMessage={t("Admin.ScanAndSave.Filters.Label.UserSearching")}
                    value={validation.values.userId}
                    errorStyle="solid"
                  />
                </Col>
                <Col sm={2} className="mb-3 scan-and-save filters-col">
                  <Label htmlFor="status">{t("Admin.ScanAndSave.Filters.Status")}</Label>
                  <ValidatedMultiSelect className="filter-input" options={scanAndSaveStatusSelectOptions} validation={validation} field="statuses" value={validation.values.statuses} />
                </Col>
                <Col sm={2}>
                  <label className="form-label">&nbsp;</label>
                  <Button type="submit" color="secondary" className="d-block" disabled={props.busy}>
                    {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("Admin.ScanAndSave.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
