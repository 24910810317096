import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Card, CardBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";

const RestrictionCheck = () => {
  const {t} = useTranslation();
  const [cardClassName, setCardClassName] = useState<string>("");
  const productDetailsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      details: state.ProductDetails.details,
      store: state.ProductDetails.store,
    }),
  );
  const {details, store} = useSelector(productDetailsData);

  useEffect(() => {
    if (store) {
      if (details.restrictionCheck.hasRestriction === true) setCardClassName("bg-danger-subtle");
      else if (details.restrictionCheck.hasRestriction === false) setCardClassName("bg-success-subtle");
      else setCardClassName("bg-warning-subtle");
    } else {
      setCardClassName("bg-warning-subtle");
    }
  }, [store, details]);

  return (
    <Card className={`${cardClassName}`}>
      <CardBody className="d-flex align-items-center justify-content-center">
        {store ? (
          details.restrictionCheck.hasRestriction === true ? (
            <div className="d-flex h-100 align-items-center">
              <div className="d-flex flex-column gap-2 justify-content-center text-center p-3 w-100">
                <h5 dangerouslySetInnerHTML={{__html: t("ProductDetails.RestrictionChecks.Label.RestrictionCheckCompleted", {storeName: store.name})}}></h5>
                {details?.restrictionCheck.restrictionReasonCodes?.split(",").map((reasonCode: string, index: number) => (
                  <div key={`ReasonCode-${index}`}>
                    <span className="badge rounded-pill fs-14 badge bg-danger mx-auto m-1">{t(`RestrictionReasonCodes.${reasonCode}.Name`)}</span>
                  </div>
                ))}

                {details?.restrictionCheck.restrictionReasonCodes?.split(",").includes("APPROVAL_REQUIRED") && (
                  <a
                    className="fw-medium link-secondary"
                    href={`https://sellercentral.amazon.ca/hz/approvalrequest/restrictions/approve?asin=${details.productInfo.asin}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("ProductDetails.RestrictionChecks.Label.RequestApprovalFromSellerCentral", {value: details.productInfo.asin})}
                    <i className="ri-arrow-right-s-line align-bottom"></i>
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className="fs-15 mb-1" dangerouslySetInnerHTML={{__html: t("ProductDetails.RestrictionChecks.Label.RestrictionCheckCompleted", {storeName: store.name})}}></div>
              <div className="text-muted">
                <p className="me-2 mb-1">{t("ProductDetails.RestrictionChecks.Label.CanYouAddThisProduct")}</p>
                {details.restrictionCheck.hasRestriction === null ? <span className="text-muted">{t("NA")}</span> : <span className="fs-12 badge rounded-pill bg-success">{t("Yes")}</span>}
              </div>
            </div>
          )
        ) : (
          <div className="text-center">
            <div className="mb-0 mt-2 text-muted">
              {t("ProductDetails.RestrictionChecks.Label.RestrictionCheckFailed")}
              <Link to="/stores/connect" className="fs-13 fw-medium link-secondary ms-1" target="_blank">
                {t("ProductDetails.RestrictionChecks.Label.ConnectYourStore")}
                <i className="ri-arrow-right-s-line align-bottom"></i>
              </Link>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default RestrictionCheck;
