import {components} from "react-select";
import {Input, Label} from "reactstrap";
import {Option} from "./SelectStyle";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import { UserSelectOption } from "Components/Hooks/useSelectOptions";

export const userOptionContainer = (props: any) => {

  const data: UserSelectOption = props.data;
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center', overflowX: 'hidden' }}>
          {props.isMulti && (
            <div style={{ marginRight: '0.5rem' }}>
              <Input type="checkbox" checked={props.isSelected} onChange={() => null} />
            </div>
          )}
          <div style={{ marginRight: '0.5rem', flexShrink: 0 }}>
            <div className="profile-user position-relative d-inline-block">
              <img 
                src={data.avatar || defaultAvatar} 
                className="rounded-circle avatar-xs img-thumbnail user-profile-image" 
                alt="user-profile" 
                style={{ width: '32px', height: '32px' }} 
              />
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Label htmlFor="name" check>
              <div className="ellipsis-single-line" title={data.label}>
                {data.label}
              </div>
              <div className={`ellipsis-single-line ${props.isFocused ? 'text-white' : 'text-muted'}`} title={data.email}>
                {data.email}
              </div>
            </Label>
          </div>
        </div>
      </components.Option>
    </div>
  );
};


export const userMultiValueContainer = (props: any) => {
  const {selectProps, data} = props;
  const label = data.label;
  const allSelected = selectProps.value as Option[];
  const index = allSelected.findIndex((selected) => selected.label === label);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
  return `${label}${labelSuffix}`;
};
