import React from 'react';

interface CheckListItemProps {
  labelName: string;
  status?: boolean | null;
  hide?: boolean;
}

const CheckListItem = ({ labelName, status, hide }: CheckListItemProps) => {
  const getStatusIcon = () => {
    switch (status) {
      case false:
        return <i className="ri-close-circle-fill fs-18 text-danger"></i>;
      case null:
        return <i className="ri-donut-chart-line fs-18 text-muted"></i>;
      case undefined:
        return <i className="ri-error-warning-fill fs-18 text-warning"></i>;
      default:
        return <i className="ri-checkbox-circle-fill fs-18 text-success"></i>;
    }
  };

  return hide ? null : (
    <li>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-1">{getStatusIcon()}</div>
        <div className="flex-grow-1">
          <h6 className={`mb-0${status === null ? ' text-muted' : ''}`}>
            {labelName}
          </h6>
        </div>
      </div>
    </li>
  );
};

export default CheckListItem;
