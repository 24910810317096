import {getToday, preventScrollUp} from "helpers/utilities";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {CardHeader, Row, Col, Button, Badge} from "reactstrap";
import {RootState} from "slices";
import {UserSearchProduct} from "models/user_search_product";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {SummaryCard} from "./Components/SummaryCard";
import {addFavoriteItem, markSearchProductReviewed, removeFavoriteItem} from "slices/search-result/thunk";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {DecisionReasonsModal} from "../Modals/DecisionReasons";
import {AddToFavoritesModal} from "../Modals/AddToFavorites";
import {IneligibilityReasonListModal} from "../Modals/IneligibilityReasonList";
import {CheckListModal} from "../Modals/CheckList";
import {DecisionReasonsLink} from "./Components/DecisionReasonsLink";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import Rating from "react-rating";
import moment from "moment";
import logoDarkTransparent from "assets/images/logo-dark-transparent.png";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import classNames from "classnames";
import DisplayDate from "Components/Common/DisplayDate";
import DisplayNumber from "Components/Common/DisplayNumber";
import _ from "lodash";
import {SellThisModal} from "Components/Common/SellThis";
import {BusinessModelBadge} from "Components/Common/Badges/BusinessModelBadge";
import {NotesModal} from "../Modals/Notes";
import {AmazonMarketplace} from "helpers/marketplace_helper";

const selectSearchResultData = createSelector([(state: RootState) => state.SearchResult], (searchResult) => ({
  loading: searchResult.loading,
  search: searchResult.result.search,
  currentFavoriteList: searchResult.currentFavoriteList,
  showCheckList: searchResult.result.showCheckList,
  notes: searchResult.result.notes,
}));

interface SummaryProps {
  item: UserSearchProduct;
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
}
const Summary = ({item, sourceMarketplace, destinationMarketplace}: SummaryProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {userProfile} = useProfile();
  const {favoriteId} = useParams();
  const [addingOrRemovingFromFavorites, setAddingOrRemovingFromFavorites] = useState<boolean>(false);
  const [markReviewLoading, setMarkReviewLoading] = useState<boolean>(false);
  const [isInUserShipment, setIsInUserShipment] = useState<boolean>(item.inUserShipment || false);
  const [decision, setDecision] = useState<string | undefined>();
  const [decisionReasons, setDecisionReasons] = useState<string | undefined>();

  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      shipmentInfo: state.Common.shipmentInfo,
      productDetailsLoading: state.ProductDetails.loading,
    }),
  );
  const {shipmentInfo, productDetailsLoading} = useSelector(commonData);
  const {showCheckList, currentFavoriteList, search, notes} = useSelector(selectSearchResultData);

  const itemNote = useMemo(() => notes?.find((note) => note.asin === item.asin), [notes, item.asin]);

  const handleAddToFavoriteList = async () => {
    preventScrollUp();
    const {asin, userFavoriteId, marketplaceTarget} = item;
    const favoriteListId = favoriteId || currentFavoriteList?.userFavoriteId;

    if (userFavoriteId) {
      setAddingOrRemovingFromFavorites(true);
      await removeFavoriteItem(userFavoriteId, asin, marketplaceTarget, true)(dispatch);
      setAddingOrRemovingFromFavorites(false);
    } else if (favoriteListId) {
      setAddingOrRemovingFromFavorites(true);
      await addFavoriteItem(favoriteListId, asin, marketplaceTarget, true)(dispatch);
      setAddingOrRemovingFromFavorites(false);
    } else {
      AddToFavoritesModal.open({asin, marketplaceTarget});
    }
  };

  const handleNotes = () => {
    NotesModal.open({asin: item.asin, userSearchId: item.userSearchId, marketplaceTarget: item.marketplaceTarget, note: itemNote});
  };

  const handleMarkAsReviewed = async () => {
    setMarkReviewLoading(true);
    if (item.isReviewed === true) {
      await markSearchProductReviewed({userSearchProductId: item.userSearchProductId, isReviewed: false})(dispatch);
    } else {
      await markSearchProductReviewed({userSearchProductId: item.userSearchProductId, isReviewed: true})(dispatch);
    }
    setMarkReviewLoading(false);
  };

  const onShipmentStatusChange = useCallback(
    () => {
      if (!_.isEmpty(shipmentInfo)) {
        let isExistOnAnyShipment = shipmentInfo?.asinList?.find((x) => x.asin === item.asin);
        if (isExistOnAnyShipment) {
          setIsInUserShipment(true);
        } else {
          setIsInUserShipment(false);
        }
      }
    },
    [shipmentInfo], // eslint-disable-line
  );

  const onLoad = useCallback(() => {
    if (item.summary) {
      if (item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING) {
        setDecision(item.summary.decisionDS);
        setDecisionReasons(item.summary.decisionReasonsDS ? item.summary.decisionReasonsDS : undefined);
      } else {
        setDecision(item.summary.decision);
        setDecisionReasons(item.summary.decisionReasons ? item.summary.decisionReasonsDS : undefined);
      }
    }
  }, [item.summary]); // eslint-disable-line

  useEffect(() => {
    onShipmentStatusChange();
  }, [onShipmentStatusChange]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <CardHeader className="common-css padding-bottom-0 border-bottom-0">
        <Row>
          <Col xl={1} lg={2} sm={3} className="mx-auto mb-4">
            {item.summary?.imageBig && (
              <div className="pimage-container">
                <Link to={`/dp/${item.asin}/${item.userSearchId}`} target="_blank">
                  <img src={`https://m.media-amazon.com/images/I/${item.summary.imageBig}`} alt="" className="rounded w-100" />
                </Link>
              </div>
            )}

            <div className="w-auto mt-2 text-center">
              <div className="d-flex gap-1 align-items-center justify-content-center">
                {item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                  <>
                    <Button
                      className={"rounded-pill p-0 " + (isInUserShipment ? "btn-success" : "btn-secondary")}
                      id={`SellThisButton-${item.userSearchProductId}`}
                      style={{width: "80%"}}
                      onClick={() => {
                        if (showCheckList) {
                          CheckListModal.open({asin: item.asin, amazonBusinessModel: item.amazonBusinessModel, checkList: item.checkList, marketplaceTarget: item.marketplaceTarget});
                        } else {
                          SellThisModal.open({asin: item.asin, marketplace: item.marketplaceTarget});
                        }
                      }}
                    >
                      <img src={logoDarkTransparent} alt="" width="100%" className="px-2 py-1" />
                    </Button>
                    <DefaultUncontrolledTooltip target={`SellThisButton-${item.userSearchProductId}`}>
                      {isInUserShipment ? t("SearchResults.SellThisButtonReverse") : t("SearchResults.SellThisButton")}
                    </DefaultUncontrolledTooltip>
                  </>
                )}

                <Link
                  id={`ProductDetailsButton-${item.userSearchProductId}`}
                  to={`/dp/${item.asin}/${item.userSearchId}`}
                  target="_blank"
                  className="btn btn-soft-secondary px-1 py-0 rounded-pill btn btn-secondary d-flex align-items-center"
                >
                  {item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING && <span className="fs-12 px-1">{t("Details")}</span>} <i className="ri-arrow-right-up-line fs-14"></i>
                </Link>
                <DefaultUncontrolledTooltip target={`ProductDetailsButton-${item.userSearchProductId}`}>{t("SearchResults.ProductDetailsButton")}</DefaultUncontrolledTooltip>
              </div>
            </div>
          </Col>

          <Col xl={11} lg={10} sm={9}>
            <div className="mt-xl-0">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Row>
                    <Col className="col-md">
                      <h4 className="ellipsis-single-line mb-0">{item.summary?.title}</h4>
                      <div className="text-muted mb-2 ">
                        {item.summary?.categoryPath ? item.summary.categoryPath.replaceAll("|", " > ") : item.summary?.categoryName ? item.summary.categoryName : t("Category") + ": " + t("NA")}
                      </div>
                    </Col>
                    <Col className="col-auto pe-2">
                      <Row>
                        <div className="hstack gap-1 flex-wrap justify-content-end">
                          <button
                            id={`ReAnalyzeProductButton-${item.userSearchProductId}`}
                            type="button"
                            className="btn p-0 px-1 btn-ghost-secondary"
                            onClick={() => {
                              ReAnalyzeModal.open({
                                asin: item.asin,
                                store: search?.userStoreId ?? item.marketplaceTarget,
                                businessModel: item.amazonBusinessModel,
                                currency: item.currencyCodeTarget,
                                price: item.purchasePriceSource?.toFixed(2).replace(",", ".") || "",
                                type: AnalyzeType.SEARCH_PRODUCT,
                                searchName: item.asin,
                              });
                            }}
                            disabled={productDetailsLoading.save}
                          >
                            <i className="mdi mdi-chart-timeline-variant-shimmer fs-20 text-info"></i>
                          </button>
                          <DefaultUncontrolledTooltip autohide={true} target={`ReAnalyzeProductButton-${item.userSearchProductId}`}>
                            {t("SearchResults.Summary.Tooltip.ReanalyzeProduct")}
                          </DefaultUncontrolledTooltip>

                          <button type="button" id={`NotesButton-${item.userSearchProductId}`} className={`p-0 px-1 btn ${itemNote ? "btn-light" : "btn-ghost-primary"}`} onClick={handleNotes}>
                            <i className={`mdi mdi-note-edit-outline fs-20 ${itemNote && "text-info"}`}></i>
                          </button>
                          <DefaultUncontrolledTooltip autohide={true} target={`NotesButton-${item.userSearchProductId}`}>
                            {itemNote ? t("SearchResults.Summary.Tooltip.YouHaveNote") : t("SearchResults.Summary.Tooltip.CreateNote")}
                          </DefaultUncontrolledTooltip>

                          <button
                            type="button"
                            id={`AddToFavoriteButton-${item.userSearchProductId}`}
                            className={`p-0 px-1 btn ${item.userFavoriteId ? "btn-light" : "btn-ghost-dark"}`}
                            onClick={handleAddToFavoriteList}
                            disabled={addingOrRemovingFromFavorites}
                          >
                            <i className={`ri-star-fill fs-20 ${item.userFavoriteId && "text-warning"}`}></i>
                          </button>
                          <DefaultUncontrolledTooltip autohide={true} target={`AddToFavoriteButton-${item.userSearchProductId}`}>
                            {item.userFavoriteId ? t("SearchResults.Summary.Tooltip.RemoveFromFavorites") : t("SearchResults.Summary.Tooltip.AddToFavorites")}
                          </DefaultUncontrolledTooltip>

                          <button
                            type="button"
                            id={`MarkReviewButton-${item.userSearchProductId}`}
                            className={`p-0 px-1 btn  ${item.isReviewed ? "btn-light" : "btn-ghost-dark"}`}
                            onClick={handleMarkAsReviewed}
                            disabled={markReviewLoading}
                          >
                            <i className={`ri-check-double-fill fs-20  ${item.isReviewed ? "text-secondary" : "text-primary-emphasis"}`}></i>
                          </button>
                          <DefaultUncontrolledTooltip autohide={true} target={`MarkReviewButton-${item.userSearchProductId}`}>
                            {item.isReviewed ? t("SearchResults.Summary.Tooltip.NotReviewed") : t("SearchResults.Summary.Tooltip.IsReviewed")}
                          </DefaultUncontrolledTooltip>
                        </div>
                      </Row>
                      {/* Only show while Favorite page is loaded */
                      favoriteId && (
                        <Row>
                          <Col className="mt-2 d-flex justify-content-end">
                            <BusinessModelBadge key={item.userSearchProductId} model={item.amazonBusinessModel} size="sm" />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>

                  <div className="hstack column-gap-4 row-gap-1 flex-wrap">
                    <CopyAsinWidget asin={item.asin} index={`CopyAsin-${item.userSearchProductId}`} bold={true} />

                    <div className="d-flex align-items-center">
                      {item.asin ? (
                        <>
                          <DomainToFlag marketplace={"us"} />
                          <Link to={`https://www.amazon.com/dp/${item.asin}?th=1&psc=1`} target="_blank" className="link-secondary link-offset-2 text-decoration-underline common-css">
                            .com
                            <i className="ri-arrow-right-up-line"></i>
                          </Link>
                          &nbsp;&nbsp;
                          {item.domainTarget && sourceMarketplace?.currency !== destinationMarketplace?.currency && (
                            <>
                              <DomainToFlag marketplace={item.marketplaceTarget} />
                              <Link
                                to={`https://www.amazon.${item.domainTarget}/dp/${item.asin}?th=1&psc=1`}
                                target="_blank"
                                className="link-secondary link-offset-2 text-decoration-underline common-css"
                              >
                                .{item.domainTarget}
                                <i className="ri-arrow-right-up-line"></i>
                              </Link>
                            </>
                          )}
                        </>
                      ) : (
                        t("NA")
                      )}
                    </div>

                    {item.summary?.reviewCount && (
                      <>
                        <div className="text-muted">
                          <Rating
                            initialRating={item.summary?.reviewRating ?? 0}
                            fractions={2}
                            emptySymbol="mdi mdi-star-outline fs-16 text-muted "
                            fullSymbol="mdi mdi-star fs-16 text-warning "
                            readonly={true}
                          />{" "}
                          <DisplayNumber
                            value={item.summary?.reviewCount}
                            className="text-muted"
                            prefix="("
                            suffix={` ${item.summary.reviewCount > 0 ? t("SearchResults.Summary.ProductReviewPlural") : t("SearchResults.Summary.ProductReview")})`}
                            renderOnEmpty={<></>}
                          />
                        </div>
                      </>
                    )}

                    {item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                      <div className="d-flex align-items-center">
                        {item.summary?.isEligible != null ? (
                          <>
                            <i className={item.summary.isEligible ? "mdi mdi-check fs-20" : "bx bx-x fs-20"}></i>
                            <span className={"fw-bold " + (item.summary.isEligible ? "text-secondary" : "text-danger")}>
                              {item.summary.isEligible ? t("SearchResults.Summary.EligibleToSell") : t("SearchResults.Summary.NotEligibleToSell")}
                            </span>
                            {item.summary?.ineligibilityReasonList && !item.summary.isEligible && (
                              <>
                                <Button
                                  color="link"
                                  id={`PopoverIneligibilityReasonList-${item.userSearchProductId}`}
                                  className="p-0 ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                                  onClick={async () => IneligibilityReasonListModal.open({asin: item.asin, ineligibilityReasonList: item.summary.ineligibilityReasonList})}
                                >
                                  <DecisionReasonsLink items={item.summary?.ineligibilityReasonList?.split(",") ?? []} />
                                </Button>
                                <DefaultUncontrolledTooltip target={`PopoverIneligibilityReasonList-${item.userSearchProductId}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                              </>
                            )}
                          </>
                        ) : (
                          <span className="text-muted">{t("SearchResults.Summary.Eligibility") + t("NA")}</span>
                        )}
                      </div>
                    )}

                    <div className="d-flex align-items-center">
                      {getToday().diff(moment.tz(item.statusDate, userProfile?.timezone!), "hours") > 48 ? (
                        <>
                          <i
                            className={`ri-error-warning-fill fs-18 ${getToday().diff(moment.tz(item.statusDate, userProfile?.timezone!), "days") > 7 ? "text-danger" : "text-warning"} me-1`}
                            id={`AnalysisWarningMessage-${item.userSearchProductId}`}
                          ></i>
                          <DefaultUncontrolledTooltip target={`AnalysisWarningMessage-${item.userSearchProductId}`}>
                            {t("SearchResults.Summary.AnalysisWarningMessage", {hours: 48})}
                          </DefaultUncontrolledTooltip>
                        </>
                      ) : (
                        <i className="bx bx-calendar-check fs-18 me-1"></i>
                      )}

                      <DisplayDate
                        id={`DisplayDateAnalysisStatusDate-${item.userSearchProductId}`}
                        className="text-muted"
                        value={item.statusDate}
                        tz={userProfile?.timezone}
                        format="D MMM YYYY HH:mm"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 hstack d-flex flex-wrap justify-content-start gap-3">
                <SummaryCard
                  icon="mdi mdi-chart-timeline-variant-shimmer"
                  label={t("SearchResults.Summary.Decision")}
                  content={
                    <div className="d-flex align-items-center">
                      {!_.isNil(decision) ? (
                        <Badge color={decision === "Sellable" ? "success" : decision === "Recheck" ? "warning" : "danger"} className="rounded-pill fs-11">
                          {t(decision)}
                          {decision === "Sellable" && <i className="mdi mdi-check ms-1"></i>}
                          {}
                        </Badge>
                      ) : (
                        " " + t("NA")
                      )}
                      {!_.isNil(decisionReasons) ? (
                        <>
                          <Button
                            color="link"
                            id={`PopoverDecisionReasons-${item.userSearchProductId}`}
                            className="p-0 ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                            onClick={() => {
                              DecisionReasonsModal.open({
                                asin: item.asin,
                                variationAnalysis: item.variationAnalysis,
                                amazonBusinessModel: item.amazonBusinessModel,
                                checkList: item.checkList,
                                summary: item.summary,
                              });
                            }}
                          >
                            {item.summary?.decision === "Sellable" ? (
                              <>
                                <i className="mdi mdi-alert align-middle fs-18 text-warning"></i>
                                <DefaultUncontrolledTooltip target={`PopoverDecisionReasons-${item.userSearchProductId}`}>
                                  {t("SearchResults.Summary.DecisionSellableWarning")}
                                </DefaultUncontrolledTooltip>
                              </>
                            ) : (
                              <>
                                <DecisionReasonsLink items={decisionReasons.split(",")} isWarning={decision === "Sellable"} />
                                <DefaultUncontrolledTooltip target={`PopoverDecisionReasons-${item.userSearchProductId}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                              </>
                            )}
                          </Button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                />
                <SummaryCard
                  icon="mdi mdi-finance"
                  label={t("SearchResults.Summary.SaleCount.Title")}
                  content={
                    <div className="d-flex align-items-center">
                      {item.saleAnalysis?.saleCount != null ? (
                        <>
                          <span>{item.saleAnalysis.saleCount.toLocaleString()}</span>
                          <small className="fs-13 text-muted">
                            <i className="mdi mdi-slash-forward"></i>
                            {t("month")}
                          </small>
                        </>
                      ) : (
                        t("NA")
                      )}{" "}
                      {item.saleAnalysis?.hasAnomalies && (
                        <>
                          <i id={`PopoverHasAnomaliesSummary-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert fs-20 text-warning"></i>
                          <DefaultUncontrolledTooltip target={`PopoverHasAnomaliesSummary-${item.userSearchProductId}`}>{t("SearchResults.Summary.SaleCount.Anomalies")}</DefaultUncontrolledTooltip>
                        </>
                      )}
                      {item.saleAnalysis?.precision && !item.saleAnalysis.hasAnomalies && (
                        <>
                          <i
                            id={`PopoverPrecisionSummary-${item.userSearchProductId}`}
                            className={`ms-1 mdi ${
                              item.saleAnalysis.precision === "VeryClose"
                                ? "mdi-check-circle text-secondary"
                                : item.saleAnalysis.precision === "Medium"
                                ? "mdi-progress-check text-secondary fs-18"
                                : item.saleAnalysis.precision === "Prediction"
                                ? "mdi-progress-clock text-danger fs-18"
                                : ""
                            }`}
                          ></i>
                          <DefaultUncontrolledTooltip target={`PopoverPrecisionSummary-${item.userSearchProductId}`}>
                            [{t("SearchResults.Summary.SaleCount.Precision")}: {item.saleAnalysis.precision}] {t(`SearchResults.Summary.SaleCount.Precisions.${item.saleAnalysis.precision}`)}
                          </DefaultUncontrolledTooltip>
                        </>
                      )}
                    </div>
                  }
                />

                <SummaryCard
                  icon="mdi mdi-currency-usd"
                  label={t("SearchResults.Summary.ProfitAndROI")}
                  content={
                    item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING ? (
                      <>
                        <DisplayPrice
                          source={item.currencyCodeTarget}
                          className={classNames({"text-danger": item.profitAnalysis.profitDS != null && item.profitAnalysis.profitDS < 0}, "float-start")}
                          value={item.profitAnalysis.profitDS}
                          decimals={2}
                          notation="abbreviated"
                          renderOnEmpty={<span>{t("NA")}</span>}
                        />
                        <DisplayNumber
                          value={item.profitAnalysis.roids}
                          className={`badge rounded-pill fs-15 ms-2 ${
                            item.profitAnalysis.roids != null && item.profitAnalysis.roids < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success"
                          }`}
                          suffix=" %"
                          decimals={2}
                          renderOnEmpty={<></>}
                        />
                      </>
                    ) : (
                      <>
                        <DisplayPrice
                          className={classNames({"text-danger": item.profitAnalysis.profit != null && item.profitAnalysis.profit < 0 && "text-danger"}, "float-start")}
                          source={item.currencyCodeTarget}
                          value={item.profitAnalysis.profit}
                          decimals={2}
                          notation="abbreviated"
                          renderOnEmpty={<span>{t("NA")}</span>}
                        />
                        <DisplayNumber
                          value={item.profitAnalysis.roi}
                          className={`badge rounded-pill fs-15 ms-2 ${
                            item.profitAnalysis.roi != null && item.profitAnalysis.roi < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success"
                          }`}
                          suffix=" %"
                          decimals={2}
                          renderOnEmpty={<></>}
                        />
                      </>
                    )
                  }
                />
                <SummaryCard
                  icon="mdi mdi-layers-triple-outline"
                  label={t("SearchResults.Summary.Variation")}
                  content={
                    <>
                      <DisplayNumber
                        value={item.variationAnalysis?.variationCount}
                        className={
                          item.variationAnalysis?.variationCount != null
                            ? item.variationAnalysis.variationCount < 2
                              ? "badge rounded-pill bg-success fs-14 common-css"
                              : item.variationAnalysis.variationCount < 5
                              ? "badge rounded-pill bg-warning fs-14 common-css"
                              : "badge rounded-pill bg-danger fs-14 common-css"
                            : ""
                        }
                        renderOnEmpty={<>{t("NA")}</>}
                      />
                    </>
                  }
                />
                <SummaryCard
                  icon={
                    item.competitorAnalysis.competitionLevel === "Weak"
                      ? "mdi mdi-speedometer-slow text-success"
                      : item.competitorAnalysis.competitionLevel === "Medium"
                      ? "mdi mdi-speedometer-medium text-warning"
                      : "mdi mdi-speedometer text-danger"
                  }
                  label={t("SearchResults.Summary.Competition")}
                  content={
                    <>
                      {item.competitorAnalysis?.competitionLevel != null ? (
                        <Badge
                          color={item.competitorAnalysis.competitionLevel === "Weak" ? "success" : item.competitorAnalysis.competitionLevel === "Medium" ? "warning" : "danger"}
                          className="rounded-pill fs-14"
                        >
                          {t(item.competitorAnalysis.competitionLevel)}
                          {item.competitorAnalysis.competitionLevel === "Weak" && <i className="mdi mdi-check ms-1"></i>}
                        </Badge>
                      ) : (
                        t("NA")
                      )}
                    </>
                  }
                />
                <SummaryCard
                  icon="mdi mdi-trophy-outline text-secondary"
                  label={`BSR ${item.domainTarget ? " - " + item.domainTarget.replace("com.", "").toUpperCase() : ""}`}
                  content={<>{item.summary?.bsr ? item.summary.bsr.toLocaleString() : t("NA")}</>}
                />
              </div>
            </div>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
};

export default Summary;
