import React, {useEffect} from "react";
import {Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {getSystemMonitoring} from "slices/admin/systemMonitoring/thunk";
import BreadCrumb from "Components/Common/BreadCrumb";
import ServiceDetails from "./Modals/ServiceDetails";
import SearchQueues from "./_SearchQueues";
import ScanAndSaveQueues from "./_ScanAndSaveQueues";
import ContinuousServices from "./_ContinousServices";
import AnalyzeServices from "./_AnalyzeServices";
import Others from "./_Others";
import TokenStatistics from "./_TokenStatistics";
import MarketplaceProductStatistics from "./_MarketplaceProductStatistics";
import OrchServiceDistribution from "./_OrchServiceDistribution";
import AddProduct from "./Modals/AddProduct";

interface AdminSystemMonitoringProps {
  details?: boolean;
  addProduct?: boolean;
}
const AdminSystemMonitoring = (props: AdminSystemMonitoringProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getSystemMonitoring()(dispatch);
  }, []); //eslint-disable-line

  document.title = t("PageTitles.AdminSystemMonitoring");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Admin.SystemMonitoring.Title")} menus={[{label: t("Admin.SystemMonitoring.Title")}]} />
          <ServiceDetails isOpen={props.details || false} />
          <AddProduct isOpen={props.addProduct || false} />
          <Row className="g-4">
            <Col xl={7} className="same-height">
              <SearchQueues />
            </Col>

            <Col xl={5} className="same-height">
              <ScanAndSaveQueues />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-12">
              <h6 className="fs-14 text-uppercase fw-semibold">
                {t("Admin.SystemMonitoring.Section.Title.ContinuousServices")} <small className="badge rounded-pill bg-success align-bottom ms-1">8</small>
              </h6>
            </Col>
          </Row>
          <Row className="g-4">
            <ContinuousServices />
          </Row>

          <Row className="mt-4">
            <Col className="col-12">
              <h6 className="fs-14 text-uppercase fw-semibold">
                {t("Admin.SystemMonitoring.Section.Title.AnalyzeServices")} <small className="badge rounded-pill bg-success align-bottom ms-1">3</small>
              </h6>
            </Col>
          </Row>
          <Row className="g-4">
            <AnalyzeServices />
          </Row>

          <Row className="mt-4">
            <Col className="col-12">
              <h6 className="fs-14 text-uppercase fw-semibold">{t("Others")}</h6>
            </Col>
          </Row>
          <Row className="g-4">
            <Col xxl={3} xl={4} lg={6} sm={12}>
              <Others />
            </Col>
            <Col xxl={3} xl={4} lg={6} sm={12}>
              <TokenStatistics />
            </Col>
            <Col xxl={3} xl={4} lg={6} sm={12}>
              <MarketplaceProductStatistics />
            </Col>

            <Col xxl={3} xl={4} lg={6} sm={12}>
              <OrchServiceDistribution />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminSystemMonitoring;
