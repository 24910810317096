import {FormikValidation} from "helpers/types";
import {CSSProperties} from "react";
import {FormFeedback, InputGroup, InputGroupText} from "reactstrap";
import {SelectOptionsType} from "../Hooks/useSelectOptions";
import TextInput from "./TextInput";
import _ from "lodash";

export type ValidatedInputProps<T> = {
  validation: FormikValidation<T>;
  field: keyof T;
  type?: "text" | "email" | "url" | "textarea" | "password" | "number";
  id?: string;
  disabled?: boolean;
  className?: string;
  icon?: string;
  disableValidationUI?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  mask?: string;
  upperFirst?: boolean;
  options?: SelectOptionsType[];
  maxLength?: number;
  size?: "sm" | "lg";
  tabIndex?: number;
};

const ValidatedInput = <T,>({field, validation, disableValidationUI, disabled, upperFirst, type, options, icon, ...rest}: ValidatedInputProps<T>) => {
  const fieldName = field.toString();
  const {error, touched, value} = validation.getFieldMeta(fieldName);

  return (
    <>
      <InputGroup>
      {icon && (
          <InputGroupText>
            <i className={icon}></i>
          </InputGroupText>
        )}
        <TextInput
          name={fieldName}
          invalid={touched && error ? true : false}
          isValid={disableValidationUI ? undefined : touched ? !error : undefined}
          onChange={(val: any) => {
            const value = upperFirst ? _.upperFirst(val) : val;
            validation.setFieldValue(fieldName, value);
          }}
          onBlur={validation.handleBlur}
          value={value}
          disabled={disabled}
          maxLength={rest.maxLength}
          type={type}
          size={rest.size || ""}
          tabIndex={rest.tabIndex}
          className={rest.className}
          {...rest}
        />
      </InputGroup>
      {touched && error ? (
        <FormFeedback type="invalid">
          <div>{error}</div>
        </FormFeedback>
      ) : null}
    </>
  );
};

export default ValidatedInput;
