import {generateError} from "helpers/utilities";
import {ShipmentSummary, UserOrderStats, apiError, loading, reset, setUserOrderShipments, setUserOrderStats, setUserOrders} from "./reducer";
import {ResponseModel} from "models/response_model";
import {UserOrderWithShipments} from "models/user_order";
import {postGetUserOrders} from "services/store_service";
import {PagedList} from "helpers/types";
import {FilterOrderQuery} from "api/query";

export const getUserOrders = (query: FilterOrderQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserOrders(query);
    const list: PagedList<UserOrderWithShipments> = result.data.pagedList;
    const stats: UserOrderStats = result.data.stats;
    const shipmentSelectOptions: ShipmentSummary[] = result.data.shipments;
    dispatch(setUserOrders(list));
    dispatch(setUserOrderStats(stats));
    dispatch(setUserOrderShipments(shipmentSelectOptions));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const resetOrderState = () => async (dispatch: any) => {
  dispatch(reset());
};
