import {ChangeEvent, useEffect, useRef, useState} from "react";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import TimezoneSelect, { ITimezoneOption, type ITimezone, allTimezones } from 'react-timezone-select'
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {setLoading, updateGeneralInformation, uploadAvatar} from "slices/account/thunk";
import {useNavigate} from "react-router-dom";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/useProfile";
import {IUpdateGeneralInformationDto} from "models/application_user";
import {updateDemoModeInfoLocalData} from "helpers/local_storage";
import {renderFailedToast} from "helpers/utilities";
import BusyOverlay from "Components/Common/BusyOverlay";
import PhoneInput from "react-phone-input-2";
import defaultAvatar from "../../../assets/images/user-dummy-img.jpg";
import i18n from "i18n";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import Checkbox from "Components/Common/Checkbox";
import ValidatedDate from "Components/Common/ValidatedDate";
import "react-phone-input-2/lib/high-res.css";
import * as Yup from "yup";

const ProfileInformation = () => {
  const {t} = useTranslation();
  const {userProfile, isAdmin} = useProfile();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>("")
  const avatarInputRef =useRef<HTMLInputElement | null>(null);
  const accountPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loggedUser: state.Login.user,
      demoMode: state.Login.demoMode,
      loading: state.Account.loading,
    }),
  );

  // Inside your component
  const {loading, loggedUser,demoMode} = useSelector(accountPageData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: userProfile!.firstName,
      lastName: userProfile!.lastName,
      phone: userProfile!.phone,
      timezone: userProfile!.timezone,
      isDemoModeActive: demoMode?.active,
      demoModeDate: demoMode?.date
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("Account.ProfileInformation.Validation.FirstName")),
      lastName: Yup.string().required(t("Account.ProfileInformation.Validation.LastName")),
      phone: Yup.string()
        .required(t("Account.ProfileInformation.Validation.LastName"))
        .min(6, t("Account.ProfileInformation.Validation.Phone")),
      timezone: Yup.string(),
      isDemoModeActive: Yup.boolean(),
      demoModeDate: Yup.date().when("isDemoModeActive", ([isDemoModeActive], schema) => {
        if (isDemoModeActive) return schema.required();
        else return schema.notRequired();
      })
    }),
    onSubmit: (values) => {
      const parameters : IUpdateGeneralInformationDto = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        timezone: values.timezone,
      }
      updateGeneralInformation(parameters)(dispatch);
      if(isAdmin() && values.isDemoModeActive !== undefined && values.demoModeDate!==undefined) {
        updateDemoModeInfoLocalData(values.isDemoModeActive, values.demoModeDate)
        window.location.href="/"
      }
      
    },
  });
  useEffect(() => {
    if(loggedUser)
    {
      setSelectedTimezone(loggedUser.timezone)
    }
  }, [loggedUser])

  
  const resizeImage = (file: File, maxHeight: number): Promise<File> => {
    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target?.result) {
          img.src = event.target.result as string;
        }
      };

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = img.width;
        let height = img.height;

        // Yükseklik kontrolü
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }

        if (ctx) {
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(resizedFile);
            }
          }, file.type);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const handleOnAvatarChange = async (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLoading("upload", true));
    const file = event.target.files?.[0];
  
    if (file) {
      const maxFileSize = 3 * 1024 * 1024; // 3 MB
      if (file.size > maxFileSize) {
        if (avatarInputRef.current) 
          avatarInputRef.current.value = ""; // Değeri sıfırla
        renderFailedToast(t("Account.ProfileInformation.Error.MaxFileSize"));
        dispatch(setLoading("upload", false));
        return; 
      } else {
        const resizedImage = await resizeImage(file, 400);
        const uploadPromise = uploadAvatar(resizedImage)(dispatch);
        uploadPromise.then((success) => {
          if (success) {
            navigate("/account/profile");
            if (avatarInputRef.current) 
              avatarInputRef.current.value = ""; // Yükleme sonrası sıfırlama
          }
        }).catch(() => {
          avatarInputRef.current!.value = ""; 
        });
      }
    } else {
      if (avatarInputRef.current) 
        avatarInputRef.current.value = "";
    }
  };
  
  document.title = t("PageTitles.ProfileInformation");
  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/profile",
    },
    {
      label: t("Account.ProfileInformation.Title"),
      url: "",
    },
  ];
  return (
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
          <ProfileLayout hasAnyChanges={validation.dirty} navigateTo="">
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 align-items-center d-flex">
                  <i className="mdi mdi-account-outline fs-18 me-1"></i>
                  {t("Account.ProfileInformation.Title")}
                </h4>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className="needs-validation"
                  action="#"
                >
                 <Row className="d-flex align-items-center">
                    <Col xs="auto">
                      <div className="text-center">
                        <div className="profile-user position-relative d-inline-block mx-auto ">
                          <BusyOverlay busy={loading.upload} backgroundColor="white" spinnerColor="dark">
                            <img src={userProfile?.avatar || defaultAvatar} className="rounded-circle avatar-lg img-thumbnail user-profile-image" alt="user-profile" />
                          </BusyOverlay>
                          <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <Input innerRef={avatarInputRef} id="profile-img-file-input" type="file" className="profile-img-file-input" accept="image/*" onChange={handleOnAvatarChange} />
                            <Label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                              <span className="avatar-title rounded-circle bg-light text-body">
                                <i className="ri-camera-fill"></i>
                              </span>
                            </Label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <h5 className="fs-16 mb-1">{`${userProfile?.firstName} ${userProfile?.lastName}`}</h5>
                      <p className="text-muted mb-0">{`${userProfile?.email}`}</p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="firstName" className="form-label">
                          {t("Account.ProfileInformation.FirstName")} <span className="text-danger">*</span>
                        </Label>
                        <ValidatedInput validation={validation} field="firstName" maxLength={50} placeholder={t("Account.ProfileInformation.FirstNamePlaceholder")} upperFirst disableValidationUI/>
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="lastName" className="form-label">
                          {t("Account.ProfileInformation.LastName")} <span className="text-danger">*</span>
                        </Label>
                        <ValidatedInput validation={validation} field="lastName" maxLength={50} placeholder={t("Account.ProfileInformation.LastNamePlaceholder")} upperFirst disableValidationUI/>
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="phone" className="form-label ">
                          {t("Account.ProfileInformation.Phone")} <span className="text-danger">*</span>
                        </Label>
                        <PhoneInput
                          searchStyle={{border: "1px solid #ced4da", borderRadius: "0.25rem", height: "38px", marginBottom: "5px", width: "calc(100% - 30px)"}}
                          enableSearch
                          inputClass={`w-100`}
                          country={i18n.language === "en" ? "us" : i18n.language}
                          onChange={(value) => validation.setFieldValue("phone", value)}
                          onBlur={() => validation.setFieldTouched("phone", true)}
                          value={validation.values.phone || ""}
                          buttonStyle={{background:"var(--vz-input-bg-custom)", border: "var(--vz-border-width) var(--vz-border-style) var(--vz-border-color) !important"}}
                          inputStyle={{background:"var(--vz-input-bg-custom)", borderColor: validation.touched.phone && validation.errors.phone ? "red" : "var(--vz-border-color)"}}
                        />

                        {validation.touched.phone && validation.errors.phone ? <div className="mt-1 text-danger fs-11">{t(validation.errors.phone)}</div> : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="phone" className="form-label ">
                          {t("Account.ProfileInformation.Region")} <span className="text-danger">*</span>
                        </Label>
                          <TimezoneSelect
                            value={selectedTimezone}
                            onChange={(timezone: ITimezone) => {
                              setSelectedTimezone(timezone)
                              const option = timezone as ITimezoneOption
                               
                              validation.setFieldValue("timezone", option.value)
                            }}
                            timezones={{...allTimezones}}
                          />
                      </div>
                    </Col>
                    {
                      isAdmin() && (
                        <>
                          <Col xs={12} className="mb-3">
                            <ValidationWrapper validation={validation} field="isDemoModeActive">
                              <Checkbox label={t("Account.ProfileInformation.DemoMode")} />
                            </ValidationWrapper>
                          </Col>
                          {
                            validation.values.isDemoModeActive && (
                              <Col xs={12}>
                              <ValidatedDate field="demoModeDate" validation={validation} value={validation.values.demoModeDate}></ValidatedDate>
                              </Col>
                            )
                          }
                         
                        </>
                      )
                    }
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <Button color="secondary" type="submit" disabled={loading.update}>
                          {loading.update ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : null}
                          {t("Account.ProfileInformation.Button.SaveChanges")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </ProfileLayout>
        </Container>
      </div>
  );
};

export default ProfileInformation;
