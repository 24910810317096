import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import FeatherIcon from "feather-icons-react";
import DisplayPrice from "Components/Common/DisplayPrice";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";
import React from "react";
import {ActiveMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";

type ProjectedSalesAndProfitsData = {
  label: string;
  count: number;
  revenue: number;
  estimatedPayout: number;
  expectedProfit: number;
  profit: number;
  totalExpectedROI: number;
  totalExpectedMargin: number;
  totalROI: number;
  totalMargin: number;
  expectedRoi: number;
  roi: number;
  expectedMargin: number;
  margin: number;
  projectedTotalRoi: number;
  projectedTotalMargin: number;
  successRate: number;
};
const ProjectedSalesAndProfits = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);
  const [tableData, setTableData] = useState<ProjectedSalesAndProfitsData[]>([]);
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();

  const shipmentsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Shipment.loading,
      currentUserShipment: state.Shipment.currentUserShipment,
    }),
  );

  const {currentUserShipment} = useSelector(shipmentsData);
  useEffect(() => {
    if (currentUserShipment) {
      let destination = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setDestinationMarketplace(destination);

      const shipment = currentUserShipment;
      const soldItems: ProjectedSalesAndProfitsData = {
        label: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.SoldItems"),
        count: shipment?.soldItems,
        revenue: shipment?.userShipmentFinance.actualSoldRevenue,
        estimatedPayout: shipment?.userShipmentFinance.estimatedSoldPayout,
        expectedProfit: shipment?.userShipmentFinance.expectedSoldProfit,
        profit: shipment?.userShipmentFinance.actualSoldProfit,
        totalExpectedROI: shipment?.userShipmentFinance.expectedROI,
        totalExpectedMargin: shipment?.userShipmentFinance.expectedMargin,
        totalROI: shipment?.userShipmentFinance.actualROI,
        totalMargin: shipment?.userShipmentFinance.actualMargin,
        expectedRoi: shipment?.userShipmentFinance.expectedSoldROI,
        expectedMargin: shipment?.userShipmentFinance.expectedSoldMargin,
        roi: shipment?.userShipmentFinance.actualSoldROI,
        margin: shipment?.userShipmentFinance.actualSoldMargin,
        projectedTotalRoi: shipment?.userShipmentFinance.actualSoldROI,
        projectedTotalMargin: shipment?.userShipmentFinance.actualSoldMargin,
        successRate: SuccessRateCalculator(shipment?.userShipmentFinance.actualSoldProfit, shipment?.userShipmentFinance.expectedSoldProfit) || 0,
      };

      const leftItems: ProjectedSalesAndProfitsData = {
        label: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems"),
        count: shipment?.totalItems - shipment?.soldItems,
        revenue: shipment?.userShipmentFinance.actualLeftRevenue,
        estimatedPayout: shipment?.userShipmentFinance.estimatedLeftPayout,
        expectedProfit: shipment?.userShipmentFinance.expectedLeftProfit,
        profit: shipment?.userShipmentFinance.actualLeftProfit,
        totalExpectedROI: shipment?.userShipmentFinance.expectedROI,
        totalExpectedMargin: shipment?.userShipmentFinance.expectedMargin,
        totalROI: shipment?.userShipmentFinance.actualROI,
        totalMargin: shipment?.userShipmentFinance.actualMargin,
        expectedRoi: shipment?.userShipmentFinance.expectedLeftROI,
        expectedMargin: shipment?.userShipmentFinance.expectedLeftMargin,
        roi: shipment?.userShipmentFinance.actualLeftROI,
        margin: shipment?.userShipmentFinance.actualLeftMargin,
        projectedTotalRoi: shipment?.userShipmentFinance.actualSoldROI,
        projectedTotalMargin: shipment?.userShipmentFinance.actualSoldMargin,
        successRate: SuccessRateCalculator(shipment?.userShipmentFinance.actualLeftProfit, shipment?.userShipmentFinance.expectedLeftProfit) || 0,
      };

      const joined: ProjectedSalesAndProfitsData[] = [soldItems, leftItems];
      setTableData(joined);
    }
  }, [t, currentUserShipment]); //eslint-disable-line

  const columns = useMemo<ColumnDef<ProjectedSalesAndProfitsData, any>[]>(
    () => [
      {
        header: " ",
        size: 100,
        accessorKey: "label",
        cell: (cellProps) => {
          return <span className="fw-medium">{cellProps.getValue()}</span>;
        },
        footer: () => {
          return <span className="fw-bold">{t("Total")}</span>;
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Count"),
        size: 50,
        accessorKey: "count",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          return (
            <span className="d-flex align-items-center">
              {cellProps.getValue()}
              <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return (
            <span className="d-flex align-items-center">
              {totalCount}
              <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
            </span>
          );
        },
      },
      {
        header: `${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Revenue")} / ${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.EstimatedPayout")}`,
        size: 150,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.revenue} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 mt-1 mb-0 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.estimatedPayout} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
        footer: ({table, column}) => {
          const totalRevenue = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.revenue as number), 0);
          const totalEstimatedPayout = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.estimatedPayout as number), 0);
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalRevenue} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 mt-1 mb-0 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalEstimatedPayout} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: `${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.ExpectedProfit")} / ${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Profit")}`,
        size: 150,
        accessorKey: "expectedProfit",
        enableSorting: false,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedProfit} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 mt-1 mb-0 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.profit} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
        footer: ({table, column}) => {
          const totalExpectedProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.expectedProfit as number), 0);
          const totalProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.profit as number), 0);
          return (
            <>
              <span className="text-muted">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalExpectedProfit} decimals={2} notation="decimal" />
              </span>
              <h5 className="fs-14 mt-1 mb-0 text-nowrap">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalProfit} decimals={2} notation="decimal" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.ExpectedRoiMargin"),
        size: 150,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <div className="text-nowrap">
              <span>{t("PercentSign", {value: row.expectedRoi.toFixed(0)})}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>{t("PercentSign", {value: row.expectedMargin.toFixed(0)})}</span>
            </div>
          );
        },
        footer: ({table}) => {
          const expectedRoi = table.getPreFilteredRowModel().rows[0]?.original.totalExpectedROI.toFixed(0);
          const expectedMargin = table.getPreFilteredRowModel().rows[0]?.original.totalExpectedMargin.toFixed(0);
          return (
            <div className="text-nowrap">
              <span>{t("PercentSign", {value: expectedRoi})}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>{t("PercentSign", {value: expectedMargin})}</span>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.RoiMargin"),
        size: 120,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          return (
            <div className="text-nowrap">
              <span>{t("PercentSign", {value: row.roi.toFixed(0)})}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>{t("PercentSign", {value: row.margin.toFixed(0)})}</span>
            </div>
          );
        },
        footer: ({table}) => {
          const totalRoi = table.getPreFilteredRowModel().rows[0]?.original.totalROI.toFixed(0);
          const totalMargin = table.getPreFilteredRowModel().rows[0]?.original.totalMargin.toFixed(0);
          return (
            <div className="text-nowrap">
              <span>{t("PercentSign", {value: totalRoi})}</span>
              <i className="mdi mdi-slash-forward"></i>
              <span>{t("PercentSign", {value: totalMargin})}</span>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.SuccessRate"),
        size: 120,
        accessorKey: "successRate",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const value = row.successRate;
          let style = "";
          if (value >= 50 && value <= 70) {
            style = "warning";
          } else if (value > 70) {
            style = "success";
          } else {
            style = "danger";
          }
          return (
            <div className="flex-shrink-0 ">
              <FeatherIcon icon="check-circle" className={`text-${style} icon-dual-${style} icon-xs me-2`} />
              <span>{t("PercentSign", {value: value.toFixed(0)})}</span>
            </div>
          );
        },
        footer: ({table, column}) => {
          const totalExpectedProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.expectedProfit as number), 0);
          const totalProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.profit as number), 0);

          const totalRate = SuccessRateCalculator(totalProfit, totalExpectedProfit) ?? 0;
          let style = "";
          if (totalRate >= 50 && totalRate <= 70) {
            style = "warning";
          } else if (totalRate > 70) {
            style = "success";
          } else {
            style = "danger";
          }
          return (
            <div className="flex-shrink-0 ">
              <FeatherIcon icon="check-circle" className={`text-${style} icon-dual-${style} icon-xs me-2`} />
              <span>{t("PercentSign", {value: totalRate.toFixed(0)})}</span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, destinationMarketplace],
  );
  return (
    <React.Fragment>
      <Col xl={9} className="same-height">
        <Card className="card-animate">
          <CardHeader>
            <h5 className="mb-0 border-0 align-items-center d-flex">{t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Title")}</h5>
          </CardHeader>
          <CardBody>
            <DataTable thClass="px-3" trClass="text-start" tdClass="text-start p-3" ref={tableRef} busy={false} columns={columns} data={tableData} totalDataLength={0} hovered />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ProjectedSalesAndProfits;
