import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";
import CopyAsinWidget from "Components/Common/CopyAsinWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import {UserSearchProduct} from "models/user_search_product";

interface ProductNotExistProps {
  item: UserSearchProduct;
}
const ProductNotExist = ({item}: ProductNotExistProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border">
      <CardHeader className="common-css padding-bottom-0">
        <Row className="align-items-center g-4">
          <Col sm>
            {item.asin ? (
              <>
                <div className="card-title mb-0">
                  <CopyAsinWidget asin={item.asin} index={item.userSearchProductId} bold={true} />
                </div>
              </>
            ) : (
              "Asin: " + t("NA")
            )}
          </Col>
        </Row>
        <Row>
          <Col sm>
            <Card className="bg-light overflow-hidden shadow-none my-3 card mt-2">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-file-find fs-22 me-1"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h5 className="align-items-center">{t("SearchResults.Analyzes.ProductNotExist.NotExist")}…</h5>
                    <p className="text-muted mb-0">
                      {item.existAtSource === false && item.existAtTarget === false
                        ? t("SearchResults.Analyzes.ProductNotExist.NotFoundInBothMarketplaces")
                        : item.existAtSource === false
                        ? t("SearchResults.Analyzes.ProductNotExist.NotFoundInSource")
                        : t("SearchResults.Analyzes.ProductNotExist.NotFoundInTarget")}
                    </p>
                    <p className="text-muted mb-0">
                      {t("Do you want to check?")}
                      &nbsp;&nbsp;
                      {item.asin && (
                        <>
                          {item.domainTarget && item.existAtSource === false && (
                            <>
                              <DomainToFlag marketplace={"us"} />
                              <Link to={`https://www.amazon.com/dp/${item.asin}?th=1&psc=1`} target="_blank" className="link-secondary link-offset-2 text-decoration-underline">
                                {item.asin}
                                <i className="ri-arrow-right-up-line"></i>
                              </Link>
                              &nbsp;&nbsp;
                            </>
                          )}

                          {item.domainTarget && item.existAtTarget === false && (
                            <>
                              <DomainToFlag marketplace={item.marketplaceTarget} />
                              <Link to={`https://www.amazon.${item.domainTarget}/dp/${item.asin}?th=1&psc=1`} target="_blank" className="link-secondary link-offset-2 text-decoration-underline">
                                {item.asin}
                                <i className="ri-arrow-right-up-line"></i>
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  );
};

export default ProductNotExist;
