import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {Link} from "react-router-dom";
import CountUp from "react-countup";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useMemo, useRef, useState} from "react";
import {rebalanceOrchestrationService} from "slices/admin/systemMonitoring/thunk";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {OrchestrationServiceDistribution} from "models/system_monitoring";
import DisplayNumber from "Components/Common/DisplayNumber";

const OrchServiceDistribution = () => {
  const tableRef = useRef<DataTableRef>(null);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const rebalanceOrchestrationServiceDialogRef = useRef<DialogRef>(null);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [dialogDescription, setDialogDescription] = useState<string>("");
  const [isUnassigned, setIsUnassigned] = useState<boolean>(false);
  const systemMonitoringData = createSelector(
    (state: RootState) => state,
    (state) => ({
      systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
      loading: state.AdminSystemMonitoring.loading,
    }),
  );

  const {systemMonitoring, loading} = useSelector(systemMonitoringData);

  const columns = useMemo<ColumnDef<OrchestrationServiceDistribution, any>[]>(
    () => [
      {
        header: t("Server"),
        size: 200,
        accessorKey: "server",
        sortingFn: "alphanumeric",
        sortDescFirst: false,
        cell: (cellProps) => {
          const row = cellProps.row.original as OrchestrationServiceDistribution;
          return (
            <div className="d-flex align-items-center">
              {row.server}
              {row.server === "Unassigned" && (
                <button
                  type="button"
                  className="btn btn-soft-secondary btn-sm ms-2"
                  onClick={() => {
                    setIsUnassigned(true);
                    setDialogTitle(t("Admin.SystemMonitoring.Dialog.RebalanceUnassignedOrchestrationService.Title"));
                    setDialogDescription(t("Admin.SystemMonitoring.Dialog.RebalanceUnassignedOrchestrationService.Description"));
                    rebalanceOrchestrationServiceDialogRef?.current?.show();
                  }}
                >
                  {t("Admin.SystemMonitoring.Button.Rebalance")}
                </button>
              )}
            </div>
          );
        },
        footer: () => {
          return <span className="fw-bold">{t("Total")}</span>;
        },
      },
      {
        header: t("Total"),
        size: 150,
        accessorKey: "total",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrchestrationServiceDistribution;
          return (
            <span className="counter-value">
              <CountUp end={row.total} duration={2} />
            </span>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return <DisplayNumber className="fw-bold" value={totalCount} notation="decimal" />;
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <PlaceholderGlow busy={loading.list}>
      <Card className="card-animate mb-0">
        <div className="align-items-center d-flex card-header">
          <h4 className="card-title mb-0 flex-grow-1">{t("Admin.SystemMonitoring.Section.Title.OrchestrationServiceDistribution")}</h4>
          <div className="flex-shrink-0">
            <Link
              id="buttonOrchestrationRebalance"
              to=""
              className="btn btn-soft-secondary btn-sm px-1 py-0 fs-16"
              onClick={() => {
                setIsUnassigned(false);
                setDialogTitle(t("Admin.SystemMonitoring.Dialog.RebalanceOrchestrationService.Title"));
                setDialogDescription(t("Admin.SystemMonitoring.Dialog.RebalanceOrchestrationService.Description"));
                rebalanceOrchestrationServiceDialogRef?.current?.show();
              }}
            >
              <i className="mdi mdi-autorenew"></i>
            </Link>
            <DefaultUncontrolledTooltip target="buttonOrchestrationRebalance">{t("Admin.SystemMonitoring.Button.Rebalance")}</DefaultUncontrolledTooltip>
          </div>
        </div>
        <CardBody>
          <DataTable
            ref={tableRef}
            busy={loading.list}
            columns={columns}
            data={systemMonitoring?.orchestrationServicesDistribution || []}
            totalDataLength={systemMonitoring?.orchestrationServicesDistribution?.length || 0}
            hovered
          />
        </CardBody>
      </Card>
      <Dialog
        ref={rebalanceOrchestrationServiceDialogRef}
        color="secondary"
        buttons={["yes", "no"]}
        busy={loading.rebalance}
        iconClass="mdi mdi-autorenew"
        title={dialogTitle}
        message={dialogDescription}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await rebalanceOrchestrationService({isUnassigned})(dispatch);
          }
          rebalanceOrchestrationServiceDialogRef.current?.hide();
        }}
      />
    </PlaceholderGlow>
  );
};

export default OrchServiceDistribution;
