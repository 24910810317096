import React, {useEffect, useState} from "react";
import {Card, CardBody, Col} from "reactstrap";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {RootState} from "slices";
import {StrokedCircularRadial} from "Components/Common/Charts/StrokedCircularRadial";
import CountUp from "react-countup";
import DisplayPrice from "Components/Common/DisplayPrice";

const SalesOverview = () => {
  const {t} = useTranslation();
  const [revenue, setRevenue] = useState<number | undefined>(0);
  const [soldItemsCount, setSoldItemsCount] = useState<number | undefined>(0);
  const [cost, setCost] = useState<number | undefined>(0);
  const [fees, setFees] = useState<number | undefined>(0);
  const [profit, setProfit] = useState<number | undefined>(0);
  const [profitPerItem, setProfitPerItem] = useState<number | undefined>(0);
  const [roi, setRoi] = useState<number | undefined>(0);
  const [margin, setMargin] = useState<number | undefined>(0);

  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      filter: state.Dashboard.filter,
      statistics: state.Dashboard.statistics,
    }),
  );
  const {filter, statistics} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics.salesOverview) {
      setRevenue(statistics.salesOverview.revenue);
      setSoldItemsCount(statistics.salesOverview.soldItemsCount);
      setCost(statistics.salesOverview.totalCosts);
      setFees(statistics.salesOverview.totalFees);
      setProfit(statistics.salesOverview.profit);
      setProfitPerItem(statistics.salesOverview.profitPerItem);
      setRoi(statistics.salesOverview.roi);
      setMargin(statistics.salesOverview.margin);
    }
  }, [statistics]);

  return (
    <React.Fragment>
      <Col xl={4} className="same-height">
        <Card className="card-height-100 card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.SalesOverview.Title")}</h4>
          </div>
          <CardBody>
            <div>
              <div className="mb-n4">
                <StrokedCircularRadial progress={roi ?? 0} label="ROI" height={330} />
              </div>
              <div className="d-flex flex-column gap-3 fs-14">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-truncate mb-0">
                      <i className="bx bx-dollar-circle align-middle text-primary me-2 fs-20"></i>
                      {t("Dashboard.SalesOverview.Label.Revenue")}
                    </p>
                  </div>
                  <div className="fw-medium">
                    <DisplayPrice source={filter?.currencyCode} value={revenue} decimals={2} notation="abbreviated" />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-truncate mb-0">
                      <i className="bx bx-shopping-bag align-middle text-primary me-2 fs-20"></i>
                      {t("Dashboard.SalesOverview.Label.SoldItems")}
                    </p>
                  </div>
                  <div className="fw-medium">{soldItemsCount}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-truncate mb-0">
                      <i className="bx bxs-credit-card align-middle text-primary me-2 fs-20"></i>
                      {t("Dashboard.SalesOverview.Label.Costs")}
                    </p>
                  </div>
                  <div className="fw-medium">
                    <DisplayPrice source={filter?.currencyCode} value={cost} decimals={2} notation="abbreviated" />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-truncate mb-0">
                      <i className="bx bxs-bank align-middle text-primary me-2 fs-20"></i>
                      {t("Dashboard.SalesOverview.Label.Fees")}
                    </p>
                  </div>
                  <div className="fw-medium">
                    <DisplayPrice source={filter?.currencyCode} value={fees} decimals={2} notation="abbreviated" />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-truncate mb-0">
                      <i className="bx bxs-wallet align-middle text-primary me-2 fs-20"></i>
                      {t("Dashboard.SalesOverview.Label.Profit")}
                    </p>
                  </div>
                  <div className="fw-medium">
                    <DisplayPrice source={filter?.currencyCode} value={profit} decimals={2} notation="abbreviated" />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-truncate mb-0">
                      <i className="bx bxs-purchase-tag align-middle text-primary me-2 fs-20"></i>
                      {t("Dashboard.SalesOverview.Label.ProfitPerItem")}
                    </p>
                  </div>
                  <div className="fw-medium">
                    <DisplayPrice source={filter?.currencyCode} value={profitPerItem} decimals={2} notation="abbreviated" />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="text-truncate mb-0">
                      <i className="bx bx-dollar align-middle text-primary me-2 fs-20"></i>
                      {t("Dashboard.SalesOverview.Label.RoiMargin")}
                    </p>
                  </div>
                  <div className="fw-medium">
                    <CountUp start={0} end={roi ?? 0} decimals={0} duration={2} suffix="%" />

                    <i className="mdi mdi-slash-forward"></i>
                    <CountUp start={0} end={margin ?? 0} decimals={0} duration={2} suffix="%" />
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SalesOverview;
