import {PropsWithChildren, useEffect, useRef} from "react";
import {FormGroup, Input, InputProps, Label} from "reactstrap";
import {ValidatableInputProps} from "helpers/types";
import classNames from "classnames";

export type CheckboxProps = ValidatableInputProps<boolean> &
  PropsWithChildren &
  Pick<InputProps, "id" | "disabled" | "className" | "style" | "onClick"> & {
    switch?: boolean;
    indeterminate?: boolean;
    label?: string;
    disableValidationUI?: boolean;
    disabled?: boolean;
  };

const Checkbox = (props: CheckboxProps) => {
  const {isValid, className, switch: isSwitch, value, onChange, label, name, disabled, indeterminate, children, disableValidationUI, ...otherProps} = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && indeterminate) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [inputRef, indeterminate]);

  return (
    <>
      <FormGroup
        switch={isSwitch}
        check={!isSwitch}
        className={
          classNames({
            "form-switch-md": isSwitch,
          }) + " user-select-none"
        }
      >
        <Input
          innerRef={inputRef}
          className={classNames(className)}
          type={isSwitch ? "switch" : "checkbox"}
          onChange={(e) => onChange?.(e.target.checked)}
          disabled={disabled || false}
          checked={value || false}
          name={name}
          id={name}
          {...otherProps}
        />
        {label || children ? (
          <Label htmlFor={name} check>
            {children || label}
          </Label>
        ) : null}
      </FormGroup>
    </>
  );
};

export default Checkbox;
