export enum AmazonBusinessModel {
    CROSS_BORDER_ARBITRAGE = 0,
    DROPSHIPPING = 1,
    WHOLESALE = 2
  }

  export const convertToAmazonBusinessModel = (value: string | null): AmazonBusinessModel | undefined  => {
    if (value !== null && !isNaN(Number(value))) {
      const numericValue = Number(value);
      if (numericValue in AmazonBusinessModel) {
        return numericValue as AmazonBusinessModel;
      }
    }
    return undefined;
  }
  
  export const convertToEnum = <T extends { [key: string]: string | number }>(enumObj: T, value: string | null): T[keyof T] | undefined => {
    if (value !== null) {
        const enumValue = enumObj[value as keyof T];
        if (enumValue !== undefined) {
            return enumValue;
        }
    }
    return undefined;
};