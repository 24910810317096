export const AmazonHelper = {
  isValidAsin(part: string): boolean {
    // ASIN: Must be 10 characters long and contain only letters and numbers
    const asinRegex = /^[A-Za-z0-9]{10}$/;
    return asinRegex.test(part);
  },

  isValidUpc(part: string): boolean {
    // UPC: Must be 12 characters long and contain only digits
    return part.length === 12 && /^[0-9]+$/.test(part);
  },

  isValidEan(part: string): boolean {
    // EAN: Must be 8 or 13 characters long and contain only digits
    return (part.length === 8 || part.length === 13) && /^[0-9]+$/.test(part);
  },
};
