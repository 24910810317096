import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import ProfitCalculator from "Components/Common/Calculator/_ProfitCalculator";
import { AmazonMarketplace } from "helpers/marketplace_helper";

interface ProfitAnalysisProps {
  item: UserSearchProduct;
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
}
const ProfitAnalysis = ({item, sourceMarketplace, destinationMarketplace}: ProfitAnalysisProps) => {
  const {t} = useTranslation();
  return (
    <ProfitCalculator
      title={t("SearchResults.Analyzes.ProfitAnalysis.Title")}
      amazonBusinessModel={item.amazonBusinessModel}
      itemProfitAnalysis={item.profitAnalysis}
      buyBoxType={item.competitorAnalysis?.buyBoxType}
      offerCount={item.competitorAnalysis?.offerCount}
      userSearchProductId={item.userSearchProductId}
      sourceMarketplace={sourceMarketplace}
      destinationMarketplace={destinationMarketplace}
    />
  );
};

export default ProfitAnalysis;
