import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useFormik} from "formik";
import {SearchResultFilter} from "models/search_result";
import {memo, useCallback, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import {deleteFilter, getUserSearchResultsFilters, saveFilter} from "slices/search-result/thunk";
import {createTypedModal} from "helpers/modal_helpers";
import {createSelector} from "reselect";
import {RootState} from "slices";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface ModalData {
  filterName: string;
  filters: SearchResultFilter;
}

export const DefinedFiltersModal = createTypedModal<ModalData>("defined_filters");
const definedFilterSelector = createSelector([(state: RootState) => state.SearchResult], (searchResult) => ({
  loading: searchResult.loading,
  definedFilters: searchResult.definedFilters,
}));

const DefinedFilters = () => {
  const {open, data} = DefinedFiltersModal.useModal();
  if (!open) return null;
  return <DefinedFilterContent data={data} />;
};

const DefinedFilterContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const deleteFilterDialogRef = useRef<DialogRef>(null);
  const {loading, definedFilters} = useSelector(definedFilterSelector);

  const initModal = useCallback(() => {
    const filterResult = definedFilters.find((filter) => filter.filterName === data?.filterName);
    if (filterResult) {
      // Update existing filter
      validation.setFieldValue("filter", data?.filters);
      validation.setFieldValue("filterName", data?.filterName);
      validation.setFieldValue("originalFilterName", data?.filterName);
    } else {
      // Create new filter
      validation.setFieldValue("filter", data?.filters);
      validation.setFieldValue("originalFilterName", null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      originalFilterName: "",
      filterName: "",
      filter: {} as SearchResultFilter,
    },
    validationSchema: Yup.object({
      originalFilterName: Yup.string().notRequired(),
      filterName: Yup.string(),
      filter: Yup.object<SearchResultFilter>(),
    }),
    onSubmit: (values) => {
      const savePromise = saveFilter({originalFilterName: values.originalFilterName, filterName: values.filterName, filter: values.filter})(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          toggle();
        }
      });
    },
  });

  const toggle = () => {
    DefinedFiltersModal.close();
    validation.resetForm();
  };

  useEffect(() => {
    if (data?.filters && validation.values.filterName && !validation.values.originalFilterName) {
      validation.setFieldValue("newFilterName", validation.values.filterName);
    }
  }, [data?.filters, validation.values.filterName]); // eslint-disable-line

  return (
    <>
      <Modal isOpen={true} toggle={toggle} fade={true} centered={true}>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("SearchResults.Filters.Dialog.SaveFilter.Title")}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <ValidatedInput validation={validation} field="filterName" maxLength={100} placeholder={t("SearchResults.Filters.Dialog.SaveFilter.FilterName")} disableValidationUI />
            <Row className="mt-3 d-flex align-items-center">
              <Col>
                {data?.filterName && (
                  <div>
                    <Button
                      color="link"
                      className="link-danger link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={() => deleteFilterDialogRef.current?.show()}
                    >
                      {t("SearchResults.Filters.Button.Delete", {value: data.filterName})}
                    </Button>
                  </div>
                )}
              </Col>
              <Col xs="auto" className="pe-0">
                <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.save}>
                  {t("SearchResults.Filters.Dialog.SaveFilter.CloseButton")}
                </Button>
              </Col>
              <Col xs="auto">
                <Button type="submit" className="btn btn-success" disabled={loading.save}>
                  {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                  {t("SearchResults.Filters.Dialog.SaveFilter.SubmitButton")}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Dialog
        ref={deleteFilterDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("SearchResults.Filters.Dialog.DeleteFilter.Description")}
        title={t("SearchResults.Filters.Dialog.DeleteFilter.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && data?.filters) {
            await deleteFilter(data.filterName)(dispatch);
            await getUserSearchResultsFilters()(dispatch);
            toggle();
          }
          deleteFilterDialogRef.current?.hide();
        }}
      />
    </>
  );
};

export default memo(DefinedFilters);
