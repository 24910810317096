import useSelectOptions, {MarketplaceSelectOption} from "Components/Hooks/useSelectOptions";
import {useCallback, useEffect, useState} from "react";
import {useFormik} from "formik";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setSelectAllUserInventories} from "slices/inventory/reducer";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {UserStore} from "models/user_stores";
import {UserInventoryQuery} from "api/query";
import {Link} from "react-router-dom";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {NumberRange} from "helpers/types";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import Checkbox from "Components/Common/Checkbox";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedNumberRange from "Components/Common/ValidatedNumberRange";
import * as Yup from "yup";
import {AllMarketplaces} from "helpers/marketplace_helper";

interface FiltersProps {
  busy: boolean;
  fields: FieldConfig<UserInventoryQuery>[];
}
const Filters = (props: FiltersProps) => {
  const dispatch: any = useDispatch();
  const {multiSelectTranslations} = useSelectOptions();
  const {t} = useTranslation();
  const [storeSelectOptions, setStoreSelectOptions] = useState<MarketplaceSelectOption[]>([]);
  const [shipmentSelectOptions, setShipmentSelectOptions] = useState<MarketplaceSelectOption[]>([]);

  const reduxData = createSelector(
    (state: RootState) => state,
    (state) => ({
      commonUserStore: state.Common.userStores,
      shipments: state.Inventory.shipments,
    }),
  );

  const {commonUserStore, shipments} = useSelector(reduxData);

  useEffect(() => {
    prepareOptions();
  }, [commonUserStore]); //eslint-disable-line

  const prepareOptions = async () => {
    const stores = commonUserStore.filter((store) => store.marketplace !== "US" && store.status);
    const storeOptions: MarketplaceSelectOption[] = stores.map((store: UserStore) => {
      return {
        value: store.userStoreId,
        label: `${AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === store.marketplace)?.marketplace} - ${store.name}`,
        marketplace: store.marketplace,
      };
    });
    setStoreSelectOptions(storeOptions);
  };

  const validation = useFormik({
    initialValues: {} as UserInventoryQuery,
    validationSchema: Yup.object<UserInventoryQuery>({
      search: Yup.string().notRequired(),
      store: Yup.string().notRequired(),
      marginRange: Yup.object<NumberRange>(),
      roiRange: Yup.object<NumberRange>(),
      profitRange: Yup.object<NumberRange>(),
      shipments: Yup.array<String>().notRequired(),
      inStock: Yup.boolean(),
      showArchived: Yup.boolean(),
      productsWithoutCostInformation: Yup.boolean(),
      availableInMyStore: Yup.boolean(),
    }),
    onSubmit: (values: UserInventoryQuery) => {
      dispatch(setSelectAllUserInventories(false));
      const payload: UserInventoryQuery = {
        page: values.page,
        pageSize: values.pageSize,
        stores: values.stores,
        search: values.search,
        marginRange: values.marginRange,
        roiRange: values.roiRange,
        profitRange: values.profitRange,
        shipments: values.shipments,
        inStock: values.inStock || undefined,
        showArchived: values.showArchived || undefined,
        productsWithoutCostInformation: values.productsWithoutCostInformation || undefined,
        availableInMyStore: values.availableInMyStore || undefined,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });

  const {updateQuery} = useUrlQuery<UserInventoryQuery>(props.fields, validation);

  const onStoreSelectChange = useCallback(() => {
    const filteredShipments = validation.values.stores?.length ? shipments.filter((shipment) => validation.values.stores?.includes(shipment.userStoreId)) : shipments;

    setShipmentSelectOptions(
      filteredShipments.map((shipment) => ({
        value: shipment.userShipmentId,
        label: shipment.name,
        marketplace: shipment.marketplace,
      })),
    );
  }, [validation.values.stores, shipments]);

  const onLoad = useCallback(() => {
    validation.handleSubmit();
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    onStoreSelectChange();
  }, [onStoreSelectChange]);

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <Card>
          <CardBody>
            <div className="live-preview">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="align-items-center">
                  <Col>
                    <div className="flex-shrink-0">
                      <Label className="form-label text-muted">{t("Inventory.Filters.Title")}</Label>
                    </div>
                  </Col>
                  <Col xs="auto" align="right">
                    <Link to="/inventory/updateInventory" className="btn btn-secondary fw-semibold btn-sm">
                      <i className="mdi mdi-swap-vertical-bold align-middle me-1"></i>
                      {t("Inventory.Dialog.UpdateInventory.Button")}
                    </Link>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center mt-2 gx-5 gy-3">
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="search">{t("Inventory.Filters.Search")}</Label>
                    <ValidatedInput validation={validation} field="search" maxLength={100} placeholder={t("Inventory.Filters.SearchPlaceholder")} disableValidationUI />
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="stores">{t("Inventory.Filters.StoreFilter")}</Label>
                    <ValidatedMultiSelect
                      options={storeSelectOptions}
                      validation={validation}
                      field="stores"
                      value={validation.values.stores}
                      translations={{
                        ...multiSelectTranslations,
                        allItemsAreSelected: t("General.Select.AllStoresSelected"),
                        selectSomeItems: t("General.Select.SelectStore"),
                      }}
                      optionStyle="country"
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="endDate">{t("Orders.Filters.Shipment")}</Label>
                    <ValidatedMultiSelect
                      options={shipmentSelectOptions}
                      validation={validation}
                      field="shipments"
                      value={validation.values.shipments}
                      translations={{
                        ...multiSelectTranslations,
                        allItemsAreSelected: t("General.Select.AllShipmentsSelected"),
                        selectSomeItems: t("General.Select.SelectShipment"),
                      }}
                      optionStyle="country"
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="marginMin">{t("Inventory.Filters.Margin")}</Label>
                    <ValidatedNumberRange validation={validation} field={"marginRange"} />
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="roi">{t("Inventory.Filters.ROI")}</Label>
                    <ValidatedNumberRange validation={validation} field={"roiRange"} />
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="profit">{t("Inventory.Filters.Profit")}</Label>
                    <ValidatedNumberRange validation={validation} field={"profitRange"} />
                  </Col>
                </Row>
                <Row className="gx-5 gy-3 mt-3">
                  <Col xs="auto">
                    <ValidationWrapper validation={validation} field="inStock" submitOnChange>
                      <Checkbox disableValidationUI>
                        <span>{t("Inventory.Filters.InStock")}</span>
                      </Checkbox>
                    </ValidationWrapper>
                  </Col>

                  <Col xs="auto">
                    <ValidationWrapper validation={validation} field="showArchived" submitOnChange>
                      <Checkbox disableValidationUI>
                        <span>{t("Inventory.Filters.ShowArchived")}</span>
                      </Checkbox>
                    </ValidationWrapper>
                  </Col>

                  <Col xs="auto">
                    <ValidationWrapper validation={validation} field="productsWithoutCostInformation" submitOnChange>
                      <Checkbox disableValidationUI>
                        <span>{t("Inventory.Filters.ProductsWithoutCostInformation")}</span>
                      </Checkbox>
                    </ValidationWrapper>
                  </Col>

                  <Col>
                    <ValidationWrapper validation={validation} field="availableInMyStore" submitOnChange>
                      <Checkbox disableValidationUI>
                        <span>{t("Inventory.Filters.AvailableInMyStore")}</span>
                      </Checkbox>
                    </ValidationWrapper>
                  </Col>

                  <Col xs="auto">
                    <Button type="submit" color="secondary" className="me-0" disabled={props.busy}>
                      {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("Inventory.Filters.Button.Apply")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default Filters;
