import {Table} from "@tanstack/react-table";
import {Workbook} from "exceljs";
import {saveAs} from "file-saver";
import _ from "lodash";

export default async function exportExcelForTable(table: Table<any>, fileName: string, applyFilters = true) {
  const wb = new Workbook();
  const ws = wb.addWorksheet("Sheet 1");

  const lastHeaderGroup = table.getHeaderGroups().at(-1);
  if (!lastHeaderGroup) {
    console.error("No header group found", table.getHeaderGroups());
    return;
  }

  ws.columns = lastHeaderGroup.headers
    .filter((h) => h.column.getIsVisible())
    .map((header) => {
      return {
        header: header.column.columnDef.header as string,
        key: header.id,
        width: 20,
      };
    });

  const exportRows = applyFilters ? table.getFilteredRowModel().rows : table.getRowModel().rows;

  exportRows.forEach((row) => {
    const cells = row.getVisibleCells();
    const values = cells.map((cell) => cell.getValue() ?? "");
    ws.addRow(values);
  });

  ws.getRow(1).eachCell((cell) => {
    cell.font = {bold: true};
  });

  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${fileName}.xlsx`);
}

export async function exportExcelForList<T>(dataList: T[], fileName: string, adjustColumns?: boolean): Promise<void> {
  // Create a new workbook and add a worksheet
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");

  // Collect all unique keys across the list to form the headers
  const headers = Array.from(new Set(dataList.flatMap((item) => Object.keys((item as unknown) as Record<string, any>))));

  // Convert camelCase headers to Normal Case
  const formattedHeaders = headers.map((header) => _.startCase(header));
  worksheet.addRow(formattedHeaders);

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = {bold: true};
  });

  if (adjustColumns) {
    // Auto adjust column widths
    headers.forEach((header, index) => {
      let maxLength = header.length;
      dataList.forEach((item) => {
        const cellValue = ((item as unknown) as Record<string, any>)[header] ?? "";
        maxLength = Math.max(maxLength, cellValue.toString().length);
      });
      worksheet.getColumn(index + 1).width = maxLength + 2; 
    });
  }
  // Add data rows dynamically
  dataList.forEach((item) => {
    const row = headers.map((header) => ((item as unknown) as Record<string, any>)[header] ?? null);
    worksheet.addRow(row);
  });

  // Write to a file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${fileName}.xlsx`);
}


export async function exportAsinsToExcel<T>(data: T[], fileName: string) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Sheet 1");

  worksheet.columns = [{header: "ASIN", key: "asin"}];

  for (const item of data) {
    worksheet.addRow(item as any); // TypeScript hata mesajını önlemek için
  }

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${fileName}.xlsx`);
}
