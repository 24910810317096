import React from "react";

interface PlaceholderGlowProps {
  busy: boolean;
  children?: React.ReactNode;
}
const PlaceholderGlow = (props: PlaceholderGlowProps) => {
  return (
    <React.Fragment>
      {props.busy ? (
          <div className={"placeholder w-100"}>{props.children}</div>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
};

export default PlaceholderGlow;
