import useSelectOptions, {MarketplaceSelectOption} from "Components/Hooks/useSelectOptions";
import {useCallback, useEffect, useState} from "react";
import {useFormik} from "formik";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {FilterOrderQuery} from "api/query";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import ValidatedInput from "Components/Common/ValidatedInput";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import * as Yup from "yup";
import {NumberRange} from "helpers/types";
import ValidatedNumberRange from "Components/Common/ValidatedNumberRange";

interface FiltersProps {
  busy: boolean;
  fields: FieldConfig<FilterOrderQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {multiSelectTranslations, orderStatusSelectOptions} = useSelectOptions();
  const [shipmentSelectOptions, setShipmentSelectOptions] = useState<MarketplaceSelectOption[]>([]);

  const orderData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Orders.loading,
      shipments: state.Orders.shipments,
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
    }),
  );
  const {loading, shipments, activeUserStoreOptions} = useSelector(orderData);

  const validation = useFormik({
    initialValues: {} as FilterOrderQuery,
    validationSchema: Yup.object<FilterOrderQuery>({
      search: Yup.string(),
      stores: Yup.array<String>().notRequired(),
      shipments: Yup.array<String>().notRequired(),
      marginRange: Yup.object<NumberRange>().notRequired(),
      roiRange: Yup.object<NumberRange>().notRequired(),
      profitRange: Yup.object<NumberRange>().notRequired(),
      startDate: Yup.date(),
      endDate: Yup.date(),
      status: Yup.array<String>().notRequired(),
    }),
    onSubmit: (values: FilterOrderQuery) => {
      const payload: FilterOrderQuery = {
        page: values.page,
        pageSize: values.pageSize,
        stores: values.stores,
        search: values.search,
        marginRange: values.marginRange,
        roiRange: values.roiRange,
        profitRange: values.profitRange,
        startDate: values.startDate,
        endDate: values.endDate,
        status: values.status,
        shipments: values.shipments,
        action: "filtering",
      };

      updateQuery(payload);
    },
  });

  const {updateQuery} = useUrlQuery<FilterOrderQuery>(props.fields, validation);

  const onLoad = useCallback(() => {
    validation.handleSubmit();
  }, []); // eslint-disable-line

  const onStoreSelectChange = useCallback(() => {
    const filteredShipments = validation.values.stores?.length ? shipments.filter((shipment) => validation.values.stores?.includes(shipment.userStoreId)) : shipments;

    setShipmentSelectOptions(
      filteredShipments.map((shipment) => ({
        value: shipment.userShipmentId,
        label: shipment.name,
        marketplace: shipment.marketplace,
      })),
    );
  }, [validation.values.stores, shipments]);

  const onShipmentListChange = useCallback(() => {
    if (shipments) {
      setShipmentSelectOptions(
        shipments.map((shipment) => {
          return {
            value: shipment.userShipmentId,
            label: shipment.name,
            marketplace: shipment.marketplace,
          };
        }),
      );
    }
  }, [shipments]);

  useEffect(() => {
    onLoad();
  }, [onLoad]); // eslint-disable-line

  useEffect(() => {
    onStoreSelectChange();
  }, [onStoreSelectChange]);

  useEffect(() => {
    onShipmentListChange();
  }, [onShipmentListChange]);

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <Card>
          <CardBody>
            <div className="live-preview">
              <Form onSubmit={validation.handleSubmit}>
                <Row className="align-items-center">
                  <Col>
                    <div className="flex-shrink-0">
                      <Label className="form-label text-muted">{t("Orders.Filters.Title")}</Label>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-2 gx-5 gy-3">
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="search">{t("Orders.Filters.Search")}</Label>
                    <ValidatedInput validation={validation} field="search" maxLength={100} placeholder={t("Orders.Filters.SearchPlaceholder")} disableValidationUI />
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="stores">{t("Orders.Filters.StoreFilter")}</Label>
                    <ValidatedMultiSelect
                      options={activeUserStoreOptions}
                      validation={validation}
                      field="stores"
                      value={validation.values.stores}
                      translations={{
                        ...multiSelectTranslations,
                        allItemsAreSelected: t("General.Select.AllStoresSelected"),
                        selectSomeItems: t("General.Select.SelectStore"),
                      }}
                      optionStyle="country"
                    />
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="startDate">{t("Orders.Filters.StartDate")}</Label>
                    <ValidatedDate field="startDate" validation={validation} value={validation.values.startDate}></ValidatedDate>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="endDate">{t("Orders.Filters.EndDate")}</Label>
                    <ValidatedDate field="endDate" validation={validation} value={validation.values.endDate}></ValidatedDate>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="endDate">{t("Orders.Filters.Shipment")}</Label>
                    <ValidatedMultiSelect
                      options={shipmentSelectOptions}
                      validation={validation}
                      field="shipments"
                      value={validation.values.shipments}
                      translations={{
                        ...multiSelectTranslations,
                        allItemsAreSelected: t("General.Select.AllShipmentsSelected"),
                        selectSomeItems: t("General.Select.SelectShipment"),
                      }}
                      optionStyle="country"
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="profit">{t("Status")}</Label>
                    <Row>
                      <Col>
                        <ValidatedMultiSelect validation={validation} field="status" value={validation.values.status} options={orderStatusSelectOptions} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-2 gx-5 gy-3">
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="marginMin">{t("Orders.Filters.Margin")}</Label>
                    <Row>
                      <Col>
                        <ValidatedNumberRange validation={validation} field="marginRange" />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="roi">{t("Orders.Filters.ROI")}</Label>
                    <Row>
                      <Col>
                        <ValidatedNumberRange validation={validation} field="roiRange" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="profit">{t("Orders.Filters.Profit")}</Label>
                    <Row>
                      <Col>
                        <ValidatedNumberRange validation={validation} field="profitRange" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="profit">&nbsp;</Label>
                    <Row>
                      <Col>
                        <Button type="submit" color="secondary" className="me-0" disabled={loading.filter} onClick={() => validation.setFieldValue("page", 1)}>
                          {loading.filter ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                          {t("Orders.Filters.Button.Apply")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      </PlaceholderGlow>
    </>
  );
};

export default Filters;
