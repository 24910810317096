import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {duplicateCostAndFeeSettings} from "slices/settings/thunk";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface IDuplicateCostAndFeeOptionProps {
  show: boolean;
  toggle: () => void;
}
const DuplicateCostAndFeeOption = (props: IDuplicateCostAndFeeOptionProps) => {
  const {t} = useTranslation();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {name: "", marketplace: null, copyFrom: null},
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Settings.CostAndFees.Validation.Name")),
    }),
    onSubmit: (values: any) => {
      const duplicatePromise = duplicateCostAndFeeSettings(currentCostAndFeeOption.userSettingCostAndFeeOptionId, values.name)(dispatch);
      duplicatePromise.then((success) => {
        if (success) {
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  const dispatch: any = useDispatch();
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
      currentCostAndFeeOption: state.Settings.currentCostAndFeeOption,
    }),
  );

  const {loading, currentCostAndFeeOption} = useSelector(searchResultsData);

  const toggle = () => {
    props.toggle();
    validation.resetForm();
  };
  return (
    <>
      <Modal backdrop="static" id="showDuplicateCostAndFeeOptionModal" isOpen={props.show} toggle={toggle} fade={true} centered={true}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalHeader className="bg-light p-3" toggle={toggle}>
            {t("Settings.CostAndFees.Dialog.Duplicate.Title")}
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
              <Col>
                <Label className="form-label" htmlFor="name">
                  {t("Settings.CostAndFees.Name")}
                </Label>
                <ValidatedInput validation={validation} field={"name"} maxLength={100} placeholder={t("Settings.CostAndFees.NamePlaceholder")} disableValidationUI />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={toggle}>
                {t("Settings.CostAndFees.Dialog.Duplicate.CancelButton")}
              </Button>
              <Button type="submit" color="success" disabled={loading.duplicate}>
                {loading.duplicate && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                {t("Settings.CostAndFees.Dialog.Duplicate.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default DuplicateCostAndFeeOption;
