import usFlag from "../assets/images/flags/us.svg";
import caFlag from "../assets/images/flags/ca.svg";
import mxFlag from "../assets/images/flags/mx.svg";
import brFlag from "../assets/images/flags/br.svg";
import gbFlag from "../assets/images/flags/gb.svg";
import deFlag from "../assets/images/flags/de.svg";
import frFlag from "../assets/images/flags/fr.svg";
import itFlag from "../assets/images/flags/it.svg";
import nlFlag from "../assets/images/flags/nl.svg";
import esFlag from "../assets/images/flags/es.svg";
import beFlag from "../assets/images/flags/be.svg";
import plFlag from "../assets/images/flags/pl.svg";
import seFlag from "../assets/images/flags/se.svg";
import aeFlag from "../assets/images/flags/ae.svg";
import saFlag from "../assets/images/flags/sa.svg";
import egFlag from "../assets/images/flags/eg.svg";
import inFlag from "../assets/images/flags/in.svg";
import trFlag from "../assets/images/flags/tr.svg";
import auFlag from "../assets/images/flags/au.svg";
import jpFlag from "../assets/images/flags/jp.svg";
import sgFlag from "../assets/images/flags/sg.svg";
import i18n from "i18n";
import {MarketplaceSelectOption, SelectOptionsType} from "Components/Hooks/useSelectOptions";

export type AmazonMarketplace = Readonly<{
  marketplace: string;
  countryName: string;
  domain: string;
  currency: string;
  flag: string;
  active: boolean;
  priceSign: string;
}>;

export type AmazonFBAMarketplace = Readonly<{
  id: string;
  marketplace: string;
  countryName: string;
  domain: string;
  currency: string;
  flag: string;
  active: boolean;
  priceSign: string;
  isLocal: boolean;
  isDefaultUs?: boolean;
}>;

const AmazonMarketplaces: ReadonlyArray<AmazonMarketplace> = [
  {
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: true,
    priceSign: "$",
  },
  {
    marketplace: "CA",
    countryName: i18n.t("Country.Canada"),
    domain: "ca",
    currency: "CAD",
    flag: caFlag,
    active: true,
    priceSign: "$",
  },
  {
    marketplace: "MX",
    countryName: i18n.t("Country.Mexico"),
    domain: "com.mx",
    currency: "MXN",
    flag: mxFlag,
    active: true,
    priceSign: "$",
  },
  {
    marketplace: "BR",
    countryName: i18n.t("Country.Brazil"),
    domain: "com.br",
    currency: "BRL",
    flag: brFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "GB",
    countryName: i18n.t("Country.UnitedKingdom"),
    domain: "co.uk",
    currency: "GBP",
    flag: gbFlag,
    active: true,
    priceSign: "£",
  },
  {
    marketplace: "DE",
    countryName: i18n.t("Country.Germany"),
    domain: "de",
    currency: "EUR",
    flag: deFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "FR",
    countryName: i18n.t("Country.France"),
    domain: "fr",
    currency: "EUR",
    flag: frFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "IT",
    countryName: i18n.t("Country.Italy"),
    domain: "it",
    currency: "EUR",
    flag: itFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "NL",
    countryName: i18n.t("Country.Netherlands"),
    domain: "nl",
    currency: "EUR",
    flag: nlFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "ES",
    countryName: i18n.t("Country.Spain"),
    domain: "es",
    currency: "EUR",
    flag: esFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "BE",
    countryName: i18n.t("Country.Belgium"),
    domain: "com.be",
    currency: "EUR",
    flag: beFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "PL",
    countryName: i18n.t("Country.Poland"),
    domain: "pl",
    currency: "PLN",
    flag: plFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "SE",
    countryName: i18n.t("Country.Sweden"),
    domain: "se",
    currency: "SEK",
    flag: seFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "AE",
    countryName: i18n.t("Country.UnitedArabEmirates"),
    domain: "ae",
    currency: "AED",
    flag: aeFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "SA",
    countryName: i18n.t("Country.SaudiArabia"),
    domain: "sa",
    currency: "SAR",
    flag: saFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "EG",
    countryName: i18n.t("Country.Egypt"),
    domain: "eg",
    currency: "EGP",
    flag: egFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "IN",
    countryName: i18n.t("Country.India"),
    domain: "in",
    currency: "INR",
    flag: inFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "TR",
    countryName: i18n.t("Country.Turkey"),
    domain: "com.tr",
    currency: "TRY",
    flag: trFlag,
    active: false,
    priceSign: "₺",
  },
  {
    marketplace: "AU",
    countryName: i18n.t("Country.Australia"),
    domain: "com.au",
    currency: "AUD",
    flag: auFlag,
    active: true,
    priceSign: "$",
  },
  {
    marketplace: "JP",
    countryName: i18n.t("Country.Japan"),
    domain: "co.jp",
    currency: "JPY",
    flag: jpFlag,
    active: false,
    priceSign: "",
  },
  {
    marketplace: "SG",
    countryName: i18n.t("Country.Singapore"),
    domain: "sg",
    currency: "SGD",
    flag: sgFlag,
    active: false,
    priceSign: "",
  },
];

export const AmazonFBAMarketplaces: ReadonlyArray<AmazonFBAMarketplace> = [
  {
    id: "ATVPDKIKX0DER",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: true,
    isDefaultUs: true,
  },
  {
    id: "A2EUQ1WTGCTBG2",
    marketplace: "CA",
    countryName: i18n.t("Country.Canada"),
    domain: "ca",
    currency: "CAD",
    flag: caFlag,
    active: true,
    priceSign: "$",
    isLocal: true,
  },
  {
    id: "A1AM78C64UM0Y8",
    marketplace: "MX",
    countryName: i18n.t("Country.Mexico"),
    domain: "com.mx",
    currency: "MXN",
    flag: mxFlag,
    active: true,
    priceSign: "$",
    isLocal: true,
  },
  {
    id: "A2Q3Y263D00KWC",
    marketplace: "BR",
    countryName: i18n.t("Country.Brazil"),
    domain: "com.br",
    currency: "BRL",
    flag: brFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A1F83G8C2ARO7P",
    marketplace: "GB",
    countryName: i18n.t("Country.UnitedKingdom"),
    domain: "co.uk",
    currency: "GBP",
    flag: gbFlag,
    active: true,
    priceSign: "£",
    isLocal: true,
  },
  {
    id: "A1PA6795UKMFR9",
    marketplace: "DE",
    countryName: i18n.t("Country.Germany"),
    domain: "de",
    currency: "EUR",
    flag: deFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A13V1IB3VIYZZH",
    marketplace: "FR",
    countryName: i18n.t("Country.France"),
    domain: "fr",
    currency: "EUR",
    flag: frFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "APJ6JRA9NG5V4",
    marketplace: "IT",
    countryName: i18n.t("Country.Italy"),
    domain: "it",
    currency: "EUR",
    flag: itFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A1805IZSGTT6HS",
    marketplace: "NL",
    countryName: i18n.t("Country.Netherlands"),
    domain: "nl",
    currency: "EUR",
    flag: nlFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A1RKKUPIHCS9HS",
    marketplace: "ES",
    countryName: i18n.t("Country.Spain"),
    domain: "es",
    currency: "EUR",
    flag: esFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "AMEN7PMS3EDWL",
    marketplace: "BE",
    countryName: i18n.t("Country.Belgium"),
    domain: "com.be",
    currency: "EUR",
    flag: beFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A1C3SOZRARQ6R3",
    marketplace: "PL",
    countryName: i18n.t("Country.Poland"),
    domain: "pl",
    currency: "PLN",
    flag: plFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A2NODRKZP88ZB9",
    marketplace: "SE",
    countryName: i18n.t("Country.Sweden"),
    domain: "se",
    currency: "SEK",
    flag: seFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A2VIGQ35RCS4UG",
    marketplace: "AE",
    countryName: i18n.t("Country.UnitedArabEmirates"),
    domain: "ae",
    currency: "AED",
    flag: aeFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A17E79C6D8DWNP",
    marketplace: "SA",
    countryName: i18n.t("Country.SaudiArabia"),
    domain: "sa",
    currency: "SAR",
    flag: saFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "ARBP9OOSHTCHU",
    marketplace: "EG",
    countryName: i18n.t("Country.Egypt"),
    domain: "eg",
    currency: "EGP",
    flag: egFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A21TJRUUN4KGV",
    marketplace: "IN",
    countryName: i18n.t("Country.India"),
    domain: "in",
    currency: "INR",
    flag: inFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A33AVAJ2PDY3EV",
    marketplace: "TR",
    countryName: i18n.t("Country.Turkey"),
    domain: "com.tr",
    currency: "TRY",
    flag: trFlag,
    active: false,
    priceSign: "₺",
    isLocal: true,
  },
  {
    id: "A39IBJ37TRP1C6",
    marketplace: "AU",
    countryName: i18n.t("Country.Australia"),
    domain: "com.au",
    currency: "AUD",
    flag: auFlag,
    active: true,
    priceSign: "$",
    isLocal: true,
  },
  {
    id: "A1VC38T7YXB528",
    marketplace: "JP",
    countryName: i18n.t("Country.Japan"),
    domain: "co.jp",
    currency: "JPY",
    flag: jpFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "A19VAU5U5O7RUS",
    marketplace: "SG",
    countryName: i18n.t("Country.Singapore"),
    domain: "sg",
    currency: "SGD",
    flag: sgFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },

  {
    id: "A3JCEYBC5L8UJ8",
    marketplace: "UK",
    countryName: i18n.t("Country.UnitedKingdom"),
    domain: "co.uk",
    currency: "GBP",
    flag: gbFlag,
    active: false,
    priceSign: "£",
    isLocal: false,
  },
  {
    id: "A2L77EE7U53NWQ",
    marketplace: "Resale",
    countryName: i18n.t("Country.LocalResale"),
    domain: "local",
    currency: "Local Currency",
    flag: usFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
  {
    id: "ARPIJN329XQ0D",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "A4XRJ8S0WXSO0",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "A3DWYIK6Y9EEQB",
    marketplace: "CA",
    countryName: i18n.t("Country.Canada"),
    domain: "ca",
    currency: "CAD",
    flag: caFlag,
    active: false,
    priceSign: "$",
    isLocal: true,
  },
  {
    id: "A2QUTRSO1ZHRN9",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "A3BWELMBWVCY9T",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "AVDBXBAVVSXLQ",
    marketplace: "MX",
    countryName: i18n.t("Country.Mexico"),
    domain: "com.mx",
    currency: "MXN",
    flag: mxFlag,
    active: false,
    priceSign: "$",
    isLocal: true,
  },
  {
    id: "A2KVF7QXNCLV8H",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "A3OJWAJQNSBARP",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "A1G99GVHAT2WD8",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "A3Q3FYJVX702M2",
    marketplace: "BE",
    countryName: i18n.t("Country.Belgium"),
    domain: "com.be",
    currency: "EUR",
    flag: beFlag,
    active: false,
    priceSign: "€",
    isLocal: true,
  },
  {
    id: "A17D2BRD4YMT0X",
    marketplace: "NL",
    countryName: i18n.t("Country.Netherlands"),
    domain: "nl",
    currency: "EUR",
    flag: nlFlag,
    active: false,
    priceSign: "€",
    isLocal: true,
  },
  {
    id: "ANU9KP01APNAG",
    marketplace: "SE",
    countryName: i18n.t("Country.Sweden"),
    domain: "se",
    currency: "SEK",
    flag: seFlag,
    active: false,
    priceSign: "kr",
    isLocal: true,
  },
  {
    id: "A2R2221NX79QZP",
    marketplace: "PL",
    countryName: i18n.t("Country.Poland"),
    domain: "pl",
    currency: "PLN",
    flag: plFlag,
    active: false,
    priceSign: "zł",
    isLocal: true,
  },
  {
    id: "A1ZVRGNO5AYLOV",
    marketplace: "EG",
    countryName: i18n.t("Country.Egypt"),
    domain: "eg",
    currency: "EGP",
    flag: egFlag,
    active: false,
    priceSign: "£",
    isLocal: true,
  },
  {
    id: "A2XPWB6MYN7ZDK",
    marketplace: "SA",
    countryName: i18n.t("Country.SaudiArabia"),
    domain: "sa",
    currency: "SAR",
    flag: saFlag,
    active: false,
    priceSign: "﷼",
    isLocal: true,
  },
  {
    id: "ANEGB3WVEVKZB",
    marketplace: "AU",
    countryName: i18n.t("Country.Australia"),
    domain: "com.au",
    currency: "AUD",
    flag: auFlag,
    active: false,
    priceSign: "$",
    isLocal: true,
  },
  {
    id: "A14PAP71RVPZX1",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: false,
  },
  {
    id: "A2R2RITDJNW1Q6",
    marketplace: "US",
    countryName: i18n.t("Country.UnitedStates"),
    domain: "com",
    currency: "USD",
    flag: usFlag,
    active: false,
    priceSign: "$",
    isLocal: true,
  },
  {
    id: "AN1VRQENFRJN5",
    marketplace: "JP",
    countryName: i18n.t("Country.Japan"),
    domain: "co.jp",
    currency: "JPY",
    flag: jpFlag,
    active: false,
    priceSign: "",
    isLocal: true,
  },
];

export const AllMarketplaces: ReadonlyArray<AmazonMarketplace> = AmazonMarketplaces;
export const ActiveMarketplaces: ReadonlyArray<AmazonMarketplace> = AmazonMarketplaces.filter((x) => x.active);
export const ActiveMarketplaceOptions: ReadonlyArray<MarketplaceSelectOption> = ActiveMarketplaces.map((x) => ({
  value: x.marketplace,
  label: x.countryName,
  marketplace: x.marketplace,
}));
export const ActiveMarketplaceCurrencyOptions: ReadonlyArray<SelectOptionsType> = ActiveMarketplaces.map((x) => ({
  value: x.currency,
  label: x.currency,
}));
