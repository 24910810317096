import {
  apiError,
  loading,
  setLoginToken,
  setSubscriptionList,
  setUserLogHistoryList,
  setUserPaymentHistoryList,
  setUsersList,
  setUserTags,
  updateList,
  updateUserStatusAsActive,
  updateUserStatusAsBlock,
} from "./reducer";
import {ResponseModel} from "models/response_model";
import {
  postGetUsers,
  postBlockUser,
  postUnblockUser,
  postResetUserPassword,
  postGetUserPaymentHistory,
  postGetUserLogHistory,
  postGenerateUserLoginToken,
  postCreateTag,
  postDeleteTag,
  postUpdateTag,
  postAddUserTagItem,
  postDeleteUserTagItem,
} from "services/admin_service";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {PagedList} from "helpers/types";
import {postDownloadInvoice, postGetSubscriptions} from "services/payment_service";
import {AdminGetUsersQuery, GetUserLogHistoryQuery, GetUserPaymentHistoryQuery} from "api/query";
import {UserPayment} from "models/user_payment";
import {LogEntry} from "models/log_entry";
import i18n from "i18n";
import {User} from "models/user";
import {Tag} from "models/tag";
import {AddUserTagItemCommand, CreateTagCommand, DeleteTagCommand, DeleteUserTagItemCommand, UpdateTagCommand} from "api/command";
import {UserTag} from "models/user_tag";
import {setTags} from "slices/common/reducer";

export const getUsers = (query: AdminGetUsersQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const response: ResponseModel = await postGetUsers(query);
    const list: PagedList<User> = response.data.pagedList;
    const tags: Tag[] = response.data.tags;
    dispatch(setUsersList(list));
    dispatch(setTags(tags));
    dispatch(updateList(tags));

    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const getAllSubscriptionData = () => async (dispatch: any) => {
  try {
    dispatch(loading(["listSubscriptions", true]));
    const result: ResponseModel = await postGetSubscriptions();
    dispatch(setSubscriptionList(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listSubscriptions", false]));
  }
};

export const blockUser = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["block", true]));
    await postBlockUser({userId: user.userId});
    dispatch(updateUserStatusAsBlock(user));
    renderSuccessToast(i18n.t("Admin.Users.Toast.BlockSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["block", false]));
  }
};

export const unblockUser = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["unblock", true]));
    await postUnblockUser({userId: user.userId});
    dispatch(updateUserStatusAsActive(user));
    renderSuccessToast(i18n.t("Admin.Users.Toast.UnblockSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["unblock", false]));
  }
};

export const generateUserLoginToken = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["loginAs", true]));
    const result: ResponseModel = await postGenerateUserLoginToken({userId: user.userId});
    dispatch(setLoginToken(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["loginAs", false]));
  }
};

export const resetUserPassword = (user: User) => async (dispatch: any) => {
  try {
    dispatch(loading(["resetPassword", true]));
    await postResetUserPassword({userId: user.userId});
    dispatch(updateUserStatusAsActive(user));
    renderSuccessToast(i18n.t("Admin.Users.Toast.PasswordResetSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["resetPassword", false]));
  }
};

export const getUserPaymentHistory = (query: GetUserPaymentHistoryQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["listPaymentHistory", true]));
    const response: ResponseModel = await postGetUserPaymentHistory(query);
    const pagedResult: PagedList<UserPayment> = response.data;
    dispatch(setUserPaymentHistoryList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listPaymentHistory", false]));
  }
};

export const downloadAdminUserPayment = (userPaymentId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["downloadPayment", true]));
    const result = await postDownloadInvoice({userPaymentId});
    window.open(result.message, "_blank", "noreferrer noopener");
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["downloadPayment", false]));
  }
};

export const getUserLogHistory = (payload: GetUserLogHistoryQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["listLogHistory", true]));
    const response: ResponseModel = await postGetUserLogHistory(payload);
    const pagedResult: PagedList<LogEntry> = response.data;
    dispatch(setUserLogHistoryList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["listLogHistory", false]));
  }
};

export const createTag = (payload: CreateTagCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const response: ResponseModel = await postCreateTag(payload);
    const tags: Tag[] = response.data;
    dispatch(setTags(tags));
    dispatch(updateList(tags));
    renderSuccessToast(i18n.t("Admin.Users.Dialog.AddTag.Toast.TagCreateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const updateTag = (payload: UpdateTagCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const response: ResponseModel = await postUpdateTag(payload);
    const tags: Tag[] = response.data;
    dispatch(setTags(tags));
    dispatch(updateList(tags));
    renderSuccessToast(i18n.t("Admin.Users.Dialog.AddTag.Toast.TagUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const deleteTag = (payload: DeleteTagCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const response: ResponseModel = await postDeleteTag(payload);
    const tags: Tag[] = response.data;
    dispatch(setTags(tags));
    dispatch(updateList(tags));
    renderSuccessToast(i18n.t("Admin.Users.Dialog.AddTag.Toast.TagDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const addUserTagItem = (payload: AddUserTagItemCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const response: ResponseModel = await postAddUserTagItem(payload);
    const tags: UserTag[] = response.data;
    dispatch(setUserTags(tags));
    renderSuccessToast(i18n.t("Admin.Users.Dialog.AddTag.Toast.UserTagAddSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const deleteUserTagItem = (payload: DeleteUserTagItemCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const response: ResponseModel = await postDeleteUserTagItem(payload);
    const tags: UserTag[] = response.data;
    dispatch(setUserTags(tags));
    renderSuccessToast(i18n.t("Admin.Users.Dialog.AddTag.Toast.UserTagDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};
