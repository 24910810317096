import React, {useEffect, useState} from "react";
import OrdersSummaryChart from "./Charts/OrdersSummaryChart";
import {Card, CardBody, Col, ListGroup, ListGroupItem} from "reactstrap";
import {useTranslation} from "react-i18next";
import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {RootState} from "slices";
import DisplayNumber from "Components/Common/DisplayNumber";

const OrdersSummary = () => {
  const {t} = useTranslation();

  const [pending, setPending] = useState<number>(0);
  const [shipped, setShipped] = useState<number>(0);
  const [canceled, setCanceled] = useState<number>(0);
  const dashboardData = createSelector(
    (state: RootState) => state,
    (state) => ({
      statistics: state.Dashboard.statistics,
      loading: state.Dashboard.loading,
    }),
  );

  const {statistics, loading} = useSelector(dashboardData);

  useEffect(() => {
    if (statistics.orderSummary) {
      const pendingOrders = statistics.orderSummary?.pendingCount ?? 0;
      const canceledOrders = statistics.orderSummary?.canceledCount ?? 0;
      const shippedOrders = statistics.orderSummary?.shippedCount ?? 0;

      setPending(pendingOrders);
      setShipped(shippedOrders);
      setCanceled(canceledOrders);
    }
  }, [statistics]);


  return (
    <React.Fragment>
      <Col xl={3} className="same-height">
        <Card className="card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.OrdersSummary.Title")}</h4>
          </div>
          <CardBody>
            <div id="portfolio_donut_charts" dir="ltr">
              <OrdersSummaryChart pendingCount={pending} shippedCount={shipped} canceledCount={canceled} dataColors={["#f7b84b", "#0ab39c", "#f06548"]} />
            </div>

            <ListGroup className="border-dashed mb-0" flush>
              <ListGroupItem className="px-0">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-warning rounded-circle fs-2">
                      <i className="align-middle text-warning mdi mdi-clock"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-1">{t("OrderStatus.PENDING")}</h6>
                    <p className="fs-12 mb-0 text-muted d-flex align-items-center">
                      <i className="mdi mdi-circle fs-10 align-middle me-1 text-warning"></i>
                        <DisplayNumber value={(pending / (pending + shipped + canceled)) * 100} suffix="%" decimals={0} busy={loading.list} />
                    </p>
                  </div>
                  <div className="flex-shrink-0 text-end">
                    <h5 className="mb-1">{pending}</h5>
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem className="px-0">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-success rounded-circle fs-2">
                      <i className="align-middle text-success mdi mdi-truck-fast"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-1">{t("OrderStatus.SHIPPED")}</h6>
                    <p className="fs-12 mb-0 text-muted d-flex align-items-center">
                      <i className="mdi mdi-circle fs-10 align-middle me-1 text-success"></i>
                        <DisplayNumber value={(shipped / (pending + shipped + canceled)) * 100} suffix="%" decimals={0} busy={loading.list} />
                    </p>
                  </div>
                  <div className="flex-shrink-0 text-end">
                    <h5 className="mb-1">{shipped}</h5>
                  </div>
                </div>
              </ListGroupItem>
              <ListGroupItem className="px-0">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-light text-danger rounded-circle fs-2">
                      <i className="align-middle text-danger mdi mdi-basket-remove"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-1">{t("OrderStatus.CANCELED")}</h6>
                    <p className="fs-12 mb-0 text-muted d-flex align-items-center">
                      <i className="mdi mdi-circle fs-10 align-middle me-1 text-danger"></i>
                        <DisplayNumber value={(canceled / (pending + shipped + canceled)) * 100} suffix="%" decimals={0} busy={loading.list} />
                    </p>
                  </div>
                  <div className="flex-shrink-0 text-end">
                    <h5 className="mb-1">{canceled}</h5>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default OrdersSummary;
