import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Modal, ModalHeader, ModalBody, Col, Row, Input, Button, ModalFooter, Form, Spinner, Label} from "reactstrap";
import {ColumnDef} from "@tanstack/react-table";
import {
  addUserShipmentItemFromSearchResult,
  deleteUserShipmentFromSearchResult,
  deleteUserShipmentItemFromSearchResult,
  getUserDraftShipments,
  setUserShipmentDetailsFromSearchResult,
} from "slices/common/thunk";
import {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {IAddUserShipmentItemDto, IDeleteUserShipmentItemDto, ISetUserShipmentDetailsDto, UserShipment} from "models/user_shipment";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import {RootState} from "slices";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {useDebounce} from "Components/Hooks/useDebounce";
import {createTypedModal} from "helpers/modal_helpers";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import EditShipment from "pages/Shipments/Shipments/Modal/EditShipment";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedSelect from "./ValidatedSelect";
import DisplayNumber from "./DisplayNumber";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import DisplayDate from "./DisplayDate";
import * as Yup from "yup";
import Restricted from "./Restricted";
import { AllMarketplaces } from "helpers/marketplace_helper";

interface ModalData {
  asin: string;
  marketplace: string;
}
export const SellThisModal = createTypedModal<ModalData>("sell_this");
export type ListRef = {
  reload: VoidFunction;
};
const SellThis = () => {
  const {t} = useTranslation();
  const {open, data} = SellThisModal.useModal();
  const {userProfile} = useProfile();
  const [showNewShipmentStage, setShowNewShipmentStage] = useState<boolean>(false);
  const [selectedShipment, setSelectedShipment] = useState<UserShipment>();
  const [isEditShipmentOpen, setIsEditShipmentOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<ListRef>(null);
  const deleteDialogRef = useRef<DialogRef>(null);
  const commonData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Common.loading,
      shipmentInfo: state.Common.shipmentInfo,
      userStores: state.Common.userStores,
    }),
  );
  const {loading, shipmentInfo, userStores} = useSelector(commonData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipmentName: "",
      userStore: "",
    } as AddUserShipmentForm,
    validationSchema: Yup.object<AddUserShipmentForm>({
      shipmentName: Yup.string()
        .required(t("Shipments.NameRequired"))
        .max(100, t("Shipments.NameMaxLength")),
      userStore: Yup.string().required(t("Shipments.StoreRequired")),
    }),
    onSubmit: (values: AddUserShipmentForm) => {
      let productInfos: IAddUserShipmentItemDto = {
        userStoreId: values.userStore,
        userShipmentId: "",
        shipmentName: values.shipmentName,
        asin: data?.asin!,
        purchasePrice: 0,
        tax: 0,
        shippingPrice: 0,
        fBAFee: 0,
        referralFee: 0,
        otherFees: 0,
        expectedSellPrice: 0,
        totalItems: 1,
        weight: 0,
        from: "SearchResults",
      };

      const savePromise = addUserShipmentItemFromSearchResult(productInfos)(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          setShowNewShipmentStage(false);
          validation.resetForm();
          listRef.current?.reload();
        }
      });
    },
  });

  const handleCheckBoxChange = (event: any, userStoreId: string, userShipmentId: string, shipmentName: string) => {
    setShowNewShipmentStage(false);

    if (event.target.checked) {
      let parameters: IAddUserShipmentItemDto = {
        userStoreId: userStoreId,
        userShipmentId: userShipmentId,
        shipmentName: shipmentName,
        asin: data?.asin!,
        purchasePrice: 0,
        tax: 0,
        shippingPrice: 0,
        fBAFee: 0,
        referralFee: 0,
        otherFees: 0,
        expectedSellPrice: 0,
        totalItems: 1,
        weight: 0,
        from: "SearchResults",
      };
      addUserShipmentItemFromSearchResult(parameters)(dispatch);
    } else {
      let parameters: IDeleteUserShipmentItemDto = {
        userShipmentId: userShipmentId,
        userShipmentItemId: "",
        asin: data?.asin!,
        from: "SearchResults",
      };
      deleteUserShipmentItemFromSearchResult(parameters)(dispatch);
    }
  };

  const debouncedLoadList = useDebounce(() => {
    if (open) {
      getUserDraftShipments(
        data?.asin!,
        data?.marketplace!,
      )(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList]);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const initModal = useCallback(() => {
    if (open) {
      getUserDraftShipments(
        data?.asin!,
        data?.marketplace!,
      )(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  const columns = useMemo<ColumnDef<UserShipment, any>[]>(
    () => [
      {
        header: t("Select"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const dynamic = row as any;
          return (
            <Row className="align-items-center w-100">
              <div className="align-middle">
                <Input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={dynamic.userShipmentItem}
                  onChange={(event) => handleCheckBoxChange(event, row.userStoreId, row.userShipmentId, "")}
                />
              </div>
            </Row>
          );
        },
      },
      {
        header: t("Shipments.ShipmentItems.Quantity"),
        size: 10,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const dynamic = row as any;

          const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(e.target.value);

            if (newValue !== (dynamic.userShipmentItem?.totalItems || 0)) {
              let newShipmentDetails: ISetUserShipmentDetailsDto = {
                userShipmentId: row.userShipmentId,
                userInventoryId: dynamic.userShipmentItem?.userInventoryId,
                userShipmentItemId: dynamic.userShipmentItem?.userShipmentItemId,
                field: "TotalItems",
                newValue: newValue,
                shipDate: "",
                amazonShipmentId: "",
                from: "SearchResults",
              };

              setUserShipmentDetailsFromSearchResult(newShipmentDetails)(dispatch);
            }
          };

          return (
            <>
              <Input
                id={"quantity-" + row.userShipmentId}
                type="number"
                style={{width: 60}}
                className="form-control form-control-sm border-input-group"
                defaultValue={dynamic.userShipmentItem?.totalItems || 0}
                onBlur={handleValueChange}
                onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleValueChange(event as any)}
                disabled={(dynamic.userShipmentItem?.totalItems || 0) === 0}
              />
            </>
          );
        },
      },
      {
        header: t("Action"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <div className="d-flex align-items-center">
              <Button
                id={`Edit-${row.userShipmentId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedShipment(row);
                  setIsEditShipmentOpen(true);
                }}
              >
                <i className="ri-pencil-fill"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Edit-${row.userShipmentId}`}>{t("Edit")}</DefaultUncontrolledTooltip>
              <Button
                id={`Delete-${row.userShipmentId}`}
                className="btn btn-ghost-danger px-1 py-0 fs-16"
                onClick={() => {
                  setSelectedShipment(row);
                  deleteDialogRef.current?.show();
                  // handleDeleteActionBtn(shipmentData);
                }}
              >
                <i className="ri-delete-bin-fill"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Delete-${row.userShipmentId}`}>{t("Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        header: t("Shipments.Name"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <Link to={`/shipments/shipment-details/${row.userShipmentId}`} className="link-secondary link-offset-2 text-decoration-underline" target="_blank">
              {row.name}
              <i className="ri-arrow-right-up-line"></i>
            </Link>
          );
        },
      },
      {
        header: t("Store"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const marketplaceObject = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === data?.marketplace);
          return (
            <span className="hstack gap-2">
              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                <img src={marketplaceObject?.flag} alt="" className=" rounded-circle" />
              </div>
              {marketplaceObject?.countryName} {`(${data?.marketplace}) - ${row.userStore?.name}`}
            </span>
          );
        },
      },
      {
        header: t("Shipments.SKUCount"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <>{<DisplayNumber value={row.totalSKU} />}</>;
        },
      },
      {
        header: t("Shipments.ItemsCount"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <>{<DisplayNumber value={row.totalItems} />}</>;
        },
      },
      {
        header: t("CreateDate"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <DisplayDate id={`DisplayDateSellThis-${row.userShipmentId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, userStores, data],
  );

  const toggle = () => {
    SellThisModal.close();
  };
  return (
    <Modal backdrop="static" id="showSellThisModal" isOpen={open} toggle={toggle} fade={true} centered={true} size="xl">
      <PlaceholderGlow busy={loading.userShipment || loading.save || loading.update || loading.delete}>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Shipments.CurrentShipments")}
          <span className="ms-1 fs-13 text-muted fw-medium">
            -{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: t("Shipments.PleaseSelectShipmentForASIN", {asin: data?.asin}),
              }}
            ></span>
          </span>
        </ModalHeader>
        <ModalBody>
          {shipmentInfo?.userStores && shipmentInfo.userStores.length === 0 ? (
            <>
              <div>
                <i className="ms-1 bx bxs-info-circle fs-20 text-danger"></i> {t("Shipments.StoreConnectInfoMessage")}
              </div>
              <div className="mt-4">
                <Button
                  type="submit"
                  className="btn w-sm btn-success"
                  onClick={() => {
                    window.location.href = "/stores?connect=true";
                  }}
                >
                  {t("Shipments.GoToStores")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  {shipmentInfo?.userShipments && shipmentInfo?.userShipments.length > 0 ? (
                    <DataTable
                      ref={tableRef}
                      columns={columns}
                      data={shipmentInfo.userShipments}
                      totalDataLength={shipmentInfo?.userShipments.length || 0}
                      thClass="text-black"
                      busy={loading.userShipment || loading.update}
                    />
                  ) : (
                    t("Shipments.NotHaveCurrentShipments")
                  )}
                </Col>
              </Row>
              <Restricted require="shipments" create>
                <Row className="mt-4">
                  <Col>
                    <Button type="button" className="btn btn-secondary add-btn" onClick={() => setShowNewShipmentStage(true)}>
                      <i className="ri-add-line align-bottom me-1"></i>
                      {t("Shipments.NewShipment")}
                    </Button>
                  </Col>
                </Row>
                {showNewShipmentStage && (
                  <Row className={"mt-4"}>
                    <Col xs={12} lg={12} className="mb-2">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();

                          validation.setFieldTouched("shipmentName", true);
                          validation.setFieldTouched("userStore", true);

                          if (validation.isValid) {
                            validation.handleSubmit();
                          } else {
                            validation.setFieldError("shipmentName", t("Shipments.NameRequired"));
                            validation.setFieldError("userStore", t("Shipments.UserStoreRequired"));
                          }
                          return false;
                        }}
                      >
                        <Row className="mt-3">
                          <Col xs={12} md={4} lg={3} className="mb-3 common-css input-col">
                            <Label htmlFor="search">{t("Shipments.Name")}</Label>
                            <ValidatedInput validation={validation} type="text" field="shipmentName" maxLength={100} placeholder={t("Shipments.Name")} disableValidationUI />
                          </Col>
                          <Col xs={12} md={4} lg={3} className="mb-3 common-css input-col">
                            <Label htmlFor="search">{t("Inventory.Filters.StoreFilter")}</Label>
                            <ValidatedSelect
                              options={userStores.filter((x) => x.marketplace === data?.marketplace).map((x) => ({value: x.userStoreId, label: x.marketplace + " - " + x.name}))}
                              validation={validation}
                              field="userStore"
                              value={validation.values.userStore}
                              errorStyle="container"
                            />
                          </Col>
                          <Col xs={12} md={4} lg={3} className="common-css top-5 mt-4">
                            <Button type="submit" className="btn btn-success " disabled={loading.save}>
                              {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                              {t("Create")}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                )}
              </Restricted>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" onClick={toggle}>
              {t("Close")}
            </Button>
          </div>
        </ModalFooter>
      </PlaceholderGlow>
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Shipments.Dialog.Delete.Description", {shipmentName: selectedShipment?.name})}
        title={t("Shipments.Dialog.Delete.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            const deletePromise = deleteUserShipmentFromSearchResult({
              asin: data?.asin!,
              from: "SearchResults",
              userShipmentId: selectedShipment!.userShipmentId,
            })(dispatch);

            deletePromise.then(() => {
              deleteDialogRef.current?.hide();
              listRef.current?.reload();
            });
          } else {
            deleteDialogRef.current?.hide();
          }
        }}
      />
      <EditShipment
        isOpen={isEditShipmentOpen}
        onReload={() => listRef.current?.reload()}
        toggle={() => setIsEditShipmentOpen(!isEditShipmentOpen)}
        from="SearchResults"
        shipment={selectedShipment!}
      />
    </Modal>
  );
};

export default SellThis;

type AddUserShipmentForm = {
  shipmentName: string;
  userStore: string;
};
