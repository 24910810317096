import {UserSearchProduct} from "models/user_search_product";
import {Card, CardBody} from "reactstrap";
import {YesNoBadge} from "./Components/YesNoBadge";
import {SellerTypeBadge} from "./Components/SellerTypeBadge";
import {useTranslation} from "react-i18next";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayNumber from "Components/Common/DisplayNumber";

interface ProductIsExistProps {
  item: UserSearchProduct;
}
const ProductIsExist = ({item}: ProductIsExistProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.ProductIsExist.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          {item.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE && (
            <>
              <li className="d-flex justify-content-between">
                <span>{t("SearchResults.Analyzes.ProductIsExist.AmazonCom")}</span>
                {item.existAtSource ? <YesNoBadge text="Yes" /> : item.existAtSource === false ? <YesNoBadge text="No" /> : <span>{t("NA")}</span>}
              </li>
              <li className="d-flex justify-content-between">
                <span>{t("SearchResults.Analyzes.ProductIsExist.HasBuybox")}</span>
                <span>
                  {item.competitorAnalysis?.buyBoxTypeSource != null ? (
                    <div>
                      <SellerTypeBadge type={item.competitorAnalysis?.buyBoxTypeSource} hideIfNA={true} />
                      <span className="ms-1">
                        <YesNoBadge text="Yes" />
                      </span>
                    </div>
                  ) : (
                    <YesNoBadge text="No" />
                  )}
                </span>
              </li>
              <li className="d-flex justify-content-between">
                <div>
                  <span>{t("SearchResults.Analyzes.ProductIsExist.OfferCount")}</span>
                </div>
                <DisplayNumber value={item.competitorAnalysis?.offerCountSource} renderOnEmpty={<span>{t("NA")}</span>} />
              </li>
            </>
          )}

          {item.domainTarget && (
            <>
              <hr />
              <li className="d-flex justify-content-between">
                <span>Amazon.{item.domainTarget}</span>
                {item.existAtTarget ? <YesNoBadge text="Yes" /> : item.existAtTarget === false ? <YesNoBadge text="No" /> : <span>{t("NA")}</span>}
              </li>
              <li className="d-flex justify-content-between">
                <span>{t("SearchResults.Analyzes.ProductIsExist.HasBuybox")}</span>
                <span>
                  {item.competitorAnalysis?.buyBoxType != null ? (
                    <div>
                      <SellerTypeBadge type={item.competitorAnalysis?.buyBoxType} hideIfNA={true} />
                      <span className="ms-1">
                        <YesNoBadge text="Yes" />
                      </span>
                    </div>
                  ) : (
                    <YesNoBadge text="No" />
                  )}
                </span>
              </li>
              <li className="d-flex justify-content-between">
                <span>
                  {t("SearchResults.Analyzes.ProductIsExist.OfferCount")}
                  {item.competitorAnalysis?.offerCount === 0 && (
                    <>
                      <i id={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert align-middle text-warning"></i>
                      <DefaultUncontrolledTooltip target={`PopoverNoSellerInTheTargetMarketplace-${item.userSearchProductId}`}> {t("NoSellerInTheTargetMarketplace")}</DefaultUncontrolledTooltip>
                    </>
                  )}
                </span>
                <DisplayNumber value={item.competitorAnalysis?.offerCount} renderOnEmpty={<span>{t("NA")}</span>} />
              </li>
            </>
          )}
        </ul>
      </CardBody>
    </Card>
  );
};

export default ProductIsExist;
