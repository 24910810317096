import SelectOptions, {UserSelectOption} from "Components/Hooks/useSelectOptions";
import {Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Label, Row, Spinner, UncontrolledDropdown} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {UserSearchesQuery} from "models/user_search";
import {SearchesListRef} from "..";
import {useProfile} from "Components/Hooks/useProfile";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {SingleSearchModal} from "Components/Common/Modals/SingleSearch";
import ValidateDate from "Components/Common/ValidatedDate";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import * as Yup from "yup";
import useSelectOptions from "Components/Hooks/useSelectOptions";

interface FiltersProps {
  listRef: RefObject<SearchesListRef>;
  busy: boolean;
  fields: FieldConfig<UserSearchesQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {isReviewedSelectOptions} = useSelectOptions();
  const {userProfile} = useProfile();
  const navigate = useNavigate();
  const {searchStatusSelectOptions, multiSelectTranslations, searchTypeSelectOptions, businessModelSelectOptions} = SelectOptions();
  const [teamUserOptions, setTeamUserOptions] = useState<UserSelectOption[]>([]);
  const searchData = createSelector(
    (state: RootState) => state,
    (state) => ({
      activeUserStoreOptions: state.Common.activeUserStoreOptions,
      activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    }),
  );
  const {activeUserStoreOptions, activeMarketplaceOptions} = useSelector(searchData);

  // validation
  const validation = useFormik({
    initialValues: {} as UserSearchesQuery,
    validationSchema: Yup.object<UserSearchesQuery>({
      startDate: Yup.date(),
      endDate: Yup.date(),
      marketplace: Yup.array<String>().notRequired(),
      statues: Yup.array<String>().notRequired(),
      amazonBusinessModels: Yup.array<String>().notRequired(),
      searchType: Yup.string().notRequired(),
      teamUserIds: Yup.array<String>().notRequired(),
      isReviewed: Yup.string().notRequired(),
    }),
    onSubmit: (values: UserSearchesQuery) => {
      const payload: UserSearchesQuery = {
        page: values.page,
        pageSize: values.pageSize,
        startDate: values.startDate,
        endDate: values.endDate,
        marketplace: values.marketplace,
        statuses: values.statuses,
        searchType: values.searchType,
        amazonBusinessModels: values.amazonBusinessModels,
        teamUserIds: values.teamUserIds,
        isReviewed: values.isReviewed,
        sortBy: values.sortBy,
        sortingOrder: values.sortingOrder,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<UserSearchesQuery>(props.fields, validation);

  const onLoad = useCallback(() => {
    validation.handleSubmit();
  }, []); // eslint-disable-line

  const prepareTeamUserOptions = useCallback(() => {
    if (userProfile?.teamUsers) {
      let ownerOption: UserSelectOption[] = [{value: userProfile.userId, label: userProfile.fullName, avatar: userProfile.avatar, email: userProfile.email}];
      const teamOptions: UserSelectOption[] = userProfile.teamUsers.map((user) => ({
        label: user.fullName,
        value: user.userId,
        avatar: user.avatar,
        email: user.email,
      }));
      setTeamUserOptions([...ownerOption, ...teamOptions]);
    }
  }, [userProfile]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    prepareTeamUserOptions();
  }, [prepareTeamUserOptions]);

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Searches.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs={12} sm="auto">
                  <Row className="g-2">
                    <Col className="auto">
                      <Button color="info" className="fw-semibold btn-sm w-100" onClick={() => navigate("/notes")}>
                        <i className="mdi mdi-note-edit-outline align-bottom me-1"></i>
                        {t("Searches.Filters.Button.Notes")}
                      </Button>
                    </Col>
                    <Col className="auto">
                      <Button color="warning" className="fw-semibold btn-sm w-100" onClick={() => navigate("/favorites")}>
                        <i className="ri-star-line align-bottom me-1"></i>
                        {t("Searches.Filters.Button.Favorites")}
                      </Button>
                    </Col>
                    <Col className="auto">
                      <UncontrolledDropdown>
                        <DropdownToggle className="fw-semibold btn-sm w-100" caret>
                          <i className="ri-add-line align-bottom me-1"></i> {t("Searches.Filters.Button.NewSearch")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem className="d-flex align-items-center" onClick={() => navigate("/searches/new/bulk")}>
                            <i className="bx bx-list-ul me-1"></i>
                            {t("Searches.Filters.Button.BulkSearch")}
                          </DropdownItem>
                          <DropdownItem className="d-flex align-items-center" onClick={() => SingleSearchModal.open({})}>
                            <i className="bx bx-bracket me-1"></i>
                            {t("Searches.Filters.Button.SingleSearch")}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2 gx-5 gy-3">
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="startDate">{t("Searches.Filters.StartDate")}</Label>
                  <ValidateDate field="startDate" validation={validation} value={validation.values.startDate}></ValidateDate>
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="endDate">{t("Searches.Filters.EndDate")}</Label>
                  <ValidateDate field="endDate" validation={validation} value={validation.values.endDate}></ValidateDate>
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="marketplace">{t("Searches.Filters.Marketplace")}</Label>
                  <ValidatedMultiSelect
                    options={[...activeUserStoreOptions, ...activeMarketplaceOptions]}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    translations={{
                      ...multiSelectTranslations,
                      selectSomeItems: t("General.Select.SelectStore"),
                    }}
                    optionStyle="country"
                  />
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="status">{t("Searches.Filters.Status")}</Label>
                  <ValidatedMultiSelect options={searchStatusSelectOptions} validation={validation} field="statuses" value={validation.values.statuses} />
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="status">{t("Searches.Filters.SearchType")}</Label>
                  <ValidatedSelect options={searchTypeSelectOptions} validation={validation} field="searchType" value={validation.values.searchType} isClearable />
                </Col>
                {userProfile?.teamUsers && userProfile?.teamUsers.length > 0 && userProfile?.subscription === "Premium" && (
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="status">{t("User")}</Label>
                    <ValidatedMultiSelect options={teamUserOptions} validation={validation} field="teamUserIds" value={validation.values.teamUserIds} optionStyle="user" />
                  </Col>
                )}
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="status">{t("Searches.Filters.BusinessModel")}</Label>
                  <ValidatedMultiSelect options={businessModelSelectOptions} validation={validation} field="amazonBusinessModels" value={validation.values.amazonBusinessModels} />
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="isReviewed">{t("Searches.Filters.ReviewedSearches")}</Label>
                  <ValidatedSelect options={isReviewedSelectOptions} validation={validation} field="isReviewed" value={validation.values.isReviewed} />
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label>&nbsp;</Label>
                  <Button type="submit" color="secondary" className="d-block" disabled={props.busy} onClick={() => validation.setFieldValue("page", 1)}>
                    {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("Searches.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
