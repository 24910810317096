import i18n from "i18n";
import {OrderStatus} from "models/enums/order_status";

interface OrderStatusBadgeProps {
  status?: OrderStatus;
  size?: "sm" | "md" | "lg";
}

export const OrderStatusBadge = ({status, size = "md"}: OrderStatusBadgeProps) => {
  const badgeClass = status === OrderStatus.CANCELED ? "bg-danger-subtle text-danger" : status === OrderStatus.PENDING ? "bg-warning-subtle text-warning" : "bg-success-subtle text-success";

  const fontSize = size === "sm" ? "fs-12" : size === "lg" ? "fs-16" : "fs-14";

  let text = "";
  switch (status) {
    case OrderStatus.PENDING:
      text = i18n.t("OrderStatus.PENDING");
      break;
    case OrderStatus.UNSHIPPED:
      text = i18n.t("OrderStatus.UNSHIPPED");
      break;
    case OrderStatus.PARTIALLY_SHIPPED:
      text = i18n.t("OrderStatus.PARTIALLY_SHIPPED");
      break;
    case OrderStatus.SHIPPED:
      text = i18n.t("OrderStatus.SHIPPED");
      break;
    case OrderStatus.CANCELED:
      text = i18n.t("OrderStatus.CANCELED");
      break;
    case OrderStatus.UNFULFILLABLE:
      text = i18n.t("OrderStatus.UNFULFILLABLE");
      break;
    case OrderStatus.INVOICE_UNCONFIRMED:
      text = i18n.t("OrderStatus.INVOICE_UNCONFIRMED");
      break;
    case OrderStatus.PENDING_AVAILABILITY:
      text = i18n.t("OrderStatus.PENDING_AVAILABILITY");
      break;
    default:
      text = "";
  }

  return <span className={`badge rounded-pill ${badgeClass} ${fontSize}`}>{text}</span>;
};
