import {Navigate} from "react-router-dom";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Stores from "pages/Stores";
import Shipments from "pages/Shipments/Shipments";
import Register from "pages/Authentication/Register";
import RegistrationSuccessful from "pages/Authentication/RegistrationSuccessful";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import EmailVerification from "pages/Authentication/EmailVerification";
import NewSubscription from "pages/Account/NewSubscription";
import ChangeSubscription from "pages/Account/ChangeSubscription";
import LimitUsageHistory from "pages/Account/LimitUsageHistory";
import ExchangeRates from "pages/Settings/ExchangeRates";
import CostAndFees from "pages/Settings/CostAndFees";
import AnalyzeOptions from "pages/Settings/AnalyzeOptions";
import PaymentHistory from "pages/Account/PaymentHistory";
import ProfileInformation from "pages/Account/ProfileInformation";
import ChangePassword from "pages/Account/ChangePassword";
import Subscription from "pages/Account/Subscription";
import Inventory from "pages/Inventory";
import Orders from "pages/Orders/Orders";
import OrderDetails from "pages/Orders/OrderDetails";
import NotificationSettings from "pages/Account/Notifications";
import Team from "pages/Account/Team";
import Searches from "pages/Searches/Searches";
import ScanAndSave from "pages/ScanAndSave";
import ShipmentDetails from "pages/Shipments/ShipmentDetails";
import Notifications from "pages/Notifications";
import Support from "pages/Account/Support";
import Dashboard from "pages/Dashboard";
import Users from "pages/Admin/Users";
import AdminScanAndSavePage from "pages/Admin/ScanAndSave";
import AdminSearchesPage from "pages/Admin/Searches";
import AdminSearchLogs from "pages/Admin/SearchLogs";
import GettingStarted from "pages/Account/NewSubscription/Page/GettingStarted";
import AdminSystemMonitoring from "pages/Admin/SystemMonitoring";
import SearchResults from "pages/Searches/SearchResults";
import ProductDetails from "pages/ProductDetails";
import SubscriptionRedirect from "pages/Account/NewSubscription/Page/SubscriptionRedirect";
import NotesPage from "pages/Notes";
import Favorites from "pages/Favorites";

const userRoutes = [
  {path: "/account/subscription/new", component: <NewSubscription />},
  {path: "/account/profile", component: <ProfileInformation />},
  {path: "/account/change-password", component: <ChangePassword />},
  {path: "/account/subscription", component: <Subscription />},
  {path: "/account/subscription/change", component: <ChangeSubscription />},
  {path: "/account/team", component: <Team />},
  {path: "/account/payment-history", component: <PaymentHistory />},
  {path: "/account/notifications", component: <NotificationSettings />},
  {path: "/account/limit-usage-history", component: <LimitUsageHistory />},
  {path: "/account/settings", component: <CostAndFees />},
  {path: "/account/settings/cost-and-fees", component: <CostAndFees />},
  {path: "/account/settings/cost-and-fees/edit/:userSettingCostAndFeeOptionId", component: <CostAndFees edit />},
  {path: "/account/settings/analyze-options", component: <AnalyzeOptions />},
  {path: "/account/settings/analyze-options/edit/:userSettingAnalyzeOptionId", component: <AnalyzeOptions edit />},
  {path: "/account/settings/exchange-rates", component: <ExchangeRates />},
  {path: "/account/support", component: <Support />},
  {path: "/stores/", component: <Stores />},
  {path: "/stores/connect", component: <Stores connect />},
  {path: "/stores/:status", component: <Stores />},
  {path: "/searches", component: <Searches />},
  {path: "/searches/new/bulk", component: <Searches bulkSearch />},

  {path: "/searches/search-results/:searchId", component: <SearchResults />},

  {path: "/favorites", component: <Favorites />},
  {path: "/favorites/:favoriteId", component: <SearchResults />},

  {path: "/notes", component: <NotesPage />},

  {path: "/scan-and-save", component: <ScanAndSave />},
  {path: "/scan-and-save/new", component: <ScanAndSave new />},
  {path: "/shipments", component: <Shipments />},
  {path: "/shipments/shipment-details/:userShipmentId", component: <ShipmentDetails />},
  {path: "/inventory", component: <Inventory />},
  {path: "/inventory/updateInventory", component: <Inventory updateInventory />},
  {path: "/orders", component: <Orders />},
  {path: "/orders/:amazonOrderId", component: <OrderDetails />},
  {path: "/notifications", component: <Notifications />},
  {path: "/index", component: <Dashboard />},
  {path: "/dashboard", component: <Dashboard />},
  {path: "/getting-started", component: <GettingStarted />},
  {path: "/subscription-redirect", component: <SubscriptionRedirect />},
  {path: "/product-details/", component: <ProductDetails />},
  {path: "/dp/:asin/:searchId", component: <ProductDetails />},
  {path: "*", component: <Navigate to="/dashboard" />},
];

const adminRoutes = [
  {path: "/admin/users", component: <Users />},
  {path: "/admin/searches", component: <AdminSearchesPage />},
  {path: "/admin/search-logs/:searchId", component: <AdminSearchLogs />},
  {path: "/admin/scan-and-save", component: <AdminScanAndSavePage />},
  {path: "/admin/system-monitoring", component: <AdminSystemMonitoring />},
  {path: "/admin/system-monitoring/service-details/:serviceId", component: <AdminSystemMonitoring details />},
  {path: "/admin/system-monitoring/add-product", component: <AdminSystemMonitoring addProduct />},
  {path: "*", component: <Navigate to="/admin/system-monitoring" />},
];
const publicRoutes = [
  // Authentication Page
  {path: "/account/email-verification", component: <EmailVerification />},
  {path: "/account/reset-password", component: <ResetPassword />},
  {path: "/account/forgot-password", component: <ForgotPassword />},
  {path: "/account/register", component: <Register />},
  {path: "/account/registration-successful", component: <RegistrationSuccessful />},
  {path: "/account/logout", component: <Logout />},
  {path: "/account/login", component: <Login />},
  {path: "/fast-search", component: <ProductDetails />},
];

const singleSearchRoutes = [{path: "/dp/:asin", component: <ProductDetails />}];

export {userRoutes, adminRoutes, publicRoutes, singleSearchRoutes};
