import DisplayPrice from "Components/Common/DisplayPrice";
import _ from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";

interface StatsProps {
  busy: boolean;
}
const Stats = (props: StatsProps) => {
  const {t} = useTranslation();
  const orderStatsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      stats: state.Orders.stats,
    }),
  );
  const {stats} = useSelector(orderStatsData);
  return (
    <React.Fragment>
      <Row>
        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Orders.Stats.TotalSales")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-24 fw-semibold ff-secondary mb-4">
                    <DisplayPrice source="USD" value={stats.totalSales} decimals={2} notation="abbreviated" />
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-success-subtle"}>
                    <i className={`text-success ri-money-dollar-circle-line`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Orders.Stats.TotalProfit")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-24 fw-semibold ff-secondary mb-4">
                    <DisplayPrice source="USD" value={stats.totalProfit} decimals={2} notation="abbreviated" />
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-secondary-subtle"}>
                    <i className={`text-secondary ri-line-chart-line`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Orders.Stats.AvgMargin")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-24 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">{t("PercentSign", {value: _.toInteger(stats.avgMargin)})}</span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-secondary-subtle"}>
                    <i className={`text-secondary ri-bar-chart-grouped-fill`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Orders.Stats.AvgRoi")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-24 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">{t("PercentSign", {value: _.toInteger(stats.avgRoi)})}</span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-info-subtle"}>
                    <i className={`text-info ri-bar-chart-grouped-fill`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="placeholder-glow">
          <Card className={`card-animate ${props.busy ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Orders.Stats.TotalOrder")}</p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-24 fw-semibold ff-secondary mb-4">
                    <span className="counter-value">{_.toInteger(stats.totalOrder)}</span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-primary-subtle"}>
                    <i className={`text-primary ri-file-list-3-line`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Stats;
