import {AmazonBusinessModel} from "models/enums/user_search_type";
import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";

interface SellerAnalysisProps {
  item: UserSearchProduct;
}
const SellerAnalysis = ({item}: SellerAnalysisProps) => {
  const {t} = useTranslation();

  const SellerAnalysisItem = ({title, value, valueCss, showWarning, hide}: {title: string; value: any; valueCss?: string; showWarning?: boolean; hide?: boolean}) => {
    return hide ? null : (
      <li className="d-flex justify-content-between">
        <span>
          {title}
          {showWarning ? <i className="mdi mdi-alert align-middle text-warning ms-1"></i> : ""}
        </span>
        {value != null ? <span className={valueCss}>{value}</span> : <span>{t("NA")}</span>}
      </li>
    );
  };
  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.SellerAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <SellerAnalysisItem
            title={t("SearchResults.Analyzes.SellerAnalysis.CommonFBASeller")}
            value={item.brandInfo.commonFBASellerCount}
            valueCss={item.brandInfo.commonFBASellerCount === 0 ? "text-success" : "text-danger"}
            showWarning={item.brandInfo?.potentialBrandOwner}
            hide={item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
          />

          <SellerAnalysisItem
            title={t("SearchResults.Analyzes.SellerAnalysis.CommonRemoteFBASeller")}
            value={item.brandInfo.commonFBARemoteSellerCount}
            valueCss={item.brandInfo.commonFBARemoteSellerCount === 0 ? "text-success" : "text-danger"}
            hide={item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
          />

          <SellerAnalysisItem
            title={t("SearchResults.Analyzes.SellerAnalysis.PotentialBrandOwner")}
            value={item.brandInfo?.potentialBrandOwner ? t("Yes") : item.brandInfo?.potentialBrandOwner === false ? t("No") : t("NA")}
            valueCss={item.brandInfo?.potentialBrandOwner ? "text-danger" : "text-success"}
            hide={item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
          />

          <SellerAnalysisItem
            title={t("SearchResults.Analyzes.SellerAnalysis.PotentialPrivateLabel")}
            value={item.brandInfo?.potentialPrivateLabel ? t("Yes") : item.brandInfo?.potentialPrivateLabel === false ? t("No") : t("NA")}
            valueCss={item.brandInfo?.potentialPrivateLabel ? "text-danger" : "text-success"}
            hide={item.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE}
          />

          <SellerAnalysisItem
            title={t("SearchResults.Analyzes.SellerAnalysis.PotentialWholesale")}
            value={item.brandInfo?.potentialWholesale ? t("Yes") : item.brandInfo?.potentialWholesale === false ? t("No") : t("NA")}
            valueCss={item.brandInfo?.potentialWholesale ? "text-danger" : "text-success"}
            hide={item.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE}
          />

          <SellerAnalysisItem
            title={t("SearchResults.Analyzes.SellerAnalysis.PotentialIpClaim")}
            value={item.brandInfo?.potentialIpClaim ? t("Yes") : item.brandInfo?.potentialWholesale === false ? t("No") : t("NA")}
            valueCss={item.brandInfo?.potentialIpClaim ? "text-danger" : "text-success"}
            hide={item.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE}
          />
        </ul>
      </CardBody>
    </Card>
  );
};

export default SellerAnalysis;
